import {FlexCenterRow, FlexColumn, FlexFiller} from '../Utils/LayoutStyles';
import image_7 from './help-visitor/7_party.svg';
import React, {ReactNode} from 'react';
import {StyledCircleClip2} from '../Utils/CircledText';
import {PrimaryRed} from '../Firebase/firebase-styles';
import {BreakingFlexRow} from './breaking-flex-row';
import {useIsSmall} from '../Shell/adaptive';

function WhyEntry({children}: {children: ReactNode}) {
  return (
    <FlexCenterRow style={{marginTop: '25px'}}>
      <StyledCircleClip2 size='22px' background={PrimaryRed} />
      <FlexFiller style={{marginLeft: '20px', fontSize: '18px'}}>{children}</FlexFiller>
    </FlexCenterRow>
  );
}

export function WhyBreakout() {
  const isSmall = useIsSmall();

  return (
    <BreakingFlexRow
      style={(isBroken) => ({
        margin: isBroken ? '30px 0' : '200px 0',
      })}
    >
      <FlexFiller style={{marginRight: '30px', minHeight: '200px', flexBasis: 'auto'}}>
        <img src={image_7} alt='' />
      </FlexFiller>
      <FlexFiller style={{flexBasis: 'auto'}}>
        <FlexColumn
          style={{background: 'white', borderRadius: isSmall ? '15px' : '30px', padding: isSmall ? '20px' : '55px'}}
        >
          <div style={{marginBottom: '30px', fontSize: '50px'}}>Why Breakout</div>
          <WhyEntry>Free</WhyEntry>
          <WhyEntry>No registration needed for guests</WhyEntry>
          <WhyEntry>Curated selection of activities</WhyEntry>
          <WhyEntry>Private and secure</WhyEntry>
        </FlexColumn>
      </FlexFiller>
    </BreakingFlexRow>
  );
}
