import {makeSubscribable} from './app/Utils/subscriptions';

export type PwaState =
  | {type: 'loading'}
  | {type: 'registering'}
  | {type: 'waiting-for-update'}
  | {type: 'update-found'}
  | {type: 'onupdatefound'}
  | {type: 'update-pending'}
  | {type: 'up-to-date'}
  | {type: 'error'}
  | {type: 'onstatechange'; state: ServiceWorkerState};

export const swChangeEvent = makeSubscribable<PwaState>(true, {type: 'loading'});

export function setSwState(state: PwaState) {
  console.log(JSON.stringify(state));
  (window as any).swState = state;
  swChangeEvent.fire(state);
}

export function getSwState(): PwaState {
  const state = (window as any).swState;
  if (!state) return {type: 'up-to-date'};
  return state;
}
