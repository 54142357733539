import {ReactNode} from 'react';
import {match, RouteComponentProps} from 'react-router';

function trivialPath(p: string) {
  return {
    makePath: () => {
      return `/${p}`;
    },
    route: (render: () => ReactNode) => {
      return {
        exact: true,
        path: `/${p}`,
        render: () => {
          return render();
        },
      };
    },
  };
}

function paramPath<TParams>({
  parseParams,
  makePath,
  matchPaths,
}: {
  matchPaths: string[];
  makePath: (params: TParams) => string;
  parseParams: (r: match<any>) => TParams;
}) {
  return {
    makePath: (p: TParams) => {
      return makePath(p);
    },
    route: (render: (p: TParams) => ReactNode) => {
      return {
        exact: true,
        path: matchPaths,
        render: (r: RouteComponentProps<any>) => {
          const p = parseParams(r.match);
          return render(p);
        },
      };
    },
  };
}

export const QrCodeRoute = paramPath<{locationToken: string; days?: number | 'forever'}>({
  matchPaths: ['/start/:locationToken/:days?'],
  makePath: ({locationToken, days}) => {
    if (days === undefined) {
      return `/start/${locationToken}`;
    }
    return `/start/${locationToken}/${days}`;
  },
  parseParams: (r) => {
    return {
      locationToken: r.params.locationToken,
      days: r.params.days ? (r.params.days === 'forever' ? 'forever' : parseInt(r.params.days)) : undefined,
    };
  },
});

export type EditChatRouteParams = {type: 'edit-chat'; chatId: string};
export type ChatMessageDestinationDto2 = {type: 'chat'; chatId: string};
export type DirectMessageDestinationDto2 = {type: 'direct'; userId: string};
export type MessageDestinationDto2 =
  | {type: 'default-location'}
  | ChatMessageDestinationDto2
  | DirectMessageDestinationDto2
  | EditChatRouteParams;
export type MainRouteParams = 'home' | 'chats' | 'edit-info' | {destination: MessageDestinationDto2};

export type ResolvedDestination = ChatMessageDestinationDto2 | DirectMessageDestinationDto2 | EditChatRouteParams;

export function getCurrentResolvedDestination(
  params: MainRouteParams,
  lastChatId: string | undefined
): ResolvedDestination | null {
  if (
    params === 'home' ||
    params === 'chats' ||
    params === 'edit-info' ||
    params.destination.type === 'default-location'
  ) {
    if (lastChatId) return {type: 'chat' as const, chatId: lastChatId};
    return null;
  }
  return params.destination;
}

export const MainRoute = paramPath<MainRouteParams>({
  matchPaths: [
    '/default-chat',
    '/home',
    '/edit-info',
    '/chats',
    '/chat/:chatId',
    '/chat/:chatId/edit',
    '/direct/:userId',
  ],
  makePath: (params) => {
    if (params === 'home') return '/home';
    if (params === 'chats') return '/chats';
    if (params === 'edit-info') return '/edit-info';
    const destination = params.destination;
    if (destination.type === 'default-location') return '/default-chat';
    else if (destination.type === 'direct') return `/direct/${destination.userId}`;
    else if (destination.type === 'chat') return `/chat/${destination.chatId}`;
    else return `/chat/${destination.chatId}/edit`;
  },
  parseParams: (r) => {
    if (r.path === '/home') return 'home';
    if (r.path === '/chats') return 'chats';
    if (r.path === '/edit-info') return 'edit-info';
    if (r.path.startsWith('/chat')) {
      if (r.path.endsWith('/edit')) {
        return {
          destination: {type: 'edit-chat', chatId: r.params.chatId},
        };
      }
      return {
        destination: {type: 'chat', chatId: r.params.chatId},
      };
    } else if (r.path.startsWith('/direct')) {
      return {
        destination: {type: 'direct', userId: r.params.userId},
      };
    } else {
      return {
        destination: {type: 'default-location'},
      };
    }
  },
});

export const HelpRoute = trivialPath('help');
export const VenueInfoRoute = trivialPath('venue-info');
export const UserInfoRoute = trivialPath('landing');
export const DemoRoute = trivialPath('demo');
export const PreprintedQrCodeRoute = trivialPath('print-qr');
export const ContactRoute = trivialPath('contact');
export const BetaRoute = trivialPath('beta');
export const DeleteAccountRoute = trivialPath('delete-account');
export const QrPrintRoute = paramPath<{roomId: string}>({
  makePath: (params) => {
    return '/qr-print/' + params.roomId;
  },
  matchPaths: ['/qr-print/:roomId'],
  parseParams: (r) => {
    return {roomId: r.params.roomId};
  },
});
