import {useChatRoomsCollection, useUsersMessagingCollection} from './collections';
import React, {ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {useAsyncEffect} from '../Utils/useAsyncEffect';
import {ChatRecord, makeNestedMap, makeUserMap} from '../../model';
import {FlexCenterColumn, FlexCenterer, FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {
  BackgroundGray,
  LightGray,
  PrimaryBlack,
  PrimaryRed,
  StyledInputGroup,
  StyledMainButton,
} from './firebase-styles';
import {useHistory} from 'react-router';
import {MainRoute, QrCodeRoute} from '../Shell/RouteDefinitions';
import {FirebaseUserInfoContext, RequireLoginComponent, useFirebaseUserInfoSafe} from './RequireLoginComponent';
import {addDays} from 'date-fns';
import {usePrevious} from '../Utils/usePrevious';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {AboutPanel, MobileChatInfoTopPanel} from './MobileDashboardComponent';
import {StyledCircledText} from '../Utils/CircledText';
import {usePreviousEffect} from '../Utils/usePreviousEffect';
import infinityIcon from '@iconify-icons/mdi/infinity';
import {Icon} from '@iconify/react';
import styled from 'styled-components';
import firebase from '@firebase/app';
import {AsyncButtonBase} from '../Utils/AsyncButton';
import {randomToken} from './random-token';
import {useFirebaseAnalytics} from './firebase';
import {Spinner} from '../Utils/SmallSpinner';

export function JoinChatComponent({token, days: selectedDays}: {token: string; days: number | 'forever' | undefined}) {
  const roomsCollection = useChatRoomsCollection();
  const query = useMemo(() => roomsCollection.where('token', '==', token), [roomsCollection, token]);
  const [chat, setLocation] = useState<'loading' | 'not-found' | (ChatRecord & {documentId: string})>('loading');
  const [askName, setAskName] = useState(selectedDays !== undefined);
  const [days, setDays] = useState<number | 'forever'>(2);
  const history = useHistory();
  const userInfoCollection = useUsersMessagingCollection();
  const context = useContext(FirebaseUserInfoContext);
  const {firebaseUser, userInfo, setUserInfo} = useFirebaseUserInfoSafe();
  const [username, setUsername] = useState(userInfo?.info.displayName ?? '');
  const [chatName, setChatName] = useState('');
  const firebaseAnalytics = useFirebaseAnalytics();

  useEffectDebug(() => {
    firebaseAnalytics.logEvent('visit_qr');
  }, []);

  useAsyncEffect(
    async ({wrap}) => {
      const s = await wrap(query.get());
      const doc = s.docs[0];
      if (!doc) {
        setLocation('not-found');
      } else {
        setLocation({...doc.data(), documentId: doc.id});
      }
    },

    [query]
  );

  useEffectDebug(() => {
    setAskName(selectedDays !== undefined);
  }, [selectedDays]);

  const onJoin = useCallback(async () => {
    if (chat === 'loading' || chat === 'not-found') return;

    if (!firebaseUser || !userInfo) {
      firebaseAnalytics.logEvent('select_days', {days});
      history.push(QrCodeRoute.makePath({days, locationToken: token}));
      return;
    }

    const newInfo = firebaseUser.isAnonymous
      ? {
          id: userInfo.info.id,
          displayName: username,
          photoUrl: null,
        }
      : userInfo.info;
    if (firebaseUser.isAnonymous) {
      setUserInfo({...userInfo, ...newInfo});
      await userInfoCollection.doc(userInfo.documentId).set(
        {
          info: newInfo,
          lastChatId: chat.documentId,
        },
        {merge: true}
      );
      userInfo.info = newInfo;
    }

    firebaseAnalytics.logEvent('join_chat', {days});
    const stayUntil = days === 'forever' ? null : firebase.firestore.Timestamp.fromDate(addDays(new Date(), days));
    const addUsers = makeUserMap(newInfo, stayUntil);
    await roomsCollection.doc(chat.documentId).set({users: addUsers}, {merge: true});
    setUserInfo({...userInfo, lastChatId: chat.documentId});
    await userInfoCollection.doc(userInfo.documentId).set({lastChatId: chat.documentId}, {merge: true});
    history.push(MainRoute.makePath({destination: {type: 'default-location'}}));
  }, [
    chat,
    days,
    firebaseAnalytics,
    firebaseUser,
    history,
    roomsCollection,
    setUserInfo,
    token,
    userInfo,
    userInfoCollection,
    username,
  ]);

  const lastLoginState = usePrevious(context.loginState);

  usePreviousEffect(
    ([s]) => {
      if (
        s !== undefined &&
        s !== 'logged-in' &&
        lastLoginState?.state !== 'logged-in' &&
        context.loginState.state === 'logged-in' &&
        context.loginState.loggedInReason === 'manual-login'
      ) {
        if (selectedDays !== undefined && userInfo && firebaseUser && !firebaseUser.isAnonymous) {
          onJoin();
        }
      }
    },
    [lastLoginState?.state, userInfo, firebaseUser, selectedDays, onJoin, context.loginState.state] as const
  );

  if (chat === 'loading') return <Spinner />;

  if (chat === 'not-found')
    return (
      <FlexCenterColumn>
        <div>This QR code is available. Create a room for it:</div>
        <StyledInputGroup>
          <div>Name:</div>
          <input value={chatName} onChange={(e) => setChatName(e.target.value)} />
        </StyledInputGroup>
        <AsyncButtonBase
          render={({isLoading, onClick}) => (
            <StyledMainButton
              disabled={chatName === '' || isLoading}
              style={{width: '250px', height: '50px', margin: '40px 10px 0 10px'}}
              onClick={onClick}
            >
              Create
            </StyledMainButton>
          )}
          onClickAsync={async () => {
            if (!userInfo) {
              return;
            }
            const r = await roomsCollection.add({
              owner: userInfo.info,
              users: makeUserMap(userInfo.info, null),
              name: chatName,
              description: '',
              createdAt: firebase.firestore.Timestamp.now(),
              token: randomToken(),
              moderators: makeNestedMap((x) => x.id, userInfo.info),
              bannedUsers: {},
              lastMessageIndex: 0,
              photoUrl: null,
            });

            history.push(MainRoute.makePath({destination: {type: 'chat', chatId: r.id}}));
          }}
        />
      </FlexCenterColumn>
    );

  if (userInfo && chat.users[userInfo.info.id]) {
    return (
      <FlexCenterColumn>
        <div>You have already joined that room</div>
        <div>{chat.name}</div>
        <StyledMainButton
          onClick={() => {
            history.push(MainRoute.makePath({destination: {type: 'chat', chatId: chat.documentId}}));
          }}
        >
          Enter
        </StyledMainButton>
      </FlexCenterColumn>
    );
  }

  if (userInfo && chat.bannedUsers && chat.bannedUsers[userInfo.info.id]) {
    return (
      <FlexCenterColumn>
        <div>You have been banned from that chat room</div>
      </FlexCenterColumn>
    );
  }

  return (
    <FlexCenterer style={{background: BackgroundGray, alignSelf: 'stretch', flex: '1 0 auto'}}>
      <FlexColumn style={{flex: '1 1 auto', alignSelf: 'flex-start', maxWidth: '1200px'}}>
        <MobileChatInfoTopPanel chat={chat} titleText='You are about to join the chat' />
        <AboutPanel description={chat.description} />
        <FlexCenterColumn style={{flex: '1 0 auto'}}>
          {askName && !userInfo && (
            <>
              <div style={{height: '20px'}} />
              <RequireLoginComponent allowAnonymous={true} />
            </>
          )}
          {userInfo && firebaseUser?.isAnonymous && (
            <StyledInputGroup>
              <div>Name:</div>
              <input value={username} onChange={(e) => setUsername(e.target.value)} data-test='test-name-input' />
            </StyledInputGroup>
          )}
          {selectedDays === undefined && (
            <>
              <FlexColumn style={{marginTop: '40px'}}>
                <DurationControlComponent
                  days={days}
                  onChange={(e) => setDays(e)}
                  maxDays={7}
                  isDisabled={days === null}
                />
              </FlexColumn>
            </>
          )}
          <FlexFiller />
          {(!askName || userInfo) && (
            <StyledMainButton
              disabled={firebaseUser?.isAnonymous && username === ''}
              style={{width: '250px', height: '50px', margin: '40px 10px 0 10px'}}
              onClick={onJoin}
              data-test='test-join-btn'
            >
              Join
            </StyledMainButton>
          )}
        </FlexCenterColumn>
        <div style={{height: '40px'}} />
      </FlexColumn>
    </FlexCenterer>
  );
}

function DurationControlComponent({
  days,
  onChange,
  maxDays,
  isDisabled,
}: {
  days: number | 'forever';
  onChange: (newDays: number | 'forever') => void;
  maxDays: number;
  isDisabled?: boolean;
}) {
  return (
    <FlexCenterColumn>
      <div style={{marginBottom: '10px'}}>Remove me from the chat after</div>
      <FlexRow>
        <DurationButton
          text='-'
          disabled={isDisabled || days === 1 || days === 'forever'}
          onClick={() => days !== 'forever' && onChange(days - 1)}
        />
        <div style={{width: '30px'}} />
        <FlexCenterColumn>
          <FlexCenterRow
            style={{
              fontSize: '25px',
              justifyContent: 'center',
              height: '35px',
              color: isDisabled ? PrimaryBlack : PrimaryRed,
            }}
          >
            {days === 'forever' ? <Icon icon={infinityIcon} width={38} height={38} /> : days}
          </FlexCenterRow>
          <div>day(s)</div>
        </FlexCenterColumn>
        <div style={{width: '30px'}} />
        <DurationButton
          text='+'
          disabled={isDisabled || days === maxDays || days === 'forever'}
          onClick={() => days !== 'forever' && onChange(days + 1)}
        />
      </FlexRow>
      <div style={{height: '30px'}} />
      <SplitSelectButtons
        value1={2}
        value2={7}
        value3={'forever' as const}
        selectedItem={days}
        onSelect={(e) => onChange(e)}
        testName={'test-never-chooseBtn'}
        render={(e) => (e === 'forever' ? 'Never' : e.toString())}
      />
    </FlexCenterColumn>
  );
}

function DurationButton({text, disabled, onClick}: {text: string; disabled: boolean; onClick: () => void}) {
  return (
    <StyledCircledText
      size={50}
      style={{
        border: 0,
        fontSize: '40px',
        backgroundColor: disabled ? LightGray : 'white',
        color: disabled ? '#ADADAE' : PrimaryRed,
        cursor: 'pointer',
        userSelect: 'none',
      }}
      onClick={() => !disabled && onClick()}
    >
      {text}
    </StyledCircledText>
  );
}

function SplitSelectButtons<T>({
  value1,
  value2,
  value3,
  selectedItem,
  onSelect,
  render,
  testName,
}: {
  value1: T;
  value2: T;
  value3: T;
  selectedItem: T | null;
  onSelect: (e: T) => void;
  render: (e: T) => ReactNode;
  testName?: string;
}) {
  return (
    <FlexRow style={{borderRadius: '8px', background: LightGray}}>
      <SplitSelectButton isSelected={selectedItem === value1} onClick={() => onSelect(value1)}>
        {render(value1)}
      </SplitSelectButton>
      <SplitSelectButton isSelected={selectedItem === value2} onClick={() => onSelect(value2)}>
        {render(value2)}
      </SplitSelectButton>
      <SplitSelectButton isSelected={selectedItem === value3} onClick={() => onSelect(value3)} data-test={testName}>
        {render(value3)}
      </SplitSelectButton>
    </FlexRow>
  );
}

const SplitSelectButton = styled.div<{isSelected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex: 1 1 0;
  width: 110px;
  height: 50px;
  color: ${(props) => (props.isSelected ? 'white' : PrimaryBlack)};
  background-color: ${(props) => (props.isSelected ? PrimaryRed : LightGray)};
  cursor: pointer;
  user-select: none;
`;
