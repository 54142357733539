import {getDisplayName, LoadedDirectChat} from './chat-context';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {RoomEntryLayoutComponent} from './RoomEntryLayoutComponent';
import React from 'react';
import {UserImage} from './ParticipantsComponent';
import {getUnreadPrivateMessagesCount} from '../../model';

export function PrivateChatEntryComponent({
  chat,
  isSelected,
  onClick,
}: {
  chat: LoadedDirectChat;
  isSelected: boolean;
  onClick: () => void;
}) {
  const {userInfo} = useFirebaseUserInfo();
  const unreadCount = getUnreadPrivateMessagesCount(userInfo, chat);
  const lastMessage = chat.orderedMessages[chat.orderedMessages.length - 1];
  const lastText = lastMessage?.text ?? '';
  const lastSenderName =
    lastMessage?.sentFromId === userInfo.info.id
      ? userInfo.info.displayName ?? 'unknown'
      : getDisplayName(chat.otherUser);
  const lastSentTime = lastMessage?.createdAt?.toDate();

  return (
    <RoomEntryLayoutComponent
      onClick={onClick}
      lastSentTime={lastSentTime}
      lastSenderName={lastSenderName}
      lastText={lastText}
      unreadCount={unreadCount}
      name={getDisplayName(chat.otherUser)}
      isSelected={isSelected}
      icon={<UserImage user={chat.otherUser} size={57} imageStyle={isSelected ? 'inverted-red' : 'red'} />}
    />
  );
}
