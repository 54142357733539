import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {PrimaryBlack, PrimaryRed} from './firebase-styles';
import {Icon} from '@iconify/react';
import closeIcon from '@iconify-icons/ion/close';
import React, {ReactNode} from 'react';
import {useIsSmall} from '../Shell/adaptive';

type TitledDialogLayoutProps = {
  onClose: () => void;
  title: string;
  content: ReactNode;
};

export function TitledDialogLayout({onClose, title, content}: TitledDialogLayoutProps) {
  return (
    <FlexColumn style={{paddingBottom: '20px'}}>
      <FlexCenterRow style={{marginLeft: '30px'}}>
        <div style={{color: PrimaryRed, fontSize: '21px'}}>{title}</div>
        <FlexFiller />
        <FlexCenterRow
          style={{opacity: '0.5', cursor: 'pointer', height: '60px', justifyContent: 'center'}}
          onClick={onClose}
          data-test='test-closeBtn'
        >
          <Icon icon={closeIcon} width={22} height={22} style={{marginRight: '30px'}} />
        </FlexCenterRow>
      </FlexCenterRow>
      {content}
    </FlexColumn>
  );
}

export function TwoColumnResponsiveLayout({
  leftContent,
  rightContent,
}: {
  leftContent: ReactNode;
  rightContent: ReactNode;
}) {
  const isVertical = useIsSmall();

  const body = (
    <>
      <FlexColumn style={{padding: '0 30px', justifyContent: 'center'}}>{leftContent}</FlexColumn>
      <div style={{width: '1px', alignSelf: 'stretch', background: PrimaryBlack, opacity: '0.1'}} />
      <FlexColumn style={{padding: '0 30px'}}>{rightContent}</FlexColumn>
    </>
  );

  if (isVertical) {
    return (
      <FlexColumn style={{justifyContent: 'space-evenly', flex: '1 1 auto', paddingTop: '0 30px'}}>{body}</FlexColumn>
    );
  }

  return <FlexRow>{body}</FlexRow>;
}

export function TwoColumnDialogLayout({
  onClose,
  title,
  leftContent,
  rightContent,
}: {
  onClose: () => void;
  title: string;
  leftContent: ReactNode;
  rightContent: ReactNode;
}) {
  return (
    <TitledDialogLayout
      onClose={onClose}
      title={title}
      content={<TwoColumnResponsiveLayout leftContent={leftContent} rightContent={rightContent} />}
    />
  );
}
