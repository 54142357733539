import {getCurrentResolvedDestination, MainRoute, MainRouteParams} from '../Shell/RouteDefinitions';
import {FlexCenterColumn, FlexCenterer, FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {MobileHeaderComponent} from '../MobileHeaderComponent';
import {ChatActivity, getDisplayName, getUserId, LoadedChat, LoadedDirectChat, useChatContext} from './chat-context';
import {Dialog, Menu, Popover} from '@blueprintjs/core';
import React, {useState} from 'react';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {ChatBodyComponent} from './ChatBodyComponent';
import {MobileFooterComponent} from '../MobileFooterComponent';
import {useHistory} from 'react-router';
import {DirectChatBodyComponent} from './DirectChatBodyComponent';
import {BackgroundGray, HintText, MenuIcon, MobileInfoCard, PrimaryRed, StyledPopupMenu} from './firebase-styles';
import {StyledCard} from '../styles';
import {DrawerComponent} from './DrawerComponent';
import {ManageChatComponent} from './ManageChatComponent';
import {EditUserInfo, UserImage} from './ParticipantsComponent';
import {BlockUnblockToggleComponent} from './PrivateMessageChatComponent';
import useLongPress from '../Utils/useLongPress';
import {ChatImageComponent} from './ChatRoomsComponent';
import {Spinner} from '../Utils/SmallSpinner';
import {ActivityImageComponent} from './ActivityImageComponent';
import {Icon} from '@iconify/react';
import openIcon from '@iconify-icons/ion/open-outline';

export function MobileDashboardComponent({params}: {params: MainRouteParams}) {
  const history = useHistory();

  return (
    <FlexColumn style={{flex: '1 0 auto', alignSelf: 'stretch', background: BackgroundGray}}>
      <FlexColumn style={{flex: '1 0 auto'}}>
        <MobileContentComponent params={params} />
      </FlexColumn>
      <MobileFooterComponent
        selectedButton={params === 'home' ? 'home' : params === 'chats' ? 'messages' : 'chat'}
        onClick={(b) => {
          if (b === 'home') history.push(MainRoute.makePath('home'));
          else if (b === 'chat') history.push(MainRoute.makePath({destination: {type: 'default-location'}}));
          else history.push(MainRoute.makePath('chats'));
        }}
      />
    </FlexColumn>
  );
}

function DirectChatComponent({chat}: {chat: LoadedDirectChat}) {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const longPress = useLongPress({
    onLongPress: () => {
      setShowMenu(true);
    },
    onClick: () => {
      history.push(MainRoute.makePath({destination: {type: 'direct', userId: getUserId(chat.otherUser)}}));
    },
  });

  return (
    <FlexCenterRow style={{height: '64px', borderBottom: '1px solid #E6E6E6'}} {...longPress}>
      <div style={{margin: '0 20px'}}>
        <UserImage user={chat.otherUser} imageStyle='black' />
      </div>
      <div style={{fontSize: '16px', fontWeight: 600, letterSpacing: '0.05em'}}>{getDisplayName(chat.otherUser)}</div>
      <Dialog isOpen={showMenu} onClose={() => setShowMenu(false)}>
        <Menu>
          <BlockUnblockToggleComponent chat={chat} />
        </Menu>
      </Dialog>
    </FlexCenterRow>
  );
}

function MobileContentComponent({params}: {params: MainRouteParams}) {
  const {chatContext} = useChatContext();
  const {userInfo} = useFirebaseUserInfo();
  const [showMenu, setShowMenu] = useState(false);

  const resolvedDestination = getCurrentResolvedDestination(params, userInfo.lastChatId);
  const chatRoomId = resolvedDestination?.type === 'chat' ? resolvedDestination.chatId : null;
  const otherUserId = resolvedDestination?.type === 'direct' ? resolvedDestination.userId : null;

  const chat = chatContext.orderedChats.find((x) => x.documentId === chatRoomId);
  const directChat = chatContext.orderedDirectChats.find((x) => getUserId(x.otherUser) === otherUserId);

  if (params === 'edit-info') {
    return (
      <>
        <MobileHeaderComponent title='Edit user info' />
        <EditUserInfo isVertical={true} />
      </>
    );
  }

  if (params === 'home') {
    return <HomeMobileComponent />;
  }

  if (params === 'chats') {
    return (
      <>
        <MobileHeaderComponent title='Private chats' />
        {chatContext.orderedDirectChats.length === 0 && <FlexCenterer>No private conversations yet</FlexCenterer>}
        {chatContext.orderedDirectChats.map((x) => (
          <DirectChatComponent key={getUserId(x.otherUser)} chat={x} />
        ))}
      </>
    );
  }

  if (chat) {
    const rightSide = (
      <ManageChatComponent
        chat={chat}
        button={({onClick}) => (
          <FlexRow
            style={{width: '60px', height: '80px', alignItems: 'center', justifyContent: 'center'}}
            onClick={onClick}
            data-test='test-menuLeaveChat-btn'
          >
            <MenuIcon />
          </FlexRow>
        )}
      />
    );
    return (
      <>
        <MobileHeaderComponent title={chat.name} rightSideContent={rightSide} />
        <ChatBodyComponent chat={chat} />
      </>
    );
  }

  if (directChat) {
    return (
      <>
        <MobileHeaderComponent
          title={getDisplayName(directChat.otherUser)}
          rightSideContent={
            <Popover
              isOpen={showMenu}
              onClose={() => setShowMenu(false)}
              position='bottom'
              content={
                <StyledPopupMenu>
                  <BlockUnblockToggleComponent chat={directChat} />
                </StyledPopupMenu>
              }
            >
              <FlexRow
                style={{width: '60px', height: '60px', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => setShowMenu(true)}
              >
                <MenuIcon />
              </FlexRow>
            </Popover>
          }
        />
        <DirectChatBodyComponent chat={directChat} />
      </>
    );
  }

  if (resolvedDestination && !chatContext.loadedChats) {
    return (
      <>
        <MobileHeaderComponent title='' />
        <Spinner />
      </>
    );
  }

  return <MobileNoLocations />;
}

function HomeMobileComponent() {
  const {userInfo} = useFirebaseUserInfo();

  const {chatContext} = useChatContext();
  const homeChat = chatContext.orderedChats.find((x) => x.documentId === userInfo.lastChatId);

  if (!chatContext.loadedChats) {
    return <Spinner />;
  }

  if (homeChat) {
    return <ChatInfoComponent chat={homeChat} />;
  }

  return <MobileNoLocations />;
}

function MobileNoLocations() {
  return (
    <FlexCenterColumn>
      <div>
        <FlexColumn
          style={{
            background: 'linear-gradient(90deg, rgba(251,101,64,1) 0%, rgba(252,56,92,1) 100%)',
            color: 'white',
            height: '250px',
          }}
        >
          <DrawerComponent />
          <div style={{alignSelf: 'center', fontSize: '25px', marginTop: '30px'}}>Breakout</div>
        </FlexColumn>
        <FlexColumn style={{marginTop: '-60px', padding: '0 16px'}}>
          <StyledCard style={{padding: '15px 30px'}}>
            <div style={{fontSize: '18px', marginBottom: '10px'}}>
              You have not joined any chats. Scan QR code or follow a share link.
            </div>
          </StyledCard>
        </FlexColumn>
      </div>
    </FlexCenterColumn>
  );
}

export function MobileChatInfoTopPanel({
  chat,
  titleText,
}: {
  chat: {name: string; photoUrl: string | undefined | null};
  titleText?: string;
}) {
  return (
    <FlexColumn
      style={{
        color: 'white',
        height: '250px',
        boxShadow: '0px 3px 6px #00000029',
        position: 'relative',
      }}
    >
      <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: '#ad1414'}} />
      <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
        <ChatImageComponent chat={chat} unclipped={true} size={250} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(90deg, rgba(251,101,64,1) 0%, rgba(252,56,92,1) 100%)',
          opacity: 0.75,
        }}
      />
      <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
        <FlexCenterRow>
          <DrawerComponent />
          <FlexCenterer style={{fontSize: '14px', fontWeight: 500, letterSpacing: '0.1em'}}>{titleText}</FlexCenterer>
        </FlexCenterRow>
        <FlexRow
          style={{
            alignSelf: 'stretch',
            fontSize: '25px',
            marginTop: '30px',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              flex: '0 1 auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              padding: '0 30px',
              letterSpacing: '0.07em',
            }}
          >
            {chat.name}
          </div>
        </FlexRow>
      </div>
    </FlexColumn>
  );
}

export function AboutPanel({description}: {description: string}) {
  return (
    <>
      <FlexColumn style={{marginTop: '-60px', padding: '0 20px', zIndex: 1}}>
        <MobileInfoCard>
          {!description ? <HintText>no description provided</HintText> : <div>{description}</div>}
        </MobileInfoCard>
      </FlexColumn>
    </>
  );
}

function ChatInfoComponent({chat}: {chat: LoadedChat}) {
  return (
    <>
      <MobileChatInfoTopPanel chat={chat} />
      <AboutPanel description={chat.description} />
      {chat.activities.map((activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
    </>
  );
}

function ActivityCard({activity}: {activity: ChatActivity}) {
  return (
    <StyledCard
      style={{
        alignSelf: 'stretch',
        margin: '20px 20px 0 20px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <FlexCenterColumn style={{alignSelf: 'stretch'}}>
        {activity.photoUrl && <ActivityImageComponent activity={activity} noBottomBorderRadius={true} />}
      </FlexCenterColumn>
      {(activity.categories || []).length > 0 && (
        <div style={{color: 'white', background: '#FC385C', padding: '6px 26px'}}>
          {(activity.categories || []).join('; ')}
        </div>
      )}

      <div style={{color: PrimaryRed, fontSize: '21px', margin: '10px 20px'}}>{activity.name}</div>
      {activity.description && <div style={{margin: '10px 20px'}}>{activity.description}</div>}
      {activity.url && (
        <a href={activity.url} style={{color: '#67D0D9', display: 'flex', alignItems: 'center', margin: '0 26px'}}>
          <div>{activity.url}</div>
          <FlexFiller />
          <Icon icon={openIcon} width={24} height={24} />
        </a>
      )}
    </StyledCard>
  );
}
