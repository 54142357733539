import {randomToken} from './random-token';
import {useMemo} from 'react';

export type LogoColors =
  | {color: string}
  | {logoColor1: string; logoColor2: string; textColor: string; textColor2: string};

function isSingleColor(x: LogoColors): x is {color: string} {
  return typeof (x as any).color === 'string';
}

const idPrefix = randomToken();
let idCounter = 0;

export function SvgBreakoutLogoWithText(colors: LogoColors) {
  let textColor, logoColor1, logoColor2, textColor2;
  if (isSingleColor(colors)) {
    textColor = colors.color;
    logoColor1 = colors.color;
    logoColor2 = colors.color;
    textColor2 = colors.color;
  } else {
    textColor = colors.textColor;
    logoColor1 = colors.logoColor1;
    logoColor2 = colors.logoColor2;
    textColor2 = colors.textColor2;
  }

  const id = useMemo(() => idPrefix + idCounter++, []);
  const gradientId = useMemo(() => 'linear-gradient' + id, [id]);

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 167.766 51.521'>
      <defs>
        <linearGradient id={gradientId} y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={logoColor1} />
          <stop offset='1' stopColor={logoColor2} />
        </linearGradient>
      </defs>
      <g transform='translate(-178.496 -117.701)'>
        <g transform='translate(178.5 117.7)'>
          <g transform='translate(0 0)'>
            <g transform='translate(52.82 14.169)'>
              <path
                fill={textColor}
                d='M329.386,157.2a8,8,0,0,1,2.307.252,1.94,1.94,0,0,1,1.226.937,3.72,3.72,0,0,1,.4,1.875,3.186,3.186,0,0,1-.469,1.911,2.057,2.057,0,0,1-1.514.757V163a2.384,2.384,0,0,1,1.839.9,3.9,3.9,0,0,1,.5,2.2,3.851,3.851,0,0,1-.361,1.875,2.1,2.1,0,0,1-1.19.937,7.106,7.106,0,0,1-2.163.252h-1.731c-.469,0-.937-.036-1.334-.036-.4-.036-.829-.036-1.262-.072l.252-1.19c.252,0,.721.036,1.406.036h2.452a5.507,5.507,0,0,0,1.478-.144,1.2,1.2,0,0,0,.757-.613,3.919,3.919,0,0,0-.036-2.668,1.257,1.257,0,0,0-.793-.685,4.213,4.213,0,0,0-1.514-.216h-3.75v-1.154h3.75a4.238,4.238,0,0,0,1.334-.18,1.169,1.169,0,0,0,.721-.577,2.764,2.764,0,0,0,.216-1.226,2.3,2.3,0,0,0-.252-1.226,1.223,1.223,0,0,0-.793-.577,5.632,5.632,0,0,0-1.514-.144h-2.632c-.324,0-.613.036-.829.036l-.252-1.19c.4-.036.757-.072,1.118-.108.361,0,.757-.036,1.154-.036Zm-2.344.144v11.682H325.6V157.308h1.442Z'
                transform='translate(-325.384 -157.128)'
              />
              <path
                fill={textColor}
                d='M355.918,166.78l.18,1.586.144.252v6.418H354.8V166.78Zm4.074-.18-.18,1.37h-.469a4.111,4.111,0,0,0-1.442.288,17.625,17.625,0,0,0-1.767.829l-.108-.9a7.372,7.372,0,0,1,1.839-1.154,4.255,4.255,0,0,1,1.8-.433Z'
                transform='translate(-344.056 -163.139)'
              />
              <path
                fill={textColor}
                d='M376.122,166.6a4.2,4.2,0,0,1,2.7.685,2.4,2.4,0,0,1,.829,2.019,2.813,2.813,0,0,1-.469,1.731,1.852,1.852,0,0,1-1.514.613h-4.759V170.53h4.435a.718.718,0,0,0,.721-.4,2.139,2.139,0,0,0,.18-.829,1.435,1.435,0,0,0-.469-1.154,2.754,2.754,0,0,0-1.55-.324,2.971,2.971,0,0,0-1.406.252,1.419,1.419,0,0,0-.721.9,6.083,6.083,0,0,0-.216,1.839,5.982,5.982,0,0,0,.252,2.019,1.55,1.55,0,0,0,.829.937,3.692,3.692,0,0,0,1.514.252c.433,0,.937-.036,1.442-.072a13.107,13.107,0,0,0,1.406-.144l.144.973a4.711,4.711,0,0,1-.973.252c-.4.072-.757.108-1.154.144s-.757.036-1.082.036a5.269,5.269,0,0,1-2.307-.433,2.557,2.557,0,0,1-1.262-1.406,8.343,8.343,0,0,1,0-5.084,2.481,2.481,0,0,1,1.226-1.334A5.738,5.738,0,0,1,376.122,166.6Z'
                transform='translate(-355.246 -163.139)'
              />
              <path
                fill={textColor}
                d='M404.462,169.953v1.082H400.5a.942.942,0,0,0-.829.324,1.335,1.335,0,0,0-.252.829v.541a1.056,1.056,0,0,0,1.262,1.226,4.228,4.228,0,0,0,1.082-.18,5.106,5.106,0,0,0,1.226-.541,5.512,5.512,0,0,0,1.118-.937v1.082a3.748,3.748,0,0,1-.541.541,5.5,5.5,0,0,1-.829.613,7.521,7.521,0,0,1-1.082.5,3.69,3.69,0,0,1-1.262.18,3.09,3.09,0,0,1-1.3-.252,2.037,2.037,0,0,1-.865-.793,2.347,2.347,0,0,1-.324-1.262v-.9a1.954,1.954,0,0,1,.613-1.514,2.453,2.453,0,0,1,1.695-.541ZM402.3,166.6a3.994,3.994,0,0,1,1.55.252,1.844,1.844,0,0,1,.973.829,3.252,3.252,0,0,1,.324,1.586v5.769h-1.154l-.216-1.8-.108-.18v-3.822a1.353,1.353,0,0,0-.361-1.082,1.992,1.992,0,0,0-1.334-.324c-.433,0-.974.036-1.622.072s-1.3.108-1.911.144l-.144-1.082a11.794,11.794,0,0,1,1.262-.18c.469-.072.937-.108,1.406-.144S401.9,166.6,402.3,166.6Z'
                transform='translate(-371.616 -163.139)'
              />
              <path
                fill={textColor}
                d='M426.978,157.036V162.3a8.16,8.16,0,0,1-.036.973c-.036.288-.072.613-.144.937a4.406,4.406,0,0,1,.108.937,7.838,7.838,0,0,1,.036.9v2.812H425.5V157h1.478Zm1.406,6.67v1.046h-1.911v-1.046Zm3.894-3.065-2.6,3.029a2.331,2.331,0,0,1-.288.324c-.108.108-.216.18-.288.252v.036a2.137,2.137,0,0,1,.288.288,2.747,2.747,0,0,1,.288.4l2.884,3.966h-1.695l-3.281-4.723,2.993-3.533h1.695Z'
                transform='translate(-389.265 -157)'
              />
              <path
                fill={textColor}
                d='M452.094,166.6a5.1,5.1,0,0,1,2.271.433,2.544,2.544,0,0,1,1.226,1.37,7.062,7.062,0,0,1,.361,2.524,6.933,6.933,0,0,1-.361,2.524,2.318,2.318,0,0,1-1.226,1.37,6.179,6.179,0,0,1-4.543,0,2.545,2.545,0,0,1-1.226-1.37,6.983,6.983,0,0,1-.4-2.524,6.846,6.846,0,0,1,.4-2.524,2.318,2.318,0,0,1,1.226-1.37A5.1,5.1,0,0,1,452.094,166.6Zm0,1.19a2.925,2.925,0,0,0-1.406.288,1.475,1.475,0,0,0-.721.973,6.517,6.517,0,0,0-.216,1.875,6.349,6.349,0,0,0,.216,1.875,1.708,1.708,0,0,0,.721.973,3.137,3.137,0,0,0,1.406.288,3.3,3.3,0,0,0,1.442-.288,1.518,1.518,0,0,0,.757-.973,6.516,6.516,0,0,0,.216-1.875,6.349,6.349,0,0,0-.216-1.875,1.615,1.615,0,0,0-.757-.973A3.3,3.3,0,0,0,452.094,167.79Z'
                transform='translate(-403.781 -163.139)'
              />
              <path
                fill={textColor}
                d='M477.678,167.1v5.8a1.6,1.6,0,0,0,.288,1.118,1.358,1.358,0,0,0,1.01.324,3.061,3.061,0,0,0,1.37-.324,12.814,12.814,0,0,0,1.659-.973l.18,1.01a8.252,8.252,0,0,1-1.875,1.154,4.825,4.825,0,0,1-1.839.4,2.008,2.008,0,0,1-2.271-2.271v-6.2h1.478Zm5.661,0v8.256h-1.154l-.144-1.55-.144-.252V167.1Z'
                transform='translate(-421.686 -163.459)'
              />
              <path
                fill={textColor}
                d='M506.952,162.752v1.154H501.4V162.86l1.622-.072h3.93ZM504.32,160.3v8.437a.937.937,0,0,0,1.118,1.118H506.7l.18,1.118c-.18.036-.4.072-.613.108a4.927,4.927,0,0,1-.685.072c-.216,0-.4.036-.541.036a2.045,2.045,0,0,1-1.586-.577,2.356,2.356,0,0,1-.541-1.659v-8.617h1.406Z'
                transform='translate(-437.8 -159.11)'
              />
              <path
                fill={textColor2}
                d='M341.008,206.136a1.156,1.156,0,0,1,1.262,1.262l.577,14.133H339.71l-.5-12.727h-.4l-2.488,9.591a1.216,1.216,0,0,1-1.334,1.082H332.86a1.251,1.251,0,0,1-1.37-1.082L329,208.8h-.361l-.469,12.727H325l.577-14.133a1.156,1.156,0,0,1,1.262-1.262h3.173a1.238,1.238,0,0,1,1.334,1.082l1.983,7.716c.072.361.18.721.216,1.082.072.361.108.721.216,1.118h.288c.072-.361.144-.721.216-1.118.072-.361.144-.721.216-1.118l1.947-7.716a1.216,1.216,0,0,1,1.334-1.082h3.245Z'
                transform='translate(-325 -188.397)'
              />
              <path
                fill={textColor2}
                d='M386.7,217.5a6.811,6.811,0,0,1,4,.937,3.3,3.3,0,0,1,1.226,2.812,3.085,3.085,0,0,1-.757,2.271,3.788,3.788,0,0,1-2.7.757h-6.129v-1.839h5.228q.757,0,.974-.324a1.586,1.586,0,0,0,.216-.9,1.282,1.282,0,0,0-.469-1.082,2.541,2.541,0,0,0-1.442-.288,3.235,3.235,0,0,0-1.442.252,1.515,1.515,0,0,0-.721.937,7.12,7.12,0,0,0-.216,2.019,6.559,6.559,0,0,0,.252,2.127,1.763,1.763,0,0,0,.829,1.046,3.962,3.962,0,0,0,1.622.288c.5,0,1.118-.036,1.875-.072s1.514-.108,2.271-.18l.324,2.019a7.888,7.888,0,0,1-1.478.5,12.779,12.779,0,0,1-1.695.252c-.577.036-1.118.072-1.659.072a8.138,8.138,0,0,1-3.389-.613,3.589,3.589,0,0,1-1.839-1.875,8.3,8.3,0,0,1-.577-3.245,8.144,8.144,0,0,1,.577-3.425,3.8,3.8,0,0,1,1.839-1.839A7.6,7.6,0,0,1,386.7,217.5Z'
                transform='translate(-360.809 -195.687)'
              />
              <path
                fill={textColor2}
                d='M421.215,217.5c.541,0,1.118.036,1.731.072s1.226.072,1.839.144c.577.072,1.118.144,1.586.216l-.216,2.055c-.721,0-1.442-.036-2.163-.036H421.9c-.577,0-1.01,0-1.334.036a1.122,1.122,0,0,0-.649.216.7.7,0,0,0-.18.577.654.654,0,0,0,.324.649,6.759,6.759,0,0,0,1.118.361l2.884.685a4.4,4.4,0,0,1,2.055,1.046,2.972,2.972,0,0,1,.685,2.091,3.86,3.86,0,0,1-.5,2.127,2.5,2.5,0,0,1-1.622,1.01,12.492,12.492,0,0,1-2.884.288c-.469,0-1.154-.036-2.055-.072a24.332,24.332,0,0,1-2.993-.324l.216-2.055c.252,0,.613.036,1.01.036h2.848c.757,0,1.334-.036,1.731-.072a1.4,1.4,0,0,0,.829-.252.793.793,0,0,0,.216-.613.775.775,0,0,0-.361-.685,6,6,0,0,0-1.19-.361l-2.776-.685a4.865,4.865,0,0,1-1.622-.721,2.436,2.436,0,0,1-.829-1.082,4.874,4.874,0,0,1-.216-1.55,2.973,2.973,0,0,1,.469-1.8,2.35,2.35,0,0,1,1.478-.973A8.478,8.478,0,0,1,421.215,217.5Z'
                transform='translate(-383.574 -195.687)'
              />
              <path
                fill={textColor2}
                d='M454.615,217.5c.541,0,1.118.036,1.731.072s1.226.072,1.839.144c.577.072,1.118.144,1.586.216l-.216,2.055c-.721,0-1.442-.036-2.163-.036H455.3c-.577,0-1.01,0-1.334.036a1.122,1.122,0,0,0-.649.216.7.7,0,0,0-.18.577.654.654,0,0,0,.324.649,6.76,6.76,0,0,0,1.118.361l2.884.685a4.4,4.4,0,0,1,2.055,1.046,2.972,2.972,0,0,1,.685,2.091,3.86,3.86,0,0,1-.5,2.127,2.5,2.5,0,0,1-1.622,1.01,12.492,12.492,0,0,1-2.884.288c-.469,0-1.154-.036-2.055-.072a24.332,24.332,0,0,1-2.993-.324l.216-2.055c.252,0,.613.036,1.01.036h2.848c.757,0,1.334-.036,1.731-.072a1.4,1.4,0,0,0,.829-.252.793.793,0,0,0,.216-.613.775.775,0,0,0-.361-.685,6,6,0,0,0-1.19-.361l-2.776-.685a4.865,4.865,0,0,1-1.622-.721,2.435,2.435,0,0,1-.829-1.082A4.873,4.873,0,0,1,450,220.6a2.973,2.973,0,0,1,.469-1.8,2.35,2.35,0,0,1,1.478-.973A8.479,8.479,0,0,1,454.615,217.5Z'
                transform='translate(-404.932 -195.687)'
              />
              <path
                fill={textColor2}
                d='M492.466,222.043l-.036,1.839-4.9.036a1.187,1.187,0,0,0-.865.288,1.107,1.107,0,0,0-.252.793v.541a1.169,1.169,0,0,0,.324.865,1.356,1.356,0,0,0,1.01.288,3.412,3.412,0,0,0,1.262-.252,6.673,6.673,0,0,0,1.334-.685,6.513,6.513,0,0,0,1.19-1.046v1.442c-.144.216-.324.5-.577.829a6.194,6.194,0,0,1-.973.973,6.576,6.576,0,0,1-1.406.829,4.917,4.917,0,0,1-1.839.324,4.131,4.131,0,0,1-1.8-.361,2.793,2.793,0,0,1-1.262-1.046,2.96,2.96,0,0,1-.469-1.731v-1.118a2.508,2.508,0,0,1,.9-2.055,3.62,3.62,0,0,1,2.452-.721h5.913ZM489.51,217.5a6.406,6.406,0,0,1,2.344.361,2.615,2.615,0,0,1,1.442,1.262,5.017,5.017,0,0,1,.5,2.416v7.247h-2.632l-.469-2.38-.216-.324V221.5a1.44,1.44,0,0,0-.361-1.118,2,2,0,0,0-1.406-.361c-.577,0-1.262.036-2.127.072-.829.036-1.731.072-2.6.144l-.324-2.2c.541-.108,1.154-.18,1.839-.252s1.406-.144,2.091-.18C488.356,217.536,488.969,217.5,489.51,217.5Z'
                transform='translate(-426.162 -195.687)'
              />
              <path
                fill={textColor2}
                d='M520.671,223.81l1.659.288a.826.826,0,0,0-.433.541.841.841,0,0,0,.072.721.94.94,0,0,0,.793.4l4.038.469a3.6,3.6,0,0,1,2.452.973,3.4,3.4,0,0,1,.721,2.307,3.549,3.549,0,0,1-.577,2.2,3.084,3.084,0,0,1-1.839,1.046,16.663,16.663,0,0,1-3.389.288,19.848,19.848,0,0,1-2.848-.18,4.872,4.872,0,0,1-1.767-.541,1.948,1.948,0,0,1-.9-.973,3.692,3.692,0,0,1-.252-1.514,2.31,2.31,0,0,1,1.082-2.091,6.65,6.65,0,0,1,1.659-.721l1.622-.577,1.19.469-.9.577c-.361.252-.685.5-.9.685a2.31,2.31,0,0,0-.5.541,1.43,1.43,0,0,0-.144.721,1.3,1.3,0,0,0,.216.793,1.191,1.191,0,0,0,.829.361,9.524,9.524,0,0,0,1.622.108,9.706,9.706,0,0,0,1.659-.108,1.37,1.37,0,0,0,.829-.361,1.183,1.183,0,0,0,.216-.757.886.886,0,0,0-.18-.613,1.252,1.252,0,0,0-.541-.288,5.535,5.535,0,0,0-1.082-.18l-3.461-.361a2.8,2.8,0,0,1-1.37-.469,2.185,2.185,0,0,1-.685-.937,2.332,2.332,0,0,1-.108-1.118,2.472,2.472,0,0,1,.4-1.01A1.894,1.894,0,0,1,520.671,223.81Zm3.353-6.31a9.765,9.765,0,0,1,3.029.361,2.922,2.922,0,0,1,1.622,1.118,3.718,3.718,0,0,1,.5,2.019,3.587,3.587,0,0,1-.5,1.983,2.756,2.756,0,0,1-1.622,1.118,13.205,13.205,0,0,1-6.129,0,3.16,3.16,0,0,1-1.659-1.118,4.29,4.29,0,0,1,0-4.038,2.848,2.848,0,0,1,1.659-1.118A14.085,14.085,0,0,1,524.024,217.5Zm-.036,2.055a3.164,3.164,0,0,0-1.695.324,1.654,1.654,0,0,0,0,2.307,3.164,3.164,0,0,0,1.695.324,2.934,2.934,0,0,0,1.659-.324,1.755,1.755,0,0,0,0-2.307A2.854,2.854,0,0,0,523.988,219.555Zm6.49-1.731-.18,1.406-2.956.361-.793-1.767Z'
                transform='translate(-448.671 -195.687)'
              />
              <path
                fill={textColor2}
                d='M559.416,204.9c.649,0,.974.324.974,1.01v1.19c0,.685-.325,1.01-.974,1.01h-1.442c-.649,0-.973-.324-.973-1.01v-1.19c0-.685.324-1.01.973-1.01Zm.9,4.867v11h-3.281v-11Z'
                transform='translate(-473.354 -187.63)'
              />
              <path
                fill={textColor2}
                d='M577.232,217.824l.4,2.2.252.288v8.545H574.6v-11h2.632Zm5.012-.324c2.091,0,3.1,1.01,3.1,3.065v8.256h-3.281V221.61a1.42,1.42,0,0,0-.252-1.01,1.223,1.223,0,0,0-.9-.288,4.473,4.473,0,0,0-1.478.288,15.478,15.478,0,0,0-1.875.865l-.144-1.839a9.429,9.429,0,0,1,2.416-1.55A5.4,5.4,0,0,1,582.244,217.5Z'
                transform='translate(-484.608 -195.687)'
              />
              <path
                fill={textColor2}
                d='M612.571,223.81l1.659.288a.826.826,0,0,0-.433.541.841.841,0,0,0,.072.721.94.94,0,0,0,.793.4l4.038.469a3.6,3.6,0,0,1,2.452.973,3.4,3.4,0,0,1,.721,2.307,3.549,3.549,0,0,1-.577,2.2,3.084,3.084,0,0,1-1.839,1.046,16.662,16.662,0,0,1-3.389.288,19.848,19.848,0,0,1-2.848-.18,4.873,4.873,0,0,1-1.767-.541,1.948,1.948,0,0,1-.9-.973,3.692,3.692,0,0,1-.252-1.514,2.31,2.31,0,0,1,1.082-2.091,6.65,6.65,0,0,1,1.659-.721l1.622-.577,1.19.469-.9.577c-.361.252-.685.5-.9.685a2.31,2.31,0,0,0-.5.541,1.43,1.43,0,0,0-.144.721,1.3,1.3,0,0,0,.216.793,1.191,1.191,0,0,0,.829.361,9.522,9.522,0,0,0,1.622.108,9.706,9.706,0,0,0,1.659-.108,1.37,1.37,0,0,0,.829-.361,1.183,1.183,0,0,0,.216-.757.886.886,0,0,0-.18-.613.965.965,0,0,0-.541-.288,5.535,5.535,0,0,0-1.082-.18l-3.461-.361a2.794,2.794,0,0,1-1.37-.469,2.185,2.185,0,0,1-.685-.937,2.332,2.332,0,0,1-.108-1.118,2.471,2.471,0,0,1,.4-1.01A1.894,1.894,0,0,1,612.571,223.81Zm3.353-6.31a9.766,9.766,0,0,1,3.029.361,2.922,2.922,0,0,1,1.622,1.118,3.717,3.717,0,0,1,.5,2.019,3.586,3.586,0,0,1-.5,1.983,2.756,2.756,0,0,1-1.622,1.118,13.205,13.205,0,0,1-6.129,0,3.16,3.16,0,0,1-1.659-1.118,4.29,4.29,0,0,1,0-4.038,2.848,2.848,0,0,1,1.659-1.118A14.084,14.084,0,0,1,615.924,217.5Zm-.036,2.055a3.164,3.164,0,0,0-1.695.324,1.654,1.654,0,0,0,0,2.307,3.164,3.164,0,0,0,1.695.324,2.934,2.934,0,0,0,1.659-.324,1.755,1.755,0,0,0,0-2.307A2.854,2.854,0,0,0,615.888,219.555Zm6.49-1.731-.18,1.406-2.956.361-.793-1.767Z'
                transform='translate(-507.436 -195.687)'
              />
            </g>
            <path
              fill={`url(#${gradientId})`}
              d='M18416,19451.982v0l0-48.455a9.432,9.432,0,0,1,9.447,9.445v5.945a18.176,18.176,0,0,1,11.027-3.723,18.67,18.67,0,0,1,2.387.154,18.423,18.423,0,0,1,15.078,24.047,18.382,18.382,0,0,1-17.459,12.586,18.929,18.929,0,0,1-4.975-.687,25.532,25.532,0,0,0-6.934-.98,22.151,22.151,0,0,0-8.176,1.521l-.4.146Zm17.094-25.963a5.438,5.438,0,0,0-5.443,5.449v9.621a16.109,16.109,0,0,1,6.908-1.705,10.83,10.83,0,0,1,2.9.373,8.5,8.5,0,0,0,2.342.361,5.449,5.449,0,0,0,5.443-5.443v-3.207a5.438,5.438,0,0,0-5.443-5.449Z'
              transform='translate(-18416 -19403.523)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
