import {toast, ToastContent, ToastOptions} from 'react-toastify';

export function useSnackbar(): {
  show: (content: ToastContent, options?: ToastOptions) => void;
} {
  return {
    show: (content, options) => {
      toast(content, {});
    },
  };
}
