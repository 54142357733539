import {ReactNode, useCallback, useMemo, useRef} from 'react';
import {
  getUserId,
  isLoadingUserObjectReference,
  lastTime,
  LoadedChat,
  LoadedChatMessage,
  LoadedDirectChat,
  useChatContext,
} from './chat-context';
import {
  getMessagesSubcollection,
  useChatRoomsCollection,
  useCollectionHelpers,
  useDirectMessagesCollection,
  useUsersMessagingCollection,
} from './collections';
import {MainRouteParams} from '../Shell/RouteDefinitions';
import {RequireLoginComponent, useFirebaseUserInfo} from './RequireLoginComponent';
import {ChatMessageRecord, mapToArray} from '../../model';
import {useIsSmall} from '../Shell/adaptive';
import {MobileDashboardComponent} from './MobileDashboardComponent';
import {QuerySnapshot} from '@firebase/firestore-types';
import {DesktopDashboardLayout} from './DesktopDashboardLayout';
import {replaceElement} from './ChatComponent';
import {useEffectDebug} from '../Utils/useEffectDebug';

export function DashboardComponent({params}: {params: MainRouteParams}) {
  const isSmall = useIsSmall();

  const content = useMemo(() => {
    return isSmall ? <MobileDashboardComponent params={params} /> : <DesktopDashboardLayout params={params} />;
  }, [params, isSmall]);

  return (
    <RequireLoginComponent>
      <ChatsContextContainerComponent>
        <ChatsLoaderComponent params={params}>{content}</ChatsLoaderComponent>
      </ChatsContextContainerComponent>
    </RequireLoginComponent>
  );
}

// todo remove
export function ChatsContextContainerComponent({children}: {children: ReactNode}) {
  return <>{children}</>;
}

function useChatRoomLoader() {
  const {userInfo} = useFirebaseUserInfo();
  const {chatContextRef, setChatContext} = useChatContext();
  const {removeUserFromChat, leaveChat} = useCollectionHelpers();

  const roomsCollection = useChatRoomsCollection();
  const userRoomsQuery = useMemo(
    () => roomsCollection.where(`users.${userInfo.ownerId}`, '!=', null),
    [userInfo.ownerId, roomsCollection]
  );

  useEffectDebug(() => {
    return userRoomsQuery.onSnapshot((snapshot) => {
      const newChats: LoadedChat[] = [];
      for (let doc of snapshot.docs) {
        const data = doc.data();
        const existingChat = chatContextRef.current.orderedChats.find((x) => x.documentId === doc.id);
        const users = mapToArray(data.users);
        const keepUsers = users.filter((x) => x.stayUntil === null || x.stayUntil.toDate() > new Date());
        const removeUsers = users.filter((x) => x.stayUntil !== null && x.stayUntil.toDate() <= new Date());
        const isAdmin = !!data.moderators[userInfo.ownerId];
        for (const removeUser of removeUsers) {
          if (removeUser.id === userInfo.ownerId) {
            leaveChat(doc.id);
          } else {
            if (isAdmin) {
              removeUserFromChat(doc.id, removeUser.id);
            }
          }
        }

        newChats.push({
          documentId: doc.id,
          name: data.name,
          description: data.description ?? '',
          token: data.token ?? 'test',
          createdAt: data.createdAt,
          orderedMessages: existingChat?.orderedMessages ?? [],
          participants: keepUsers,
          moderators: mapToArray(data.moderators),
          owner: data.owner,
          photoUrl: data.photoUrl,
          bannedUsers: mapToArray(data.bannedUsers),
          isDirect: false,
          lastMessageIndex: data.lastMessageIndex,
          activities: mapToArray(data.activities),
        });
      }

      newChats.sort((x, y) => {
        return lastTime(x).toMillis() - lastTime(y).toMillis();
      });

      setChatContext({...chatContextRef.current, orderedChats: newChats, loadedChats: true});
    });
  }, [userRoomsQuery, setChatContext, chatContextRef]);
}

function usePrivateMessagesLoader() {
  const {userInfo} = useFirebaseUserInfo();
  const {chatContextRef, setChatContext} = useChatContext();
  const directMessagesCollection = useDirectMessagesCollection();

  const userPrivateMessagesQuery = useMemo(
    () =>
      directMessagesCollection.where('participants', 'array-contains', userInfo.ownerId).orderBy('createdAt').limit(50),
    [directMessagesCollection, userInfo.ownerId]
  );

  useEffectDebug(() => {
    return userPrivateMessagesQuery.onSnapshot((snapshot) => {
      const newChats = chatContextRef.current.orderedDirectChats.map((x) => ({...x}));

      for (let doc of snapshot.docs) {
        const data = doc.data();
        const otherUser = data.sentTo === userInfo.ownerId ? data.author.id : data.sentTo;
        const existingChat = newChats.find((x) => getUserId(x.otherUser) === otherUser);
        const message = {
          documentId: doc.id,
          sentFromId: data.author.id,
          sentToId: data.sentTo,
          status: 'sent' as const,
          text: data.text,
          createdAt: data.createdAt,
        };
        if (existingChat) {
          const existingMessage = existingChat.orderedMessages.find((x) => x.documentId === doc.id);
          if (existingMessage) {
            existingMessage.text = data.text;
          } else {
            existingChat.orderedMessages = [...existingChat.orderedMessages, {...message, chat: existingChat}];
          }
        } else {
          const newChat: LoadedDirectChat = {
            otherUser: {isLoading: true, userId: otherUser},
            orderedMessages: [],
            isDirect: true,
          };
          newChat.orderedMessages = [{...message, chat: newChat}];
          newChats.push(newChat);
        }
      }

      setChatContext({...chatContextRef.current, orderedDirectChats: newChats});
    });
  }, [chatContextRef, userInfo.ownerId, setChatContext, userPrivateMessagesQuery]);
}

function usePrivateChatLoader() {
  const {chatContextRef, setChatContext} = useChatContext();
  const userMessagingCollection = useUsersMessagingCollection();

  const loadingChatInfos = useMemo(() => new Map<string, () => void>(), []);
  useEffectDebug(() => {
    for (const directChat of chatContextRef.current.orderedDirectChats) {
      if (!isLoadingUserObjectReference(directChat.otherUser)) {
        continue;
      }
      const userId = directChat.otherUser.userId;
      const existing = loadingChatInfos.get(userId);
      if (existing) continue;
      userMessagingCollection
        .where('ownerId', '==', userId)
        .limit(1)
        .get()
        .then((r) => {
          const doc = r.docs[0];
          const chat = chatContextRef.current.orderedDirectChats.find((x) => getUserId(x.otherUser) === userId);
          if (!doc) {
            setChatContext({
              ...chatContextRef.current,
              orderedDirectChats: replaceElement(
                chatContextRef.current.orderedDirectChats,
                (x) => getUserId(x.otherUser) === userId,
                (item) => ({
                  ...item,
                  otherUser: {isUnknown: true, userId},
                })
              ),
            });
            return;
          }
          if (chat) {
            const otherUserData = doc.data();
            const otherUser = otherUserData.info;
            setChatContext({
              ...chatContextRef.current,
              orderedDirectChats: replaceElement(
                chatContextRef.current.orderedDirectChats,
                (x) => getUserId(x.otherUser) === userId,
                (item) => ({
                  ...item,
                  otherUser: {...otherUser, bannedUsers: otherUserData.bannedUsers},
                })
              ),
            });
          }
        });
    }
    for (const [userId, cancel] of loadingChatInfos) {
      const chat = chatContextRef.current.orderedDirectChats.find((x) => getUserId(x.otherUser) === userId);
      if (!chat || isLoadingUserObjectReference(chat.otherUser)) {
        cancel();
      }
    }
  }, [
    setChatContext,
    loadingChatInfos,
    userMessagingCollection,
    chatContextRef,
    chatContextRef.current.orderedDirectChats,
  ]);
}

function useRoomMessagesLoader() {
  const {chatContextRef, setChatContext} = useChatContext();
  const roomsCollection = useChatRoomsCollection();

  const roomWatchers = useRef<Map<string, () => void>>(new Map<string, () => void>());
  const chatIdsString = chatContextRef.current.orderedChats
    .map((x) => x.documentId)
    .sort()
    .join(';;;');

  const onMessagesSnapshot = useCallback(
    (chatId: string, snapshot: QuerySnapshot<ChatMessageRecord>) => {
      setChatContext({
        ...chatContextRef.current,
        orderedChats: chatContextRef.current.orderedChats.map((x) => {
          if (x.documentId !== chatId) return x;

          let newMessages = [...x.orderedMessages];
          for (let docChange of snapshot.docChanges()) {
            if (docChange.type === 'removed') {
              newMessages = newMessages.filter((x) => x.documentId !== docChange.doc.id);
            }
          }
          for (let doc of snapshot.docs) {
            const existingIndex = newMessages.findIndex((m) => m.documentId === doc.id);
            const data = doc.data();
            const message: LoadedChatMessage = {
              chatId,
              text: data.text,
              createdAt: data.createdAt,
              author: data.author,
              documentId: doc.id,
              status: 'sent',
              chat: x,
            };
            if (existingIndex >= 0) {
              newMessages[existingIndex].chatId = message.chatId;
              newMessages[existingIndex].text = message.text;
              newMessages[existingIndex].createdAt = message.createdAt;
              newMessages[existingIndex].author = message.author;
              newMessages[existingIndex].documentId = message.documentId;
              newMessages[existingIndex].status = message.status;
            } else {
              newMessages.push(message);
            }
          }

          newMessages.sort((x, y) => {
            return x.createdAt.toMillis() - y.createdAt.toMillis();
          });
          return {...x, orderedMessages: newMessages};
        }),
      });
    },
    [chatContextRef, setChatContext]
  );

  useEffectDebug(() => {
    let chatIds = chatIdsString.split(';;;');
    if (chatIds.length === 1 && chatIds[0] === '') chatIds = [];
    for (let chatId of chatIds) {
      const watcher = roomWatchers.current.get(chatId);
      if (!watcher) {
        const messagesCollection = getMessagesSubcollection(roomsCollection.doc(chatId));
        const query = messagesCollection.orderBy('createdAt', 'desc').limit(25);

        roomWatchers.current.set(
          chatId,
          query.onSnapshot((snapshot) => {
            onMessagesSnapshot(chatId, snapshot);
          })
        );
      }
    }
    for (let currentElement of roomWatchers.current) {
      const chatId = currentElement[0];
      if (!chatIds.includes(chatId)) {
        const cleanup = currentElement[1];
        cleanup();
        roomWatchers.current.delete(chatId);
      }
    }
  }, [roomWatchers, chatIdsString, roomsCollection, onMessagesSnapshot]);
}

function useNewChatLoader(params: MainRouteParams) {
  const {chatContextRef, setChatContext} = useChatContext();

  useEffectDebug(() => {
    if (params !== 'chats' && params !== 'home' && params !== 'edit-info' && params.destination.type === 'direct') {
      const userId = params.destination.userId;
      const existing = chatContextRef.current.orderedDirectChats.find((x) => getUserId(x.otherUser) === userId);
      if (!existing) {
        setChatContext({
          ...chatContextRef.current,
          orderedDirectChats: [
            ...chatContextRef.current.orderedDirectChats,
            {
              otherUser: {isLoading: true, userId},
              orderedMessages: [],
              isDirect: true,
            },
          ],
        });
      }
    }
  }, [chatContextRef, params, setChatContext]);
}

export function ChatsLoaderComponent({children, params}: {children: ReactNode; params: MainRouteParams}) {
  useChatRoomLoader();
  useRoomMessagesLoader();

  usePrivateMessagesLoader();
  usePrivateChatLoader();

  useNewChatLoader(params);

  return <>{children}</>;
}
