import React, {useMemo} from 'react';
import {randomToken} from '../Firebase/random-token';

const idPrefix = randomToken();
let idCounter = 0;

export function WaveMiddle() {
  const id = useMemo(() => idPrefix + idCounter++, []);
  const gradientId = useMemo(() => 'linear-gradient' + id, [id]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 1920 600'
      style={{filter: 'drop-shadow(0px 15px 10px #454D5A40)'}}
      preserveAspectRatio='none'
    >
      <defs>
        <linearGradient id={gradientId} x1='1' y1='0.5' x2='0' y2='0.5'>
          <stop offset='0' stopColor='#fb6540' />
          <stop offset='1' stopColor='#fc385c' />
        </linearGradient>
      </defs>
      <g>
        <path
          fill={`url(#${gradientId})`}
          d='M0,0H1920V600s-89.771-100.285-310.135-112.35S1347.464,556,1038.546,550,633.83,461.9,374.193,461.9,0,550,0,550Z'
        />
      </g>
    </svg>
  );
}
