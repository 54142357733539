import {ReactNode, useCallback, useLayoutEffect, useRef, useState} from 'react';

export function DraggableThumb({
  render,
  onDrag,
}: {
  onDrag: (dx: number, dy: number) => void;
  render: (e: {onMouseDown: (e: React.MouseEvent) => void}) => ReactNode;
}) {
  const captured = useRef<{x: number; y: number}>();
  const [isCapturing, setIsCapturing] = useState(false);

  const mouseMove = useCallback(
    (e: MouseEvent) => {
      if (e.buttons === 1 && captured.current) {
        const dx = e.clientX - captured.current.x;
        const dy = e.clientY - captured.current.y;

        onDrag(Math.floor(dx), Math.floor(dy));
        captured.current = {x: e.clientX, y: e.clientY};
      } else {
        captured.current = undefined;
        setIsCapturing(false);
      }
    },
    [onDrag]
  );

  const mouseUp = useCallback((e: MouseEvent) => {
    captured.current = undefined;
    setIsCapturing(false);
  }, []);

  useLayoutEffect(() => {
    if (isCapturing) {
      document.addEventListener('mousemove', mouseMove);
      document.addEventListener('mouseup', mouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
    };
  }, [isCapturing, mouseMove, mouseUp]);

  return (
    <>
      {render({
        onMouseDown: (e) => {
          if (e.button === 0) {
            captured.current = {x: e.clientX, y: e.clientY};
            setIsCapturing(true);
          }
        },
      })}
    </>
  );
}
