import {LoadedChat} from './chat-context';
import {RoomEntryLayoutComponent} from './RoomEntryLayoutComponent';
import React from 'react';
import {ChatImageComponent} from './ChatRoomsComponent';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {getUnreadMessagesCount} from '../../model';

export function RoomEntryComponent({
  chat,
  isSelected,
  onClick,
}: {
  chat: LoadedChat;
  isSelected: boolean;
  onClick: () => void;
}) {
  const {userInfo} = useFirebaseUserInfo();
  const name = chat.name;
  const unreadCount = getUnreadMessagesCount(userInfo, chat);
  const lastMessage = chat.orderedMessages[chat.orderedMessages.length - 1];
  const lastText = lastMessage?.text ?? '';
  const lastSenderName = lastMessage?.author.displayName;
  const lastSentTime = lastMessage?.createdAt?.toDate();

  return (
    <RoomEntryLayoutComponent
      onClick={onClick}
      lastSentTime={lastSentTime}
      lastSenderName={lastSenderName}
      lastText={lastText}
      unreadCount={unreadCount}
      name={name}
      isSelected={isSelected}
      icon={<ChatImageComponent chat={chat} isSelected={isSelected} />}
    />
  );
}
