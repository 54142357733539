import {CollectionReference, DocumentReference} from '@firebase/firestore-types';
import {useMemo} from 'react';
import {ChatMessageRecord, ChatRecord, PrivateMessageRecord, UserMessagingRecord} from '../../model';
import {useFirestoreCollection} from './firebase';
import firebase from '@firebase/app';
import {useFirebaseUserInfo} from './RequireLoginComponent';

export function useChatRoomsCollection() {
  return useFirestoreCollection<ChatRecord>('chats');
}

export function useUsersMessagingCollection() {
  return useFirestoreCollection<UserMessagingRecord>('users-messaging');
}

export function usePreprintedCodesCollection() {
  return useFirestoreCollection<UserMessagingRecord>('preprinted-codes');
}

export function getMessagesSubcollection(c: DocumentReference<ChatRecord>) {
  return c.collection('messages') as CollectionReference<ChatMessageRecord>;
}

export function useMessagesCollection(chatId: string) {
  const collection = useChatRoomsCollection();
  return useMemo(() => getMessagesSubcollection(collection.doc(chatId)), [chatId, collection]);
}

export function useMessagesCollectionNull(chatId: string | undefined) {
  const collection = useChatRoomsCollection();
  return useMemo(() => (chatId ? getMessagesSubcollection(collection.doc(chatId)) : undefined), [chatId, collection]);
}

export function useDirectMessagesCollection() {
  return useFirestoreCollection<PrivateMessageRecord>('direct-messages');
}

export function useCollectionHelpers() {
  const {userInfo, setUserInfo} = useFirebaseUserInfo();
  const userMessagingCollection = useUsersMessagingCollection();
  const chatCollection = useChatRoomsCollection();

  const removeUserFromChat = async (chatDocumentId: string, userId: string) => {
    const participantsDelete: any = {};
    participantsDelete[userId] = firebase.firestore.FieldValue.delete();
    await chatCollection.doc(chatDocumentId).set({users: participantsDelete}, {merge: true});
  };

  return {
    removeUserFromChat: removeUserFromChat,
    leaveChat: async (chatDocumentId: string) => {
      await removeUserFromChat(chatDocumentId, userInfo.ownerId);
      setUserInfo({...userInfo, lastChatId: undefined});
      await userMessagingCollection
        .doc(userInfo.documentId)
        .set({lastChatId: firebase.firestore.FieldValue.delete() as any}, {merge: true});
    },
  };
}
