import React, {useLayoutEffect, useRef, useState} from 'react';

export function Leaf1Svg() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='190.509'
      height='217.266'
      viewBox='0 0 190.509 217.266'
      style={{filter: 'drop-shadow(0px 25px 5px rgba(69,77,90,0.15))'}}
    >
      <g transform='matrix(0.978, -0.208, 0.208, 0.978, 0, 33.095)'>
        <g transform='translate(0 0)'>
          <path
            fill='#fc385c'
            d='M140.881,158.237c21.945-23.181,20.863-57.566,13.677-87.237-1.159-4.945-1.7-10.277-5.023-14.218-3.245-3.863-9.427-5.718-13.445-2.7-3.323,2.473-4.1,7.109-5.409,11.127a30.392,30.392,0,0,1-9.35,13.6c-2.859,2.318-6.568,4.1-10.045,3.014-4.791-1.468-6.491-7.341-7.5-12.286-1.854-9.272-3.709-18.467-5.563-27.74-1-4.945-2.009-10.045-4.559-14.372-2.55-4.4-6.8-8.036-11.822-8.422-5.023-.309-10.277,3.554-10.277,8.654,0,2.086.85,4.1,1.159,6.182S72.5,38.392,70.721,39.4c-1.854,1-4.1,0-5.95-1.159C58.589,34.3,53.8,28.579,49.24,22.784,44.681,17.066,40.2,11.116,34.4,6.634S21.345-.861,14.159.221C7.051,1.3.405,7.252.019,14.593S5.2,28.192,10.837,32.906A114.316,114.316,0,0,0,38.345,50.137c1.777.773,3.709,1.623,4.713,3.323,1.777,3.091-.7,7.032-3.709,8.731-3.477,2.009-7.5,2.473-11.281,3.709s-7.572,3.786-8.345,7.8c-1,5.486,4.327,9.891,9.2,12.518A96.9,96.9,0,0,0,60.289,96.653c3.941.618,8.036.927,11.436,2.936,3.477,1.932,6.182,6.027,5.1,9.891s-5.409,5.95-9.35,6.027-7.8-1.314-11.745-1.932c-7.341-1.159-16.072,1.082-19.163,7.882-2.086,4.482-1.236,9.891,1.159,14.218s6.182,7.65,10.045,10.663c13.522,10.586,29.671,18,46.593,20.167,17,2.241,31.99.7,46.516-8.268'
          />
        </g>
        <g transform='translate(14.094 17.115)'>
          <path
            fill='#e0e0e0'
            d='M140.012,174.011a221.847,221.847,0,0,0-16.536-39.176c-7.5-14.14-17-28.976-27.508-44.2-2.627-3.786-5.254-7.5-7.8-11.2s-5.1-7.341-7.8-10.74a236.1,236.1,0,0,0-16.69-18.7c-5.563-5.718-11.127-10.9-16.3-15.686-5.254-4.713-10.2-9.118-14.836-12.981A177.629,177.629,0,0,0,9.118,5.023C6.182,3.323,3.941,2.086,2.4,1.236,1.623.85,1.082.541.618.309A3.523,3.523,0,0,0,0,0,3.058,3.058,0,0,0,.541.386c.464.232,1,.618,1.777,1,1.545.85,3.786,2.164,6.645,3.941A190.054,190.054,0,0,1,32.144,21.867c4.636,3.863,9.5,8.268,14.758,13.059,5.177,4.868,10.663,10.045,16.227,15.763A248.523,248.523,0,0,1,79.742,69.311c2.7,3.4,5.177,7.032,7.8,10.663,2.55,3.709,5.177,7.418,7.8,11.2,10.509,15.145,20.013,29.981,27.508,44.044a221.142,221.142,0,0,1,16.613,38.944'
          />
        </g>
        <g transform='translate(89.818 29.787)'>
          <path
            fill='#e0e0e0'
            d='M4.327,56.1A10,10,0,0,0,4.4,53.857c0-1.391-.155-3.477-.309-6.027-.309-5.1-1-12.054-1.777-19.781S.927,13.368.7,8.268Q.464,4.443.464,2.241A13.832,13.832,0,0,0,.309,0,8.6,8.6,0,0,0,.077,2.241C0,3.632,0,5.718,0,8.268c.077,5.1.541,12.131,1.314,19.858C2.086,35.7,2.782,42.653,3.323,47.907c.232,2.4.386,4.4.541,6.027C4.173,55.325,4.327,56.1,4.327,56.1Z'
          />
        </g>
        <g transform='translate(28.003 83.836)'>
          <path
            fill='#e0e0e0'
            d='M0,3.285a11.177,11.177,0,0,0,2.627-.232c1.7-.155,4.1-.464,7.032-.773C15.608,1.74,23.876,1.2,33.071,1.044c9.118-.155,17.463.077,23.413.386,3.013.155,5.409.309,7.109.464a9.723,9.723,0,0,0,2.627.077,12.66,12.66,0,0,0-2.55-.464c-1.7-.232-4.1-.541-7.109-.773a231.158,231.158,0,0,0-23.49-.7A218.8,218.8,0,0,0,9.659,1.585c-3.014.386-5.409.773-7.032,1.082A21.153,21.153,0,0,0,0,3.285Z'
          />
        </g>
        <g transform='translate(136.534 62.936)'>
          <path
            fill='#e0e0e0'
            d='M.032,88.01a5.4,5.4,0,0,0,.309-.85c.155-.7.386-1.545.618-2.55.541-2.164,1.159-5.409,2.009-9.35,1.545-7.959,3.4-18.931,5.254-31.14S11.777,20.863,13.168,12.9c.7-3.863,1.236-7.032,1.7-9.427q.232-1.507.464-2.55c.077-.618.155-.927.077-.927A5.4,5.4,0,0,0,15.1.85c-.155.7-.386,1.545-.618,2.55-.541,2.164-1.159,5.409-2.009,9.35-1.545,7.959-3.4,18.931-5.254,31.14S3.664,67.147,2.273,75.029c-.7,3.863-1.236,7.032-1.7,9.427q-.232,1.507-.464,2.55C.032,87.7-.045,88.01.032,88.01Z'
          />
        </g>
        <g transform='translate(46.238 141.133)'>
          <path
            fill='#e0e0e0'
            d='M0,0A8.742,8.742,0,0,0,.927.155c.7.077,1.545.155,2.627.309,2.4.232,5.718.618,9.659,1.082,8.191.927,19.395,2.241,31.912,3.786,12.44,1.545,23.722,2.782,31.912,3.554,4.1.386,7.418.618,9.736.773,1.082.077,1.932.077,2.627.155h.927a8.741,8.741,0,0,0-.927-.155c-.7-.077-1.545-.155-2.627-.309-2.4-.232-5.718-.618-9.659-1.082C68.924,7.341,57.643,6.027,45.2,4.482S21.481,1.7,13.29.927C9.2.541,5.872.309,3.554.155,2.473.077,1.623.077.927,0Z'
          />
        </g>
      </g>
    </svg>
  );
}

export function BranchSvg({width, height}: {width?: string; height?: string}) {
  width = width ?? '100%';
  height = height ?? '100%';
  const svgRef = useRef<SVGSVGElement>(null);
  useLayoutEffect(() => {
    if (svgRef.current) {
      const bbox = svgRef.current!.getBBox();
      setViewBox(`${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
    }
  }, []);

  const [viewBox, setViewBox] = useState('0 0 1 1');

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} ref={svgRef}>
      <g transform='translate(87.185) rotate(81)'>
        <g transform='translate(8.422 8.946)'>
          <path
            d='M89.085,288.578l1.178.181c6.253-35.617-1.088-55.646-8.428-66.159-7.975-11.51-17.31-14.41-17.673-14.5l-.363,1.178c.091,0,9.425,2.991,17.129,14.138C88.088,233.657,95.339,253.324,89.085,288.578Z'
            transform='translate(-63.8 -208.1)'
            fill='#7ccf9a'
          />
        </g>
        <path
          d='M67.551,209.713s-4.35-15.044-10.875-10.694C49.969,203.64,60.3,211.072,67.551,209.713Z'
          transform='translate(-54.507 -198.229)'
          fill='#7ccf9a'
        />
        <path
          d='M78.766,218.128s11.6-10.513,4.8-14.41C76.5,199.639,74.416,212.237,78.766,218.128Z'
          transform='translate(-56.568 -198.668)'
          fill='#7ccf9a'
        />
        <path
          d='M77.118,218.738S66.243,229.976,62.527,223C58.721,215.838,71.318,214.207,77.118,218.738Z'
          transform='translate(-55.193 -199.914)'
          fill='#7ccf9a'
        />
        <path
          d='M89.44,236.347s14.229-6.616,8.791-12.416C92.7,218.04,86.993,229.459,89.44,236.347Z'
          transform='translate(-57.726 -200.484)'
          fill='#7ccf9a'
        />
        <path
          d='M93.875,252.223s14.863-4.985,10.15-11.329C99.222,234.459,92.244,245.063,93.875,252.223Z'
          transform='translate(-58.174 -202.041)'
          fill='#7ccf9a'
        />
        <path
          d='M87.812,236.546s-13.685,7.613-15.226-.181C71.046,228.389,83.643,230.564,87.812,236.546Z'
          transform='translate(-56.189 -201.317)'
          fill='#7ccf9a'
        />
        <path
          d='M92.214,253.346s-13.685,7.613-15.226-.181C75.538,245.189,88.045,247.364,92.214,253.346Z'
          transform='translate(-56.603 -202.892)'
          fill='#7ccf9a'
        />
      </g>
    </svg>
  );
}
