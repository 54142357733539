import {WithStretchedBackground} from './with-stretched-background';
import {CenterMaxWidth2} from './center-max-width2';
import {SvgBreakoutLogoNoText} from '../Firebase/SvgBreakoutLogoNoText';
import React from 'react';
import {BreakingFlexRow} from './breaking-flex-row';

export function WaveBottom({background}: {background?: string}) {
  return (
    <WithStretchedBackground background={<WaveBottomSvg />} style={{background, paddingTop: '60px', color: 'white'}}>
      <CenterMaxWidth2 style={{alignItems: 'center'}}>
        <BreakingFlexRow
          style={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'stretch',
            marginBottom: '50px',
            justifyContent: 'space-around',
          }}
        >
          <div>All rights reserved</div>
          <div>© Breakout Messaging 2021</div>
        </BreakingFlexRow>
        <BreakingFlexRow style={{marginBottom: '30px', alignSelf: 'stretch', justifyContent: 'center'}}>
          <SvgBreakoutLogoNoText />
          <div style={{marginLeft: '30px'}}>Breakout Messaging is a Belgian company based in Europe.</div>
        </BreakingFlexRow>
      </CenterMaxWidth2>
    </WithStretchedBackground>
  );
}

function WaveBottomSvg() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 375 328' preserveAspectRatio='none'>
      <g transform='translate(0 -4626)'>
        <path
          d='M0-48.334S14.2-85.166,86.859-85.166,213.492-44.1,289.42-36.241,375-48.334,375-48.334V242.834H0Z'
          transform='translate(0 4711.166)'
          fill='#1f1f1f'
        />
      </g>
    </svg>
  );
}
