import {MobileHeaderComponent} from '../MobileHeaderComponent';
import {FlexCenterColumn} from '../Utils/LayoutStyles';
import {DeleteAccountContent} from './ParticipantsComponent';
import React from 'react';
import {RequireLoginComponent} from './RequireLoginComponent';
import {ChatsContextContainerComponent, ChatsLoaderComponent} from './DashboardComponent';

export function DeleteAccountPage() {
  return (
    <RequireLoginComponent>
      <ChatsContextContainerComponent>
        <ChatsContextContainerComponent>
          <ChatsLoaderComponent params={'home'}>
            <MobileHeaderComponent title='Delete your account' />
            <FlexCenterColumn
              style={{alignItems: 'center', justifyContent: 'center', flex: '1 1 auto', padding: '30px'}}
            >
              <DeleteAccountContent />
            </FlexCenterColumn>
          </ChatsLoaderComponent>
        </ChatsContextContainerComponent>
      </ChatsContextContainerComponent>
    </RequireLoginComponent>
  );
}
