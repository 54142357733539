import React from 'react';
import {SvgChatIcon} from './ChatRoomsComponent';

export function ActivityImageComponent({
  activity,
  noBottomBorderRadius,
}: {
  activity: {name: string; photoUrl: string | null};
  noBottomBorderRadius?: boolean;
}) {
  return activity.photoUrl ? (
    <img
      alt={activity.name}
      src={activity.photoUrl}
      referrerPolicy='no-referrer'
      style={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: noBottomBorderRadius ? '5px 5px 0 0' : '5px',
      }}
    />
  ) : (
    <SvgChatIcon size='60px' />
  );
}
