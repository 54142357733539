import {useAsyncEffect} from '../Utils/useAsyncEffect';
import {useChatRoomsCollection} from './collections';
import React, {useState} from 'react';
import {ChatRecord} from '../../model';
import {FlexCenterColumn, FlexCenterer} from '../Utils/LayoutStyles';
import {makeShareUrl} from './qr-code-helper';
import {SvgBreakoutLogoWithText} from './SvgBreakoutLogoWithText';
import styled from 'styled-components';
import {StyledQrCodeComponent} from './QrCodeComponent';
import {PrimaryBlack} from './firebase-styles';
import {Spinner} from '../Utils/SmallSpinner';

export function QrPrintComponent({roomId}: {roomId: string}) {
  const [room, setRoom] = useState<ChatRecord | 'not-found' | 'loading'>('loading');
  const chatRoomsCollection = useChatRoomsCollection();
  useAsyncEffect(
    async ({wrap}) => {
      const doc = await wrap(chatRoomsCollection.doc(roomId).get());
      const room = doc.data();
      if (!doc.exists || !room) {
        setRoom('not-found');
        return;
      }
      setRoom(room);
    },
    [roomId]
  );

  if (room === 'loading') {
    return <Spinner />;
  }

  if (room === 'not-found') {
    return <FlexCenterer>Room not found</FlexCenterer>;
  }

  const url = makeShareUrl(room.token);

  return <QrPrintLayout text={room.name} qr={url} />;
}

export function QrPrintLayout({text, qr}: {text: string; qr: string}) {
  return (
    <StyledCenterer
      style={{
        backgroundColor: 'white',
        width: '29.7cm',
        height: '21cm',
        justifyContent: 'space-between',
      }}
    >
      <div style={{fontSize: '40px', fontWeight: 600, letterSpacing: '0.07em', marginTop: '3cm'}}>{text}</div>
      <StyledQrCodeComponent data={qr} />
      <div style={{width: '110px', marginBottom: '2cm'}}>
        <SvgBreakoutLogoWithText color={PrimaryBlack} />
      </div>
    </StyledCenterer>
  );
}

const StyledCenterer = styled(FlexCenterColumn)`
  @page {
    size: landscape;
  }
  body {
    writing-mode: tb-rl;
    height: auto;
  }
`;
