import React, {useState} from 'react';
import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {Drawer} from '@blueprintjs/core';
import {useChatContext} from './chat-context';
import {UserWithUrl} from './ParticipantsComponent';
import {useFirebaseUserInfoSafe} from './RequireLoginComponent';
import {useFirebaseAuth} from './firebase';
import {useHistory} from 'react-router';
import {BetaRoute, ContactRoute, DeleteAccountRoute, MainRoute, UserInfoRoute} from '../Shell/RouteDefinitions';
import {useChatRoomsCollection, useUsersMessagingCollection} from './collections';
import {logout} from './logout-helper';
import styled from 'styled-components';
import {ChatImageComponent} from './ChatRoomsComponent';
import {Icon} from '@iconify/react';
import helpIcon from '@iconify-icons/ion/help-circle-outline';
import logoutIcon from '@iconify-icons/mdi/exit-to-app';
import closeIcon from '@iconify-icons/ion/close';
import menuIcon from '@iconify-icons/ion/menu';
import loginIcon from '@iconify-icons/ion/log-in-outline';
import warningIcon from '@iconify-icons/ion/warning-outline';
import contactSupportIcon from '@iconify-icons/ic/outline-contact-support';
import {VersionComponent} from './VersionComponent';
import trash2 from '@iconify-icons/feather/trash-2';

export function DrawerComponent() {
  const [menuOpen, setMenuOpen] = useState(false);

  const {chatContext} = useChatContext();
  const {userInfo, setUserInfo} = useFirebaseUserInfoSafe();
  const auth = useFirebaseAuth();
  const history = useHistory();

  const chatCollection = useChatRoomsCollection();
  const userInfoCollection = useUsersMessagingCollection();
  const iconSize = 30;

  return (
    <FlexCenterRow style={{alignSelf: 'stretch'}}>
      <Drawer
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        position='left'
        size='85%'
        style={{display: 'flex', flexDirection: 'column', position: 'relative', height: '100%', overflow: 'auto'}}
      >
        <div style={{alignSelf: 'flex-end', position: 'absolute'}} onClick={() => setMenuOpen(false)}>
          <Icon icon={closeIcon} width={40} height={40} />
        </div>
        <FlexRow
          style={{padding: '20px 30px'}}
          onClick={() => {
            if (userInfo) {
              history.push(MainRoute.makePath('edit-info'));
            } else {
              history.push(MainRoute.makePath('home'));
            }
            setMenuOpen(false);
          }}
        >
          {userInfo ? (
            <UserWithUrl user={userInfo.info} size={50} />
          ) : (
            <StyledDrawerLinkButton>
              <Icon icon={loginIcon} width={iconSize} height={iconSize} />
              <div>Login</div>
            </StyledDrawerLinkButton>
          )}
        </FlexRow>
        <StyledDrawerDivider />
        <FlexColumn>
          {userInfo &&
            chatContext.orderedChats.map((x) => (
              <StyledDrawerLinkButton
                key={x.documentId}
                onClick={() => {
                  setUserInfo({...userInfo, lastChatId: x.documentId});
                  userInfoCollection.doc(userInfo.documentId).set({lastChatId: x.documentId}, {merge: true});
                  history.push(MainRoute.makePath({destination: {type: 'chat', chatId: x.documentId}}));
                  setMenuOpen(false);
                }}
              >
                <ChatImageComponent chat={x} isSelected={false} size={50} />
                <DrawerText>{x.name}</DrawerText>
              </StyledDrawerLinkButton>
            ))}
        </FlexColumn>
        <FlexFiller />
        <StyledDrawerDivider />
        <StyledDrawerLinkButton
          onClick={async () => {
            history.push(UserInfoRoute.makePath());
          }}
        >
          <Icon icon={helpIcon} width={iconSize} height={iconSize} />
          <div>Help</div>
        </StyledDrawerLinkButton>
        <StyledDrawerDivider />
        <StyledDrawerLinkButton
          onClick={async () => {
            history.push(ContactRoute.makePath());
          }}
        >
          <Icon icon={contactSupportIcon} width={iconSize} height={iconSize} />
          <div>Contact us</div>
        </StyledDrawerLinkButton>
        <StyledDrawerDivider />
        {userInfo && (
          <StyledDrawerLinkButton
            onClick={async () => {
              await logout(auth, userInfo, chatCollection, userInfoCollection);
              window.location.href = '/';
            }}
            data-test='test-logout-btn'
          >
            <Icon icon={logoutIcon} width={iconSize} height={iconSize} />
            <div>Logout</div>
          </StyledDrawerLinkButton>
        )}
        <StyledDrawerDivider />
        <StyledDrawerLinkButton
          onClick={async () => {
            history.push(DeleteAccountRoute.makePath());
          }}
          data-test='test-menuDelete-btn'
        >
          <Icon icon={trash2} width={iconSize} height={iconSize} />
          <div>Delete my account</div>
        </StyledDrawerLinkButton>
        <StyledDrawerDivider />
        <StyledDrawerLinkButton
          onClick={async () => {
            history.push(BetaRoute.makePath());
          }}
        >
          <Icon icon={warningIcon} width={iconSize} height={iconSize} />
          <div>Beta</div>
        </StyledDrawerLinkButton>
        <StyledDrawerDivider style={{marginBottom: '2px'}} />
        <VersionComponent />
      </Drawer>
      <FlexRow
        style={{width: '60px', height: '60px', alignItems: 'center', justifyContent: 'center'}}
        onClick={() => setMenuOpen(true)}
        data-test='test-menuMobile-btn'
      >
        <Icon icon={menuIcon} width={iconSize} height={iconSize} />
      </FlexRow>
    </FlexCenterRow>
  );
}

const StyledDrawerDivider = styled.div`
  height: 1px;
  border-top: 1px solid rgba(68, 77, 89, 0.3);
`;

const DrawerText = styled(FlexFiller)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDrawerLinkButton = styled(FlexCenterRow)`
  cursor: pointer;
  align-self: stretch;
  height: 80px;
  padding: 0 30px;

  font-size: 18px;

  :hover {
    text-decoration: underline;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
`;
