import {FlexCenterRow} from './Utils/LayoutStyles';
import React from 'react';
import styled from 'styled-components';
import homeBaseline from '@iconify-icons/ic/baseline-home';
import homeOutline from '@iconify-icons/ic/outline-home';
import peopleBaseline from '@iconify-icons/ic/baseline-people';
import peopleOutline from '@iconify-icons/ic/baseline-people-outline';
import {Icon} from '@iconify/react';
import {SvgLogoBubbleFilled} from './Firebase/SvgLogoBubbleFilled';
import {StyledCircleClip} from './Utils/CircledText';
import {PrimaryRed} from './Firebase/firebase-styles';
import {useChatContext} from './Firebase/chat-context';
import _ from 'lodash';
import {formatUnreadMessages, getUnreadMessagesCount, getUnreadPrivateMessagesCount} from '../model';
import {useFirebaseUserInfo} from './Firebase/RequireLoginComponent';
import {useIsMobileKeyboardOpen} from './Shell/adaptive';

export type MobileFooterButton = 'home' | 'chat' | 'messages';

export function MobileFooterComponent({
  onClick,
  selectedButton,
}: {
  selectedButton: MobileFooterButton;
  onClick: (button: MobileFooterButton) => void;
}) {
  const radius = '35px';
  const iconSize = 50;

  const {userInfo} = useFirebaseUserInfo();
  const isKeyboardOpen = useIsMobileKeyboardOpen();

  const chatContext = useChatContext();
  const unreadMessages = _.sum(chatContext.chatContext.orderedChats.map((x) => getUnreadMessagesCount(userInfo, x)));
  const unreadPrivateMessages = _.sum(
    chatContext.chatContext.orderedDirectChats.map((x) => getUnreadPrivateMessagesCount(userInfo, x))
  );

  if (isKeyboardOpen) {
    return <></>;
  }

  return (
    <FlexCenterRow
      style={{
        background: '#7A889A',
        color: 'white',
        borderTopLeftRadius: radius,
        borderTopRightRadius: radius,
        height: '70px',
      }}
    >
      <StyledButton onClick={() => onClick('home')} data-test='test-mobileHome-btn'>
        <Icon icon={selectedButton === 'home' ? homeBaseline : homeOutline} width={iconSize} height={iconSize} />
      </StyledButton>
      <div style={{position: 'relative', width: '70px', height: '70px'}} onClick={() => onClick('chat')}>
        <SvgMiddleButtonContainer />
        <FlexCenterRow
          style={{position: 'absolute', top: 0, left: 0, width: '70px', height: '70px', justifyContent: 'center'}}
        >
          <SvgLogoBubbleFilled filled={selectedButton === 'chat'} />
        </FlexCenterRow>
        {unreadMessages > 0 && (
          <StyledCircleClip
            size={17}
            background={PrimaryRed}
            color='white'
            style={{position: 'absolute', top: '15px', right: '15px'}}
          >
            {formatUnreadMessages(unreadMessages, 9)}
          </StyledCircleClip>
        )}
      </div>
      <StyledButton onClick={() => onClick('messages')}>
        <div style={{position: 'relative'}}>
          <Icon
            icon={selectedButton === 'messages' ? peopleBaseline : peopleOutline}
            width={iconSize}
            height={iconSize}
          />
          {unreadPrivateMessages > 0 && (
            <StyledCircleClip
              size={17}
              background={PrimaryRed}
              color='white'
              style={{position: 'absolute', top: '5px', right: '-5px'}}
            >
              {formatUnreadMessages(unreadPrivateMessages, 9)}
            </StyledCircleClip>
          )}
        </div>
      </StyledButton>
    </FlexCenterRow>
  );
}

function SvgMiddleButtonContainer() {
  // stroke overflows the dimension so clip it with div
  return (
    <div style={{width: '70px', height: '70px', overflow: 'hidden', position: 'absolute'}}>
      <svg width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='#444d59'
          d='M 40.06 69.996 L 0 69.996 C 3.715 61.135 4.179 51.247 1.309 42.077 C 0.452 39.168 0.011 36.154 0 33.121 C 0 16.171 13.52 2.172 30.975 0.233 L 30.975 0.197 C 33.65 -0.066 36.345 -0.066 39.02 0.197 L 39.02 0.229 C 56.477 2.166 69.997 16.166 69.997 33.121 C 69.986 36.155 69.545 39.17 68.687 42.08 C 65.818 51.25 69.997 70 69.997 70 L 40.06 69.996 Z'
        />
      </svg>
    </div>
  );
}

export const StyledButton = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  height: 58px;
  color: white;
`;
