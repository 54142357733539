import {resolveValue, ValueOrCallback} from '../Utils/typescript';
import React, {CSSProperties, HTMLAttributes, ReactNode} from 'react';
import {useMediaQuery} from 'react-responsive';
import {mobileWidth} from '../Shell/adaptive';

export type BreakingRenderParams = {isBroken: boolean};

export function BreakingFlexRow({
  children,
  style,
  ...props
}: {
  children: ValueOrCallback<ReactNode, BreakingRenderParams>;
  style: ValueOrCallback<CSSProperties, BreakingRenderParams>;
} & Omit<HTMLAttributes<HTMLDivElement>, 'style'>) {
  const isNarrow = useMediaQuery({maxWidth: `${mobileWidth}px`});
  const params = {isBroken: isNarrow};
  const styleValues = resolveValue(style, params);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: isNarrow ? 'column' : 'row',
        ...styleValues,
      }}
      {...props}
    >
      {resolveValue(children, params)}
    </div>
  );
}
