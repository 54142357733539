import {FlexCenterer} from '../../Utils/LayoutStyles';
import {ContactRoute} from '../../Shell/RouteDefinitions';
import {MobileHeaderComponent} from '../../MobileHeaderComponent';
import {StyledRouterLink} from '../../Utils/NavigationLinks';
import {useIsSmall} from '../../Shell/adaptive';

export function Beta() {
  const isSmall = useIsSmall();

  const body = (
    <FlexCenterer>
      <div style={{flexShrink: 1, margin: '30px'}}>
        You are currently using the beta version of Breakout. As a result, you may experience some bugs. Could you help
        us out hunting them down by clicking on{' '}
        <StyledRouterLink to={ContactRoute.makePath()} style={{textDecoration: 'underline'}}>
          this link
        </StyledRouterLink>{' '}
        and filling the form? We will be forever grateful! <br />
        <br />
        Best, Timur & Aure 😊
      </div>
    </FlexCenterer>
  );

  if (isSmall) {
    return (
      <>
        <MobileHeaderComponent title='BETA' />
        {body}
      </>
    );
  }

  return <>{body}</>;
}
