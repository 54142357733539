import React, {useState} from 'react';
import {FlexColumn, FlexRow} from '../../Utils/LayoutStyles';
import {AsyncButtonBase} from '../../Utils/AsyncButton';
import {StyledInputGroup, StyledMainButton} from '../firebase-styles';
import {versions} from '../../versions';
import {MobileHeaderComponent} from '../../MobileHeaderComponent';
import {useIsSmall} from '../../Shell/adaptive';
import {useFirebaseUserInfoSafe} from '../RequireLoginComponent';
import {useEffectDebug} from '../../Utils/useEffectDebug';
import {Leaf1Svg} from './Leaf1Svg';

export function ContactPage() {
  const isSmall = useIsSmall();

  if (isSmall) {
    return (
      <>
        <MobileHeaderComponent title='Contact us' />
        <FlexColumn style={{alignSelf: 'stretch', padding: '0 40px'}}>
          <ContactFormContent />
        </FlexColumn>
      </>
    );
  }

  return <ContactForm />;
}

export function ContactFormContent() {
  const {userInfo, firebaseUser} = useFirebaseUserInfoSafe();
  const [name, setName] = useState(userInfo?.info.displayName ?? '');
  const [email, setEmail] = useState(firebaseUser?.email ?? '');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  useEffectDebug(() => {
    if (userInfo) {
      setName(userInfo.info.displayName);
    }
  }, [userInfo]);

  useEffectDebug(() => {
    if (firebaseUser) {
      setEmail(firebaseUser.email ?? '');
    }
  }, [firebaseUser]);
  return (
    <>
      <FlexRow style={{flexWrap: 'wrap', marginRight: '-30px'}}>
        <StyledInputGroup style={{flex: '1 1 auto', marginRight: '30px'}}>
          <div>Name</div>
          <input placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} />
        </StyledInputGroup>
        <StyledInputGroup style={{flex: '1 1 auto', marginRight: '30px'}}>
          <div>Email</div>
          <input placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </StyledInputGroup>
      </FlexRow>
      <StyledInputGroup>
        <div>Subject</div>
        <input placeholder='Enter subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
      </StyledInputGroup>
      <StyledInputGroup>
        <div>Message</div>
        <textarea
          style={{height: '90px'}}
          placeholder='Enter message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </StyledInputGroup>
      <AsyncButtonBase
        render={({onClick, isLoading, isSucceeded}) => (
          <StyledMainButton
            style={{marginTop: '50px', width: '260px', maxWidth: '100%', height: '50px', alignSelf: 'center'}}
            disabled={isLoading || isSucceeded}
            onClick={onClick}
          >
            {isSucceeded ? 'Thank you!' : 'Submit'}
          </StyledMainButton>
        )}
        onClickAsync={async () => {
          const data = new FormData();
          data.append('entry.528523273', `${versions.version}-${versions.revision}`);
          data.append('entry.813425190', name);
          data.append('entry.1869947860', email);
          data.append('entry.949221088', subject);
          data.append('entry.1270357919', message);
          await fetch(
            'https://docs.google.com/forms/d/e/1FAIpQLScXO1J4YBbwdFpB3V7u3bvTionsy3WLPDpoKmMiKDsE7ycXdw/formResponse',
            {
              method: 'POST',
              mode: 'no-cors',
              body: data,
            }
          );
        }}
      />
    </>
  );
}

export function ContactForm() {
  return (
    <FlexColumn
      style={{
        boxShadow: '0px 15px 25px #454D5A40',
        borderRadius: '34px 0px 34px 34px',
        background: 'white',
        padding: '50px 45px',
        position: 'relative',
        margin: '40px',
      }}
    >
      <div style={{position: 'absolute', top: '-60px', right: '-30px'}}>
        <Leaf1Svg />
      </div>
      <div style={{fontSize: '50px'}}>Contact us</div>
      <ContactFormContent />
    </FlexColumn>
  );
}
