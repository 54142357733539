import {LinkedList} from './LinkedList';

export type Subscription = {
  unsubscribe: () => void;
};

export type Subscribable<TData = void> = {
  (cb: (data: TData) => void): Subscription;
};

export type PublishSubscribe<T = void> = {
  subscribable: Subscribable<T>;
  fire: (data: T) => void;
};

export function makeSubscribable<T = void>(fireOnSubscribe = true, initial?: T): PublishSubscribe<T> {
  const list = new LinkedList<(data: T) => void>();
  let last: {item: T} | undefined = initial ? {item: initial} : undefined;
  return {
    fire: (data: T) => {
      if (fireOnSubscribe) {
        last = {item: data};
      }
      list.forEach((x) => {
        x(data);
      });
    },
    subscribable: (callback: (data: T) => void) => {
      const node = list.add(callback);
      if (fireOnSubscribe && last !== undefined) {
        callback(last.item);
      }
      return {
        unsubscribe: () => {
          node.detachSelf();
        },
      };
    },
  };
}
