import React, {useCallback, useState} from 'react';
import {FlexCenterColumn, FlexCenterRow, FlexColumn} from '../Utils/LayoutStyles';
import {Dialog} from '@blueprintjs/core';
import {LoadedChat, useChatContext} from './chat-context';
import {ChatMaxWidth, HeaderHeight, PrimaryRed} from './firebase-styles';
import {ManageChatComponent} from './ManageChatComponent';
import {ShareComponent} from './ShareComponent';
import {ChatBodyComponent} from './ChatBodyComponent';
import {ChatImageComponent} from './ChatRoomsComponent';
import styled from 'styled-components';
import {Spinner} from '../Utils/SmallSpinner';
import {EditChatComponent} from './EditChatComponent';

export function DesktopChatComponent({chatId, edit}: {chatId: string; edit: boolean}) {
  const {chatContext} = useChatContext();
  const chat = chatContext.orderedChats.find((x) => x.documentId === chatId);

  const [showChatInfo, setShowChatInfo] = useState(false);

  const closeChatInfo = useCallback(() => {
    setShowChatInfo(false);
  }, []);

  if (!chat) {
    if (!chatContext.loadedChats) return <Spinner />;
    else return <div>Could not find the chat.</div>;
  }

  return (
    <>
      <FlexCenterRow
        style={{
          height: HeaderHeight,
          alignSelf: 'stretch',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexCenterRow style={{maxWidth: ChatMaxWidth, flex: '1 1 0', paddingRight: '10px'}}>
          <FlexColumn
            style={{marginLeft: '12.5px', marginRight: '20px', justifyContent: 'center', cursor: 'pointer'}}
            onClick={() => {
              setShowChatInfo(true);
            }}
          >
            <ChatImageComponent chat={chat} isSelected={false} />
          </FlexColumn>
          <FlexColumn style={{flex: '1 1 0'}}>
            <StyledCommunityName>{chat.name}</StyledCommunityName>
            <div>{chat.participants.length} members</div>
          </FlexColumn>
          <ShareComponent token={chat.token} roomId={chat.documentId} />
          <ManageChatComponent chat={chat} />
        </FlexCenterRow>
      </FlexCenterRow>
      {edit ? <EditChatComponent chat={chat} /> : <ChatBodyComponent chat={chat} />}
      <Dialog isOpen={showChatInfo} onClose={closeChatInfo}>
        {chat && <ChatInfoComponent chat={chat} />}
      </Dialog>
    </>
  );
}

const StyledCommunityName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${PrimaryRed};
  font-size: 21px;
`;

function ChatInfoComponent({chat}: {chat: LoadedChat}) {
  return (
    <FlexCenterColumn>
      <ChatImageComponent chat={chat} size={160} unclipped={true} />
      <div style={{fontSize: '18px'}}>{chat.name}</div>
      <div style={{marginTop: '30px'}}>{chat.description}</div>
    </FlexCenterColumn>
  );
}
