import {useEffect, useState} from 'react';
import {FlexCenterRow, FlexColumn} from '../Utils/LayoutStyles';
import {StyledBlackButton} from './firebase-styles';
import {randomToken} from './random-token';
import {QrPrintLayout} from './QrPrintComponent';
import {makeShareUrl} from './qr-code-helper';

export function QrGenerationComponent() {
  const [count, setCount] = useState(5);
  const [text, setText] = useState('per aspera ad astra');
  const [codes, setCodes] = useState<Array<{text: string; qr: string}>>([]);

  useEffect(() => {
    const c = [];
    for (let i = 0; i < count; i++) {
      c.push({qr: makeShareUrl(randomToken()), text});
    }
    setCodes(c);
  }, [count, text]);

  return (
    <FlexColumn>
      <FlexColumn className='noprint'>
        <FlexCenterRow>
          <div>text</div>
          <input value={text} onChange={(e) => setText(e.target.value)} />
        </FlexCenterRow>
        <FlexCenterRow>
          <div>count</div>
          <input type='number' value={count} onChange={(e) => setCount(e.target.valueAsNumber)} />
        </FlexCenterRow>
        <StyledBlackButton
          style={{margin: '0 20px'}}
          onClick={() => {
            const c = [];
            for (let i = 0; i < count; i++) {
              c.push({qr: makeShareUrl(randomToken()), text});
            }
            setCodes(c);
          }}
        >
          Generate
        </StyledBlackButton>
      </FlexColumn>

      {codes.map((x) => (
        <QrPrintLayout key={x.qr} text={x.text} qr={x.qr} />
      ))}
    </FlexColumn>
  );
}
