export function SvgLogoBubbleFilled({filled}: {filled?: boolean}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27.311' height='24.266' viewBox='0 0 27.311 24.266'>
      <path
        fill={filled ? 'white' : 'transparent'}
        stroke='white'
        strokeWidth={2}
        d='M210.8,201.781V187.932a7.825,7.825,0,0,1,7.832-7.832h9.647a7.825,7.825,0,0,1,7.832,7.832v4.616a7.825,7.825,0,0,1-7.832,7.832h0a12.353,12.353,0,0,1-3.371-.519C222.678,199.239,217.647,198.513,210.8,201.781Z'
        transform='translate(-209.8 -179.1)'
      />
    </svg>
  );
}
