import {Button, ButtonProps} from '@blueprintjs/core';
import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {useSnackbar} from './useSnackbar';
import styled from 'styled-components';
import {FlexCenterRow} from './LayoutStyles';
import {useIsMounted} from './useIsMounted';
import {Icon} from '@iconify/react';
import checkIcon from '@iconify-icons/ion/checkmark';
import {Spinner} from './SmallSpinner';

const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 200ms;

  margin-top: -3px;
`;

const StyledContentContainer = styled.div`
  transition: opacity 200ms;
`;

export function AsyncButton({
  onClickAsync,
  children,
  hideCheck,
  isLoading,
  ...props
}: ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    onClickAsync: () => Promise<void>;
    children?: ReactNode;
    isLoading?: boolean;
    hideCheck?: boolean;
  }) {
  const [loading, setLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const snackbar = useSnackbar();
  const isMounted = useIsMounted();

  const content =
    isOk && !hideCheck ? (
      <FlexCenterRow>
        {children}
        <Icon icon={checkIcon} />
      </FlexCenterRow>
    ) : (
      children
    );

  return (
    <Button
      {...props}
      disabled={loading || isLoading || props.disabled}
      onClick={async () => {
        setLoading(true);
        try {
          await onClickAsync();
          setIsOk(true);
        } catch (error: any) {
          if (error.toString) snackbar.show(error.toString());
          else snackbar.show(`${error}`);
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      }}
    >
      <div style={{position: 'relative'}}>
        <StyledContentContainer style={{opacity: loading || isLoading ? 0.3 : 1}}>{content}</StyledContentContainer>
        {
          <StyledSpinnerContainer style={{opacity: loading || isLoading ? 1 : 0}}>
            <Spinner size={24} />
          </StyledSpinnerContainer>
        }
      </div>
    </Button>
  );
}

export type AsyncButtonState = {
  isLoading: boolean;
  isSucceeded: boolean;
  onClick: () => void;
};

export function AsyncButtonBase({
  render,
  onClickAsync,
}: {
  render: (state: AsyncButtonState) => ReactNode;
  onClickAsync: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const isMounted = useIsMounted();

  const clickHandler = useCallback(async () => {
    setLoading(true);
    setIsOk(false);
    try {
      await onClickAsync();
      setIsOk(true);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [isMounted, onClickAsync]);

  const state = useMemo(
    () => ({
      isLoading: loading,
      isSucceeded: isOk,
      onClick: clickHandler,
    }),
    [loading, isOk, clickHandler]
  );

  return <>{render(state)}</>;
}
