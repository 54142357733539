import {QrPrintRoute} from '../Shell/RouteDefinitions';
import React, {useState} from 'react';
import {useSnackbar} from '../Utils/useSnackbar';
import {Popover} from '@blueprintjs/core';
import copy from 'copy-to-clipboard';
import {PrimaryRed, StyledHoverMenuButton, StyledMenuLinkButton, StyledPopupMenu} from './firebase-styles';

import {Icon} from '@iconify/react';
import duplicateIcon from '@iconify-icons/ion/duplicate';
import printIcon from '@iconify-icons/ion/print';
import shareIcon from '@iconify-icons/ion/share';
import {getAppUrl, makeShareUrl} from './qr-code-helper';
import {StyledQrCodeComponent} from './QrCodeComponent';

export function ShareComponent({token, roomId}: {token: string; roomId: string}) {
  const url = makeShareUrl(token);
  const [showDialog, setShowDialog] = useState(false);
  const snackbar = useSnackbar();

  return (
    <>
      <Popover
        placement='bottom'
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        content={
          <StyledPopupMenu>
            <StyledQrCodeComponent data={url} size={150} background={PrimaryRed} hideLogo={true} />
            <div data-test='test-copyUrl-text' style={{marginTop: '20px', overflow: 'hidden', wordBreak: 'break-word'}}>
              {url}
            </div>
            <StyledMenuLinkButton
              data-test='test-copyUrl-btn'
              onClick={() => {
                copy(url);
                snackbar.show('Copied to clipboard.');
              }}
            >
              <Icon icon={duplicateIcon} width={22} height={22} />
              <div>Copy URL</div>
            </StyledMenuLinkButton>
            <StyledMenuLinkButton
              onClick={() => {
                const appUrl = getAppUrl();
                window.open(`${appUrl}#${QrPrintRoute.makePath({roomId})}`);
              }}
            >
              <Icon icon={printIcon} width={22} height={22} />
              <div>Print QR code</div>
            </StyledMenuLinkButton>
          </StyledPopupMenu>
        }
      >
        <StyledHoverMenuButton
          onClick={() => setShowDialog(!showDialog)}
          active={showDialog}
          style={{padding: '0 40px'}}
          data-test='test-share-btn'
        >
          <Icon icon={shareIcon} width={22} height={22} />
          <div>Share</div>
        </StyledHoverMenuButton>
      </Popover>
    </>
  );
}
