import * as History from 'history';
import React, {useContext} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styled from 'styled-components';
import {AdaptiveContext} from '../Shell/adaptive';

export const LinkLikeButton = styled.div`
  cursor: pointer;

  :hover {
    text-decoration: underline;
    color: #e9e9ff;
  }
`;

export function StyledRouterLink<S = History.LocationState>({onClick, style, ...props}: LinkProps<S>) {
  const adaptiveContext = useContext(AdaptiveContext);
  return (
    <Link
      style={{color: 'unset', ...style}}
      onClick={(e) => {
        adaptiveContext.onResetScroll.fire();
        if (onClick) {
          onClick(e);
        }
        e.stopPropagation();
      }}
      {...props}
    />
  );
}
