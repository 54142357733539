import {FlexRow} from './LayoutStyles';
import {DraggableThumb} from './DraggableThumb';

export function Slider({
  min,
  max,
  value,
  onChanged,
}: {
  min: number;
  max: number;
  value: number;
  onChanged: (n: number) => void;
}) {
  // useLayoutEffect(() => {
  //
  // }, [min, max, value]);

  const width = 300;
  const total = max - min;
  const valuePerPx = total / width;
  const position = (value - min) / valuePerPx;

  return (
    <FlexRow style={{position: 'relative', width: width + 'px', height: '30px', background: 'blue'}}>
      <DraggableThumb
        onDrag={(dx) => {
          onChanged(value + dx * valuePerPx);
        }}
        render={({onMouseDown}) => (
          <div
            style={{
              position: 'absolute',
              left: position + 'px',
              width: '30px',
              height: '30px',
              backgroundColor: 'green',
            }}
            onMouseDown={onMouseDown}
          />
        )}
      />
    </FlexRow>
  );
}
