import {LoadedChat} from './chat-context';
import {useCollectionHelpers} from './collections';
import {StyledMenuLinkButton} from './firebase-styles';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {MainRoute} from '../Shell/RouteDefinitions';

export function NonAdminContent({chat}: {chat: LoadedChat}) {
  const history = useHistory();
  const [leaving, setLeaving] = useState(false);
  const {leaveChat} = useCollectionHelpers();

  return (
    <>
      <StyledMenuLinkButton
        onClick={async () => {
          if (leaving) return;
          setLeaving(true);
          await leaveChat(chat.documentId);
          setLeaving(false);
          history.push(MainRoute.makePath('home'));
        }}
        data-test='test-leaveChat-btn'
      >
        Leave chat
      </StyledMenuLinkButton>
    </>
  );
}
