import {getDisplayName, getUserId, isLoadedUser, LoadedDirectChat, useChatContext} from './chat-context';
import {Popover} from '@blueprintjs/core';
import {FlexCenterRow, FlexColumn} from '../Utils/LayoutStyles';
import {
  BackgroundGray,
  ChatMaxWidth,
  HeaderHeight,
  PrimaryRed,
  StyledMenuButton,
  StyledMenuLinkButton,
  StyledPopupMenu,
} from './firebase-styles';
import {DirectChatBodyComponent} from './DirectChatBodyComponent';
import {UserImage} from './ParticipantsComponent';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import React, {useState} from 'react';
import {NestedMap, UserObjectReference} from '../../model';
import {useUsersMessagingCollection} from './collections';
import firebase from '@firebase/app';
import {Spinner} from '../Utils/SmallSpinner';

export function PrivateMessageChatComponent({userId}: {userId: string}) {
  const {chatContext} = useChatContext();
  const {userInfo} = useFirebaseUserInfo();
  const [showMenu, setShowMenu] = useState(false);

  const chat = chatContext.orderedDirectChats.find((x) => getUserId(x.otherUser) === userId);

  const isBanned = userInfo.bannedUsers && userInfo.bannedUsers[userId];

  if (!chat) return <Spinner />;

  return (
    <>
      <FlexCenterRow
        style={{
          height: HeaderHeight,
          borderRight: `1px solid ${BackgroundGray}`,
          alignSelf: 'stretch',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexCenterRow style={{maxWidth: ChatMaxWidth, flex: '1 1 0', paddingRight: '10px'}}>
          <FlexColumn style={{marginLeft: '10px', marginRight: '20px', justifyContent: 'center'}}>
            <UserImage user={chat.otherUser} size={60} imageStyle='black' />
          </FlexColumn>
          <FlexColumn style={{flex: '1 1 0'}}>
            <div
              style={{
                color: PrimaryRed,
                fontSize: '24px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {getDisplayName(chat.otherUser)}
            </div>
          </FlexColumn>

          {isBanned && (
            <>
              <div>User is blocked</div>
            </>
          )}

          <Popover
            isOpen={showMenu}
            onClose={() => setShowMenu(false)}
            position='bottom'
            content={
              <StyledPopupMenu>
                <BlockUnblockToggleComponent chat={chat} />
              </StyledPopupMenu>
            }
          >
            <StyledMenuButton onClick={() => setShowMenu(true)} />
          </Popover>
        </FlexCenterRow>
      </FlexCenterRow>
      <DirectChatBodyComponent chat={chat} />
    </>
  );
}

export function BlockUnblockToggleComponent({chat}: {chat: LoadedDirectChat}) {
  const {userInfo} = useFirebaseUserInfo();
  const userId = getUserId(chat.otherUser);
  const isBanned = userInfo.bannedUsers && userInfo.bannedUsers[userId];
  const usersMessagingCollection = useUsersMessagingCollection();

  return (
    <>
      {isBanned ? (
        <StyledMenuLinkButton
          onClick={async () => {
            if (!chat || !isLoadedUser(chat.otherUser)) return;
            const bannedUsers: any = {};
            bannedUsers[chat.otherUser.id] = firebase.firestore.FieldValue.delete();
            await usersMessagingCollection.doc(userInfo.documentId).set({bannedUsers}, {merge: true});
          }}
        >
          Unblock
        </StyledMenuLinkButton>
      ) : (
        <StyledMenuLinkButton
          onClick={async () => {
            if (!chat || !isLoadedUser(chat.otherUser)) return;
            const bannedUsers: NestedMap<UserObjectReference> = {};
            const {bannedUsers: _, ...otherUser} = chat.otherUser;
            bannedUsers[chat.otherUser.id] = otherUser;
            await usersMessagingCollection.doc(userInfo.documentId).set({bannedUsers}, {merge: true});
          }}
        >
          Block
        </StyledMenuLinkButton>
      )}
    </>
  );
}
