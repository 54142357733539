import {CenterMaxWidth} from '../Utils/LayoutStyles';
import React from 'react';
import {useIsSmall} from '../Shell/adaptive';

export function CenterMaxWidth2({
  children,
  ...p
}: Omit<Parameters<typeof CenterMaxWidth>[0], 'maxWidth' | 'leftRightPadding'>) {
  const isSmall = useIsSmall();
  return (
    <CenterMaxWidth maxWidth='1364px' leftRightPadding={isSmall ? '10px' : '40px'} {...p}>
      {children}
    </CenterMaxWidth>
  );
}
