import {createContext, useContext, useLayoutEffect} from 'react';
import {makeSubscribable, PublishSubscribe} from '../Utils/subscriptions';
import {useForceUpdate} from '../Utils/useForceUpdate';
import {useDidUnmount} from '../Utils/useIsMounted';

export type AdaptiveContextType = {
  width: number;
  height: number;
  onChanged: PublishSubscribe;
  onResetScroll: PublishSubscribe;
};

export const AdaptiveContext = createContext<AdaptiveContextType>({
  width: 1000000,
  height: 1000000,
  onChanged: makeSubscribable(false),
  onResetScroll: makeSubscribable(false),
});

export const mobileWidth = 960;

export function useIsSmall() {
  const w = useScreenWidth();
  return w < mobileWidth;
}

export function useScreenSize() {
  const c = useContext(AdaptiveContext);
  const didUnmount = useDidUnmount();
  const forceUpdate = useForceUpdate();
  useLayoutEffect(() => {
    const s = c.onChanged.subscribable(() => {
      if (!didUnmount.current) {
        forceUpdate();
      }
    });
    return () => {
      s.unsubscribe();
    };
  }, [c.onChanged, forceUpdate, didUnmount]);
  return c;
}

export function useScreenWidth() {
  const size = useScreenSize();
  return size.width;
}

export function useIsMobileKeyboardOpen() {
  const size = useScreenSize();

  return size.height < 350;
}
