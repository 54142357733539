import styled from 'styled-components';

export const StyledCircleClip = styled.div<{size: number; background?: string; color?: string}>`
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: ${(props) => props.background ?? 'initial'};
  color: ${(props) => props.color ?? '#cccccc'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  overflow: hidden;
`;

export const StyledCircleClip2 = styled.div<{size: string; background?: string}>`
  border-radius: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: ${(props) => props.background ?? 'initial'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  overflow: hidden;
`;

export const StyledCircledText = styled.div<{size: number}>`
  border-radius: 50%;
  border: 1px solid #cccccc;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cccccc;
  font-size: 12px;
  overflow: hidden;
`;
