import {versions} from '../versions';
import React, {CSSProperties, useState} from 'react';
import {FlexCenterColumn, FlexCenterRow} from '../Utils/LayoutStyles';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {PwaState, swChangeEvent} from '../../serviceWorkerTypes';
import {LinkLikeButton} from '../Utils/NavigationLinks';
import {SmallSpinner} from '../Utils/SmallSpinner';

export function VersionComponent({style}: {style?: CSSProperties}) {
  const [state, setState] = useState<PwaState>({type: 'loading'});

  useEffectDebug(() => {
    const s = swChangeEvent.subscribable((data) => {
      setState(data);
    });
    return () => s.unsubscribe();
  }, []);

  const hasUpdate = state.type === 'onstatechange' && state.state === 'activated';
  const checkingForUpdate =
    state.type === 'registering' ||
    state.type === 'onupdatefound' ||
    (state.type === 'onstatechange' && state.state !== 'activated');

  return (
    <FlexCenterColumn style={{alignSelf: 'center', fontSize: 'smaller', ...style}}>
      <div>
        v.{versions.version}-{versions.revision}-{versions.branch}
      </div>
      <div>built: {versions.builtOn}</div>
      {checkingForUpdate && (
        <FlexCenterRow>
          <SmallSpinner />
          <div>Checking for updates...</div>
        </FlexCenterRow>
      )}
      {hasUpdate && (
        <LinkLikeButton onClick={() => window.location.reload()}>New version available. Switch now.</LinkLikeButton>
      )}
    </FlexCenterColumn>
  );
}
