import {QrCodeRoute} from '../Shell/RouteDefinitions';

export function getAppUrl() {
  return `${window.location.origin}${window.location.pathname}`;
}

export function makeShareUrl(token: string) {
  const appUrl = getAppUrl();
  return `${appUrl}#${QrCodeRoute.makePath({locationToken: token})}`;
}
