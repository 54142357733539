import {PrimaryRed} from './firebase-styles';

export function SvgSend() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18.86' height='18.858' viewBox='0 0 18.86 18.858'>
      <path
        fill={PrimaryRed}
        d='M22.768,4.545,4.737,12.4a.413.413,0,0,0,.015.752l4.877,2.756a.786.786,0,0,0,.9-.088l9.617-8.291c.064-.054.216-.157.275-.1s-.034.211-.088.275L12.011,17.08a.784.784,0,0,0-.079.938l3.188,5.113a.415.415,0,0,0,.747-.01L23.323,5.09A.413.413,0,0,0,22.768,4.545Z'
        transform='translate(-4.503 -4.503)'
      />
    </svg>
  );
}
