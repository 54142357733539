import {
  getCurrentResolvedDestination,
  MainRoute,
  MainRouteParams,
  ResolvedDestination,
} from '../Shell/RouteDefinitions';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {useHistory} from 'react-router';
import {useUsersMessagingCollection} from './collections';
import {useCallback} from 'react';
import {FlexCenterColumn, FlexColumn, FlexRow} from '../Utils/LayoutStyles';
import {ChatRoomsComponent} from './ChatRoomsComponent';
import {DesktopChatComponent} from './DesktopChatComponent';
import {PrivateMessageChatComponent} from './PrivateMessageChatComponent';
import {ParticipantsComponent} from './ParticipantsComponent';

export function DesktopDashboardLayout({params}: {params: MainRouteParams}) {
  const {userInfo} = useFirebaseUserInfo();
  const history = useHistory();
  const userInfoCollection = useUsersMessagingCollection();

  const proportion = 0.3;
  const minWidth = '300px';

  const resolvedDestination = getCurrentResolvedDestination(params, userInfo.lastChatId);

  const chatRoomId =
    resolvedDestination?.type === 'chat' || resolvedDestination?.type === 'edit-chat'
      ? resolvedDestination.chatId
      : null;
  const otherUserId = resolvedDestination?.type === 'direct' ? resolvedDestination.userId : null;

  const onRoomSelect = useCallback(
    (destination: ResolvedDestination) => {
      history.push(MainRoute.makePath({destination}));
      if (destination.type === 'chat') {
        userInfoCollection.doc(userInfo.documentId).set({lastChatId: destination.chatId}, {merge: true});
      }
    },
    [history, userInfoCollection, userInfo.documentId]
  );

  return (
    <FlexRow style={{alignSelf: 'stretch', flex: '1 1 0'}}>
      <FlexColumn style={{flex: `${proportion} ${proportion} 0`, minWidth}}>
        <ChatRoomsComponent selectedRoom={resolvedDestination} onRoomSelect={onRoomSelect} />
      </FlexColumn>
      <FlexCenterColumn style={{flex: '1 1 0'}}>
        {chatRoomId ? (
          <DesktopChatComponent chatId={chatRoomId} edit={resolvedDestination?.type === 'edit-chat'} />
        ) : otherUserId ? (
          <PrivateMessageChatComponent userId={otherUserId} />
        ) : (
          <FlexCenterColumn>Select a chat</FlexCenterColumn>
        )}
      </FlexCenterColumn>
      <FlexColumn style={{flex: `${proportion} ${proportion} 0`, minWidth, backgroundColor: 'white'}}>
        <ParticipantsComponent chatId={chatRoomId} />
      </FlexColumn>
    </FlexRow>
  );
}
