import {FlexRow} from '../Utils/LayoutStyles';
import {JoinChatComponent} from '../Firebase/JoinChatComponent';
import React from 'react';
import {TestBed, TestStage} from './TestStage';

export function JoinChatComponentDemo() {
  return (
    <TestStage>
      <FlexRow style={{flexWrap: 'wrap'}}>
        <TestBed>
          <JoinChatComponent token='fzhfl6fk3zll' days={1} />
        </TestBed>
        <TestBed>
          <JoinChatComponent token='fzhfl6fk3zll' days={undefined} />
        </TestBed>
      </FlexRow>
    </TestStage>
  );
}
