import {User} from '@firebase/auth-types';
import '@firebase/firestore';
import {Timestamp} from '@firebase/firestore-types';
import {ChatActivity, getUserId, LoadedChat, LoadedDirectChat} from './app/Firebase/chat-context';

export type UserObjectReference = {
  id: string;
  displayName: string;
  description?: string;
  photoUrl: string | null | undefined;
};

export type ChatMessageRecord = {
  text: string;
  createdAt: Timestamp;
  author: UserObjectReference;
};

export type UserMessagingRecord = {
  ownerId: string;
  info: UserObjectReference;
  lastChatId?: string;
  lastReadMessages: {[chatId: string]: number};
  lastPrivateUnreadMessages?: {[chatId: string]: number};
  bannedUsers?: NestedMap<UserObjectReference>;
};

export type PrivateMessageRecord = {
  text: string;
  createdAt: Timestamp;
  author: UserObjectReference;
  sentTo: string;
  participants: [string, string];
};

export type NestedMap<T> = {[id: string]: T};

export type UserMap = NestedMap<UserObjectReference & {stayUntil: Timestamp | null}>;

export type ChatRecord = {
  token: string;
  name: string;
  description: string;
  owner: UserObjectReference;
  users: UserMap;
  bannedUsers?: NestedMap<UserObjectReference>;
  moderators: NestedMap<UserObjectReference>;
  createdAt: Timestamp;
  photoUrl: string | null;
  lastMessageIndex: number;
  activities?: NestedMap<ChatActivity>;
};

export type PreprintedQrCodeRecord = {
  token: string;
  createdAt: Timestamp;
  text: string;
};

export function mapToArray<T>(map: NestedMap<T> | null | undefined) {
  if (!map) return [];
  const r = [];
  for (const usersKey in map) {
    if (map.hasOwnProperty(usersKey)) {
      r.push({id: usersKey, ...map[usersKey]});
    }
  }
  return r;
}

export function makeNestedMap<T>(idSelector: (i: T) => string, ...items: T[]) {
  const addUsers: NestedMap<T> = {};
  for (const item of items) {
    addUsers[idSelector(item)] = item;
  }
  return addUsers;
}

export function makeInsertValue<T>(id: string, item: T): any {
  const addUsers: NestedMap<T> = {};
  addUsers[id] = item;
  return addUsers;
}

export function makeUserMap(user: UserObjectReference, stayUntil: Timestamp | null) {
  const addUsers: UserMap = {};
  addUsers[user.id] = {...user, stayUntil};
  return addUsers;
}

export function makeUserReference(user: User): UserObjectReference {
  return {
    displayName: user.displayName ?? 'unknown',
    id: user.uid,
    photoUrl: user.photoURL,
  };
}

export function getUnreadPrivateMessagesCount(userInfo: UserMessagingRecord, chat: LoadedDirectChat) {
  return (userInfo.lastPrivateUnreadMessages && userInfo.lastPrivateUnreadMessages[getUserId(chat.otherUser)]) ?? 0;
}

export function getUnreadMessagesCount(userInfo: UserMessagingRecord, chat: LoadedChat) {
  const lastReadIndex = userInfo.lastReadMessages[chat.documentId];
  return lastReadIndex === undefined ? chat.lastMessageIndex ?? 0 : chat.lastMessageIndex - lastReadIndex;
}

export function formatUnreadMessages(count: number, maximum: number) {
  if (count <= maximum) return count;
  return `${maximum}+`;
}
