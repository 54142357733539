import {Timestamp} from '@firebase/firestore-types';
import firebase from 'firebase';
import {createContext, useContext, useState} from 'react';
import {NestedMap, UserObjectReference} from '../../model';
import {makeSubscribable, Subscribable} from '../Utils/subscriptions';
import {useEffectDebug} from '../Utils/useEffectDebug';

export type LoadedChatMessage = {
  documentId: string;
  chatId: string;
  text: string;
  author: UserObjectReference;
  createdAt: Timestamp;
  status: 'sending' | 'sent' | 'error';
  chat: LoadedChat;
};

export type LoadingChat = {
  documentId: string;
  isLoading: true;
};

export function isLoadingChat(x: LoadingChat | LoadedChat): x is LoadingChat {
  return (x as any).isLoading === true;
}

export type ChatActivity = {
  name: string;
  url: string;
  description: string | undefined;
  photoUrl: string | null;
  categories: string[] | undefined;
};

export type LoadedChatActivity = ChatActivity & {id: string};

export type LoadedChat = {
  token: string;
  createdAt?: Timestamp;
  documentId: string;
  name: string;
  description: string;
  owner: UserObjectReference;
  participants: UserObjectReference[];
  moderators: UserObjectReference[];
  orderedMessages: LoadedChatMessage[];
  photoUrl: string | undefined | null;
  bannedUsers: UserObjectReference[];
  isDirect: false;
  lastMessageIndex: number;
  activities: LoadedChatActivity[];
};

export type LoadedDirectChatMessage = {
  documentId: string;
  text: string;
  createdAt: Timestamp;
  sentToId: string;
  sentFromId: string;
  status: 'sending' | 'sent' | 'error';
  chat: LoadedDirectChat;
};

export type LoadingUserObjectReference = {
  isLoading: true;
  userId: string;
};

export type UnknownUserObjectReference = {
  isUnknown: true;
  userId: string;
};

// export type LoadedChatUser = UserObjectReference | LoadingUserObjectReference | UnknownUserObjectReference;

// export type UserObjectReferenceWithBanned = UserObjectReference & {
//   bannedUsers: NestedMap<UserObjectReference> | undefined;
// };

export type ChatUserRef<TExtra = void> =
  | (UserObjectReference & TExtra)
  | LoadingUserObjectReference
  | UnknownUserObjectReference;

// export type LoadedDirectChatUser = Exclude<LoadedChatUser, UserObjectReference> | UserObjectReferenceWithBanned;

export type LoadedDirectChat = {
  otherUser: ChatUserRef<{
    bannedUsers: NestedMap<UserObjectReference> | undefined;
  }>;
  orderedMessages: LoadedDirectChatMessage[];
  isDirect: true;
};

export function isDirectChat(c: LoadedChat | LoadedDirectChat): c is LoadedDirectChat {
  return c.isDirect;
}

export function getUserId<T>(userRef: ChatUserRef<T>) {
  if (!isLoadingUserObjectReference(userRef) && !isUnknownUser(userRef)) {
    return userRef.id;
  }
  return userRef.userId;
}

// export function getUserId2(userRef: UserObjectReference | LoadingUserObjectReference) {
//   if (!isLoadingUserObjectReference(userRef)) {
//     return userRef.id;
//   }
//   return userRef.userId;
// }

export function getDisplayName<T>(userRef: ChatUserRef<T>) {
  if (isLoadingUserObjectReference(userRef)) {
    return 'Loading...';
  }

  if (isUnknownUser(userRef)) {
    return 'Deleted user';
  }

  return userRef.displayName;
}

export function isLoadingUserObjectReference<T>(x: ChatUserRef<T>): x is LoadingUserObjectReference {
  return (x as any).isLoading === true && typeof (x as any).userId === 'string';
}

export function isUnknownUser<T>(x: ChatUserRef<T>): x is UnknownUserObjectReference {
  return (x as any).isUnknown;
}

export function isLoadedUser<T>(x: ChatUserRef<T>): x is UserObjectReference & T {
  return !isLoadingUserObjectReference(x) && !isUnknownUser(x);
}

export function lastTime(chat: LoadedChat) {
  if (chat.orderedMessages.length > 0) {
    return chat.orderedMessages[chat.orderedMessages.length - 1].createdAt;
  }

  return chat.createdAt ?? firebase.firestore.Timestamp.now();
}

export type ChatContextType = {
  loadedChats: boolean;
  orderedChats: LoadedChat[];
  orderedDirectChats: LoadedDirectChat[];
};

const chatContextRef: {current: ChatContextType} = {
  current: {
    loadedChats: false,
    orderedChats: [],
    orderedDirectChats: [],
  },
};

const chatContextChanged = makeSubscribable<ChatContextType>();

export const ChatContext = createContext<{
  chatContext: {current: ChatContextType};
  currentChanged: Subscribable<ChatContextType>;
  setChatContext: (c: ChatContextType) => void;
}>({
  chatContext: chatContextRef,
  currentChanged: chatContextChanged.subscribable,
  setChatContext: (c) => {
    chatContextRef.current = c;
    chatContextChanged.fire(c);
  },
});

export function useChatContext() {
  const {chatContext, setChatContext, currentChanged} = useContext(ChatContext);
  const [contextValue, setContextValue] = useState<ChatContextType>(chatContext.current);
  useEffectDebug(() => {
    const s = currentChanged((c) => {
      setContextValue(c);
    });
    return () => s.unsubscribe();
  }, [currentChanged]);

  return {chatContext: contextValue, chatContextRef: chatContext, setChatContext};
}
