import React, {createContext, ReactNode, useContext} from 'react';
import {User} from '@firebase/auth-types';
import firebase from '@firebase/app';
import {UserMessagingRecord} from '../../model';
import {useFirebaseAuth} from './firebase';
import {StyledFirebaseAuth} from 'react-firebaseui';
import {Spinner} from '../Utils/SmallSpinner';

const anonymousUiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    'anonymous',
    firebase.auth!.EmailAuthProvider.PROVIDER_ID,
    firebase.auth!.GoogleAuthProvider.PROVIDER_ID,
  ],
};

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth!.EmailAuthProvider.PROVIDER_ID, firebase.auth!.GoogleAuthProvider.PROVIDER_ID],
};

export function RequireLoginComponent({children, allowAnonymous}: {children?: ReactNode; allowAnonymous?: boolean}) {
  useUpdateOnFirebaseUserInfoContextChanged();
  const context = useContext(FirebaseUserInfoContext);
  const auth = useFirebaseAuth();

  if (context.loginState.state === 'logged-out') {
    const config = allowAnonymous ? anonymousUiConfig : uiConfig;
    return <StyledFirebaseAuth uiConfig={{...config}} firebaseAuth={auth} />;
  }
  if (context.loginState.state === 'logged-in') return <>{children}</>;
  return <Spinner />;
}

export type LoginStateType =
  | {state: 'loading'}
  | {state: 'logged-out'}
  | {state: 'getting-user-info'; firebaseUser: User}
  | {
      state: 'logged-in';
      loggedInReason: 'manual-login' | 'auto-login';
      firebaseUser: User;
      userInfo: UserMessagingRecord & {documentId: string};
    };

export const FirebaseUserInfoContext = createContext<{
  loginState: LoginStateType;
  setUserInfo: (userInfo: UserMessagingRecord & {documentId: string}) => void;
}>({
  loginState: {state: 'loading'},
  setUserInfo: () => {},
});

export function useUpdateOnFirebaseUserInfoContextChanged() {
  useContext(FirebaseUserInfoContext);
}

export function useFirebaseUserInfoSafe() {
  const context = useContext(FirebaseUserInfoContext);

  const current = context.loginState;
  return {
    firebaseUser: current.state === 'getting-user-info' || current.state === 'logged-in' ? current.firebaseUser : null,
    userInfo: current.state === 'logged-in' ? current.userInfo : null,
    setUserInfo: context.setUserInfo,
  };
}

export function useFirebaseUserInfo() {
  const context = useContext(FirebaseUserInfoContext);
  const current = context.loginState;
  if (current.state !== 'logged-in') throw new Error();

  return {
    firebaseUser: current.firebaseUser,
    userInfo: current.userInfo,
    setUserInfo: context.setUserInfo,
  };
}
