import {useMemo} from 'react';
import {randomToken} from './random-token';

export type LogoColors = {
  color1: string;
  color2: string;
};

const idPrefix = randomToken();
let idCounter = 0;

export function SvgBreakoutLogoNoText({colors}: {colors?: LogoColors}) {
  const id = useMemo(() => idPrefix + idCounter++, []);
  const gradientId = useMemo(() => 'linear-gradient' + id, [id]);

  const color1 = colors?.color1 ?? '#fc385c';
  const color2 = colors?.color2 ?? '#fb6540';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23.834' height='29.71' viewBox='0 0 23.834 29.71'>
      <defs>
        <linearGradient id={gradientId} y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={color1} />
          <stop offset='1' stopColor={color2} />
        </linearGradient>
      </defs>
      <g transform='translate(-178.496 -117.703)'>
        <path
          d='M0,29.71V0A5.754,5.754,0,0,1,4.1,1.693a5.752,5.752,0,0,1,1.694,4.1V9.437a11.052,11.052,0,0,1,6.753-2.281A11.225,11.225,0,0,1,20.6,10.549,11.263,11.263,0,0,1,12.56,29.708a11.7,11.7,0,0,1-3.052-.417,15.578,15.578,0,0,0-4.257-.6,13.559,13.559,0,0,0-5,.933L0,29.708ZM10.479,13.793a3.339,3.339,0,0,0-3.336,3.336v5.9a9.869,9.869,0,0,1,4.237-1.047,6.6,6.6,0,0,1,1.775.23,5.288,5.288,0,0,0,1.438.221A3.334,3.334,0,0,0,17.931,19.1V17.129a3.339,3.339,0,0,0-3.336-3.336Z'
          transform='translate(178.496 117.703)'
          fill={`url(#${gradientId})`}
        />
      </g>
    </svg>
  );
}
