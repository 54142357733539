import React, {ReactNode} from 'react';
import {FlexFiller, FlexRow} from './Utils/LayoutStyles';
import {DrawerComponent} from './Firebase/DrawerComponent';

export function MobileHeaderComponent({title, rightSideContent}: {title: ReactNode; rightSideContent?: ReactNode}) {
  return (
    <>
      <FlexRow
        style={{
          background: '#FC385C',
          height: '80px',
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'stretch',
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', alignSelf: 'stretch', width: '60px'}}>
          <DrawerComponent />
        </div>
        <FlexFiller
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{flex: '0 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {title}
          </div>
        </FlexFiller>
        <div style={{display: 'flex', justifyContent: 'flex-end', height: '80px', width: '60px'}}>
          {rightSideContent}
        </div>
      </FlexRow>
    </>
  );
}
