import {LoadedChat} from './chat-context';
import {useChatRoomsCollection} from './collections';
import {useSnackbar} from '../Utils/useSnackbar';
import {useFirebaseUser} from './firebase';
import {randomToken} from './random-token';
import React from 'react';
import {StyledMenuLinkButton} from './firebase-styles';

export function AdminContent({
  chat,
  showEditChat,
  showBannedUsers,
}: {
  chat: LoadedChat;
  showEditChat: () => void;
  showBannedUsers: () => void;
}) {
  const chatCollection = useChatRoomsCollection();
  const snackbar = useSnackbar();
  const currentUser = useFirebaseUser();
  const isOwner = currentUser.uid === chat.owner.id;

  return (
    <>
      <div style={{fontSize: 'smaller', padding: '10px 10px 0 10px'}}>
        If you notice unwanted participants joining, you can refresh the access link and QR code. Note that the old QR
        code will become unusable and you will need to share the new one to allow new users to join.
      </div>
      <StyledMenuLinkButton
        onClick={async () => {
          await chatCollection.doc(chat.documentId).set({token: randomToken()}, {merge: true});
          snackbar.show('Token refreshed successfully');
        }}
      >
        Refresh access token
      </StyledMenuLinkButton>

      <StyledMenuLinkButton
        onClick={() => {
          showEditChat();
        }}
        data-test='test-chatInfo-btn'
      >
        Edit chat info
      </StyledMenuLinkButton>

      <StyledMenuLinkButton
        onClick={() => {
          showBannedUsers();
        }}
      >
        Manage banned users
      </StyledMenuLinkButton>

      {isOwner && (
        <StyledMenuLinkButton
          onClick={async () => {
            await chatCollection.doc(chat.documentId).delete();
            snackbar.show('Chat deleted');
          }}
          data-test='test-createChat-deleteBtn'
        >
          Delete chat
        </StyledMenuLinkButton>
      )}
    </>
  );
}
