import {LoadedChat} from './chat-context';
import React, {ReactNode, useState} from 'react';
import {useFirebaseUser} from './firebase';
import {Dialog, Popover} from '@blueprintjs/core';
import {FlexColumn, FlexRow} from '../Utils/LayoutStyles';
import {AdminContent} from './AdminContent';
import {NonAdminContent} from './NonAdminContent';
import {StyledMenuButton, StyledPopupMenu} from './firebase-styles';
import {useChatRoomsCollection} from './collections';
import {UserWithUrl} from './ParticipantsComponent';
import {StyledButton} from '../styles';
import firebase from '@firebase/app';
import {useHistory} from 'react-router';
import {MainRoute} from '../Shell/RouteDefinitions';

export function ManageChatComponent({
  chat,
  button,
}: {
  chat: LoadedChat;
  button?: (p: {onClick: () => void}) => ReactNode;
}) {
  const [showMenu, setShowMenu] = useState(false);
  // const [showEditDialog, setShowEditDialog] = useState(false);
  const [showBannedUsersDialog, setShowBannedUsersDialog] = useState(false);
  const history = useHistory();

  const currentUser = useFirebaseUser();
  const isAdmin = chat.moderators.find((x) => x.id === currentUser.uid);

  return (
    <>
      <Popover
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
        position='bottom'
        content={
          <StyledPopupMenu>
            {isAdmin ? (
              <AdminContent
                chat={chat}
                showEditChat={() => {
                  history.push(MainRoute.makePath({destination: {type: 'edit-chat', chatId: chat.documentId}}));
                  setShowMenu(false);
                }}
                showBannedUsers={() => {
                  setShowBannedUsersDialog(true);
                  setShowMenu(false);
                }}
              />
            ) : (
              <NonAdminContent chat={chat} />
            )}
          </StyledPopupMenu>
        }
      >
        {button ? (
          button({onClick: () => setShowMenu(true)})
        ) : (
          <StyledMenuButton
            testName='test-menuChatBtn'
            onClick={() => setShowMenu(true)}
            style={{marginRight: '20px', padding: '0 20px'}}
          />
        )}
      </Popover>
      <Dialog isOpen={showBannedUsersDialog} onClose={() => setShowBannedUsersDialog(false)}>
        <ManageBannedUsersComponentImpl chat={chat} />
      </Dialog>
    </>
  );
}

export function ManageBannedUsersComponentImpl({chat}: {chat: LoadedChat}) {
  const chatCollection = useChatRoomsCollection();
  return (
    <FlexColumn style={{width: '500px'}}>
      {chat.bannedUsers.length === 0 && (
        <div style={{alignSelf: 'center', margin: '30px'}}>No banned user in this chat</div>
      )}
      {chat.bannedUsers.map((p) => (
        <FlexRow key={p.id} style={{margin: '5px'}}>
          <UserWithUrl user={p} />
          <StyledButton
            onClick={async () => {
              const bannedUsersDelete = {} as any;
              bannedUsersDelete[p.id] = firebase.firestore.FieldValue.delete();
              await chatCollection.doc(chat.documentId).set({bannedUsers: bannedUsersDelete}, {merge: true});
            }}
          >
            <div>Unban</div>
          </StyledButton>
        </FlexRow>
      ))}
    </FlexColumn>
  );
}
