import React, {CSSProperties, ReactNode} from 'react';
import {assertNever} from './typescript';

function negate(v: string) {
  if (v.startsWith('-')) return v.substr(1);
  return '-' + v;
}

function getPosition(
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
  offsetHorizontal: string,
  offsetVertical: string
): CSSProperties {
  if (position === 'top-left') {
    return {top: offsetVertical, left: offsetHorizontal};
  }
  if (position === 'top-right') {
    return {top: offsetVertical, right: offsetHorizontal};
  }

  if (position === 'bottom-left') {
    return {bottom: offsetVertical, left: offsetHorizontal};
  }

  if (position === 'bottom-right') {
    return {bottom: offsetVertical, right: offsetHorizontal};
  }

  return assertNever(position);
}

export function Decoration({
  decoration,
  children,
  style,
  decorationStyle,
  offsetHorizontal,
  offsetVertical,
  position,
}: {
  decoration: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
  decorationStyle?: CSSProperties;
  offsetHorizontal?: string;
  offsetVertical?: string;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}) {
  offsetHorizontal = offsetHorizontal ?? '0px';
  offsetVertical = offsetVertical ?? '0px';
  position = position ?? 'top-left';
  if (position === 'top-left') {
    offsetVertical = negate(offsetVertical);
    offsetHorizontal = negate(offsetHorizontal);
  }
  if (position === 'top-right') {
    offsetHorizontal = negate(offsetHorizontal);
  }
  if (position === 'bottom-left') {
    offsetVertical = negate(offsetVertical);
  }

  return (
    <div style={{position: 'relative', zIndex: 0, ...style}}>
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
          ...getPosition(position, offsetHorizontal, offsetVertical),
          ...decorationStyle,
        }}
      >
        {decoration}
      </div>
      {children}
    </div>
  );
}
