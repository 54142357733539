import React, {HTMLAttributes, ReactNode} from 'react';
import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../../Utils/LayoutStyles';
import {PrimaryRed, StyledMainButton} from '../../Firebase/firebase-styles';

import image_1_question from './1_question.svg';
import image_2_scan_qr from './2_scan_qr.svg';
import image_3_texting from './3_texting.svg';
import image_4_balloons from './4_balloons.svg';
import image_5_pool_party from './5_pool_party.svg';
import image_6_1 from './6_1_icon_secure.svg';
import image_6_2 from './6_2_icon_network.svg';
import image_6_3 from './6_3_icon_text.svg';
import {useHistory} from 'react-router';
import {MainRoute, VenueInfoRoute} from '../../Shell/RouteDefinitions';
import {StyledCircleClip2} from '../../Utils/CircledText';
import {BranchSvg} from '../../Firebase/help/Leaf1Svg';
import {Decoration} from '../../Utils/decoration';
import {CenterMaxWidth2} from '../center-max-width2';
import {WithStretchedBackground} from '../with-stretched-background';
import {BreakingFlexRow} from '../breaking-flex-row';
import {TimelineRow} from '../timeline-row';
import {WaveMiddle} from '../wave-middle';
import {WhyBreakout} from '../why-breakout';
import {AboutUs} from '../about-us';
import {WaveBottom} from '../wave-bottom';
import {TopRow} from '../top-row';
import {StyledRouterLink} from '../../Utils/NavigationLinks';

export function HelpVisitor() {
  return (
    <FlexColumn style={{alignSelf: 'stretch'}}>
      <WithStretchedBackground background={<WaveTop />} style={{paddingBottom: '160px'}}>
        <CenterMaxWidth2>
          <TitleRow />
        </CenterMaxWidth2>
      </WithStretchedBackground>
      <CenterMaxWidth2>
        <HowItWorksSection />
      </CenterMaxWidth2>
      <FlexColumn style={{background: 'white', alignSelf: 'stretch'}}>
        <WithStretchedBackground background={<WaveMiddle />} style={{paddingBottom: '160px'}}>
          <CenterMaxWidth2>
            <OwnerLinkRow />
          </CenterMaxWidth2>
        </WithStretchedBackground>
        <CenterMaxWidth2>
          <WhatIsBreakout />
        </CenterMaxWidth2>
      </FlexColumn>
      <FlexColumn>
        <CenterMaxWidth2>
          <WhyBreakout />
        </CenterMaxWidth2>
        <CenterMaxWidth2 style={{background: 'white'}}>
          <AboutUs />
        </CenterMaxWidth2>
        <WaveBottom background='white' />
      </FlexColumn>
    </FlexColumn>
  );
}

function TitleRow() {
  const history = useHistory();
  return (
    <>
      <TopRow />
      <BreakingFlexRow style={{marginTop: '141px'}}>
        <FlexColumn style={{flex: '2 2 auto', marginRight: '30px'}}>
          <div style={{fontSize: '80px', fontWeight: 400, fontFamily: 'Russo One', lineHeight: '1em'}}>
            What Can We Do <span style={{color: PrimaryRed}}>Tonight</span>?
          </div>
          <div style={{fontSize: '18px', marginTop: '40px'}}>
            Connect with like-minded people and access local entertainment options.
          </div>
          <div style={{fontSize: '18px', marginTop: '20px'}}>
            Breakout is the first location-based social platform that takes you back to the real world.
          </div>
          <StyledMainButton
            style={{marginTop: '40px', alignSelf: 'center', padding: '20px 40px'}}
            onClick={() => {
              history.push(MainRoute.makePath({destination: {type: 'default-location'}}));
            }}
          >
            Create a chatroom
          </StyledMainButton>
        </FlexColumn>
        <FlexColumn style={{flex: '1 1 auto', minWidth: '400px'}}>
          <img src={image_1_question} alt='' />
        </FlexColumn>
      </BreakingFlexRow>
    </>
  );
}

function HowItWorksSection() {
  return (
    <>
      <div style={{fontSize: '80px', alignSelf: 'center', fontWeight: 400, fontFamily: 'Russo One', marginTop: '20px'}}>
        How Breakout Works 
      </div>
      <TimelineRow
        hideStart={true}
        step='1'
        text={"Scan the Venue's Breakout QR Code"}
        image={<img src={image_2_scan_qr} alt='' />}
      />
      <TimelineRow
        step='2'
        text={
          <Decoration decoration={<BranchSvg />} offsetVertical='170px' offsetHorizontal='50px'>
            Complete Your Profile
          </Decoration>
        }
        image={<img src={image_3_texting} alt='' />}
      />
      <TimelineRow step='3' text={'Look for Events and Friends to Go With'} image={<img src={image_4_balloons} alt='' />} />
      <TimelineRow
        step='=)'
        text={
          <div>
            Enjoy Your Evening!
            <br /> Choose <span style={{color: PrimaryRed}}>Breakout!</span>
          </div>
        }
        image={<img src={image_5_pool_party} alt='' />}
        hideEnd={true}
      />
    </>
  );
}

function OwnerLinkRow() {
  return (
    <>
      <FlexCenterRow
        style={{
          fontSize: '80px',
          fontWeight: 400,
          fontFamily: 'Russo One',
          lineHeight: '1em',
          color: 'white',
          justifyContent: 'center',
          marginTop: '80px',
          textAlign: 'center',
        }}
      >
        Do You Own a <br />
        Venue?
      </FlexCenterRow>
      <FlexCenterRow
        style={{
          fontSize: '35px',
          lineHeight: '1em',
          color: 'white',
          justifyContent: 'center',
          marginTop: '80px',
        }}
      >
        Breakout Helps You Create Magical Experiences for Your Guests.
      </FlexCenterRow>
      <StyledRouterLink
        style={{
          alignSelf: 'center',
          marginTop: '56px',
          width: '200px',
          height: '50px',
          borderRadius: '5px',
          background: '#454D5A',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        to={VenueInfoRoute.makePath()}
      >
        Find out how
      </StyledRouterLink>
    </>
  );
}

function WhatIsSection({children}: {children: ReactNode}) {
  return <div style={{padding: '30px 0', borderBottom: '1px solid #f0f0f0'}}>{children}</div>;
}

function BreakoutFeature({image, text, isVertical}: {text: string; image: string; isVertical: boolean}) {
  return (
    <FlexColumn style={{width: '260px', marginTop: isVertical ? '60px' : 0}}>
      <StyledCircleClip2
        size='260px'
        background={'linear-gradient(90deg, rgba(251,101,64,1) 0%, rgba(252,56,92,1) 100%)'}
      >
        <img src={image} alt='' />
      </StyledCircleClip2>
      <FlexRow style={{marginTop: '25px', alignSelf: 'stretch'}}>
        <FlexFiller style={{textAlign: 'center'}}>{text}</FlexFiller>
      </FlexRow>
    </FlexColumn>
  );
}

// const MobileBreakWidth = '960px';

// function Wrap<TParams>(getParams: () => TParams, getStyle: (params: TParams) => CSSProperties) {
//   return function BreakingFlexRow({
//                                     children,
//                                     style,
//                                     ...props
//                                   }: {children: (p: TParams) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
//     const params = getParams(); //useMediaQuery({maxWidth: '960px'});
//     return (
//       <div
//         style={{
//           ...getStyle(params),
//           ...style,
//         }}
//         {...props}
//       >
//         {children(params)}
//       </div>
//     );
//   };
// }
//
// const BreakingFlexRow = Wrap<{isBroken: boolean}>(
//   () => ({isBroken: useMediaQuery({maxWidth: '960px'})}),
//   (params) => ({
//     display: 'flex',
//     alignItems: 'center',
//     flexDirection: params.isBroken ? 'column' : 'row',
//   })
// );
//
// function FeaturesRow({
//                        children,
//                      }: {children: ({isBroken}: {isBroken: boolean}) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
//   return <BreakingFlexRow style={{justifyContent: 'space-around', marginTop: '150px'}}>{children}</BreakingFlexRow>;
// }

function FeaturesRow({children}: {children: (isBroken: boolean) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
  return (
    <BreakingFlexRow
      style={(isBroken) => ({
        justifyContent: 'space-around',
        marginTop: isBroken ? 0 : '150px',
        marginBottom: isBroken ? '60px' : '200px',
      })}
    >
      {children}
    </BreakingFlexRow>
  );
}

function WhatIsBreakout() {
  return (
    <>
      <div style={{fontSize: '50px', fontFamily: 'Russo One', alignSelf: 'center'}}>What Is Breakout</div>
      <WhatIsSection><div style={{fontSize: '18px'}}>Breakout is a location-based social platform.</div></WhatIsSection>
      <WhatIsSection>
      <div style={{fontSize: '18px'}}>It connects guests in the same venue and proposes local activities.</div>
      </WhatIsSection>
      <WhatIsSection><div style={{fontSize: '18px'}}>Breakout is safe, convenient and free.</div></WhatIsSection>
      <WhatIsSection><div style={{fontSize: '18px'}}>No registration is needed.</div></WhatIsSection>
      <FeaturesRow>
        {(isBroken) => (
          <>
            <BreakoutFeature
              text='The QR code is the only door to the chatroom.'
              image={image_6_1}
              isVertical={isBroken}
            />
            <BreakoutFeature
              text='Find people in your venue to hang out with.'
              image={image_6_2}
              isVertical={isBroken}
            />
            <BreakoutFeature
              text='Browse a curated list of activities and venues.'
              image={image_6_3}
              isVertical={isBroken}
            />
          </>
        )}
      </FeaturesRow>
    </>
  );
}

function WaveTop() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1920 975'
      style={{filter: 'drop-shadow(0px 15px 10px #454D5A40)'}}
      height='100%'
      width='100%'
      preserveAspectRatio='none'
    >
      <defs>
        <linearGradient id='a' x1='1' y1='0.5' x2='0' y2='0.5' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fb6540' />
          <stop offset='1' stopColor='#fc385c' />
        </linearGradient>
      </defs>
      <g>
        <path fill='#f2f2f2' d='M0,0H1920V770S1438.871,971.3,958.871,971.3,0,770,0,770Z' />
      </g>
    </svg>
  );
}
