import React from 'react';

import './index.scss';

import {AppComponent} from './app/Firebase/AppComponent';
import {versions} from './app/versions';
import ReactDOM from 'react-dom';

fetch(`${process.env.PUBLIC_URL}/environment.json`)
  .then((r) => r.json())
  .then((r) => {
    (window as any).environment = r;
    console.log(`starting app v.${versions.version}-${versions.revision}-${versions.branch}...`);
    ReactDOM.render(<AppComponent />, document.getElementById('root'));
  });

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .catch(function (err) {
      console.log('Service Worker registration failed: ', err);
    });
}

// serviceWorkerRegistration.register({
//   onUpdate: () => {
//     console.log('service worker updated');
//   },
//   onSuccess: () => {
//     console.log('service worker success');
//   },
// });
