import {FlexCenterColumn, FlexCenterRow, FlexColumn, FlexFiller} from '../Utils/LayoutStyles';
import {format} from 'date-fns';
import {StyledCircleClip} from '../Utils/CircledText';
import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {PrimaryRed} from './firebase-styles';

export function RoomEntryLayoutComponent({
  isSelected,
  unreadCount,
  onClick,
  lastSenderName,
  lastSentTime,
  lastText,
  name,
  icon,
}: {
  name: string;
  isSelected: boolean;
  unreadCount: number;
  onClick: () => void;
  lastSenderName: string;
  lastText: string;
  lastSentTime: Date;
  icon: ReactNode;
}) {
  return (
    <StyledRoom hasUnread={unreadCount !== 0} isSelected={isSelected} onClick={onClick}>
      <div style={{margin: '0 10px'}}>{icon}</div>
      <FlexColumn style={{flex: '1 1 0'}}>
        <StyledRoomTitle isSelected={isSelected}>{name}</StyledRoomTitle>
        <StyledLastSenderName isSelected={isSelected}>{lastSenderName}</StyledLastSenderName>
        <StyledLastMessageText isSelected={isSelected}>{lastText}</StyledLastMessageText>
      </FlexColumn>
      <FlexCenterColumn style={{width: '50px', alignSelf: 'stretch'}}>
        {lastSentTime && <StyledSentTime style={{}}>{format(lastSentTime, 'HH:mm')}</StyledSentTime>}
        <FlexFiller />
        {unreadCount !== 0 && (
          <StyledCircleClip style={{alignSelf: 'flex-end'}} size={21} background={PrimaryRed} color='white'>
            {unreadCount}
          </StyledCircleClip>
        )}
      </FlexCenterColumn>
    </StyledRoom>
  );
}

const StyledRoom = styled(FlexCenterRow)<{isSelected: boolean; hasUnread: boolean}>`
  background: ${(props) => (props.isSelected ? '#FC385C' : props.hasUnread ? '#F8D4D4' : '#F2F2F7')};
  margin: 1px 0;
  cursor: pointer;
  padding: 10px;
  height: 75px;

  :hover {
    background: ${(props) => (props.isSelected ? '#FC385C' : props.hasUnread ? '#fdc6c6' : '#d2d2db')};
  }
`;

const StyledRoomTitle = styled.div<{isSelected: boolean}>`
  color: ${(props) => (props.isSelected ? 'white' : '#FC385C')};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLastSenderName = styled.div<{isSelected: boolean}>`
  color: ${(props) => (props.isSelected ? 'white' : '#444D59')};

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :after {
    content: '\\200b';
  }
`;

const StyledLastMessageText = styled.div<{isSelected: boolean}>`
  color: ${(props) => (props.isSelected ? 'white' : '#444D59')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;

  :after {
    content: '\\200b';
  }
`;

const StyledSentTime = styled.div`
  color: #fd9aac;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.05em;
  align-self: flex-end;
`;
