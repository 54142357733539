import React, {CSSProperties, ReactNode} from 'react';
import {FlexRow} from '../Utils/LayoutStyles';

export function WithStretchedBackground({
  background,
  children,
  style,
}: {
  background: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <FlexRow style={{position: 'relative', zIndex: 0, ...style}}>
      <div style={{position: 'absolute', zIndex: -1, top: 0, left: 0, bottom: 0, right: 0}}>{background}</div>
      {children}
    </FlexRow>
  );
}
