import {getUserId, isLoadedUser, LoadedDirectChat, useChatContext} from './chat-context';
import {useDirectMessagesCollection, useUsersMessagingCollection} from './collections';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import firebase from '@firebase/app';
import React, {useMemo, useRef} from 'react';
import {FirebaseChatBodyComponentImpl, mergeDirectChats, replaceElement} from './ChatComponent';
import {DocumentReference, Timestamp} from '@firebase/firestore-types';
import {PrivateMessageRecord} from '../../model';
import {useAsyncEffect} from '../Utils/useAsyncEffect';
import {useEffectDebug} from '../Utils/useEffectDebug';

export function DirectChatBodyComponent({chat}: {chat: LoadedDirectChat}) {
  const {setChatContext, chatContextRef} = useChatContext();
  const messagesCollection = useDirectMessagesCollection();
  const {userInfo} = useFirebaseUserInfo();
  const userInfoId = useRef<string | undefined>();
  const usersMessagingCollection = useUsersMessagingCollection();

  const otherUserId = getUserId(chat.otherUser);

  useEffectDebug(() => {
    const newUnreadMessages = {} as any;
    newUnreadMessages[otherUserId] = 0;
    usersMessagingCollection
      .doc(userInfo.documentId)
      .set({lastPrivateUnreadMessages: newUnreadMessages}, {merge: true});
  }, [chat]);

  useAsyncEffect(
    async ({wrap}) => {
      const query = usersMessagingCollection.where('ownerId', '==', otherUserId);
      const otherUserInfos = await wrap(query.get());
      const otherUserInfo = otherUserInfos.docs[0];
      if (!otherUserInfo) return;
      userInfoId.current = otherUserInfo.id;
    },
    [otherUserId]
  );

  function createSendingMessage(text: string) {
    const doc = messagesCollection.doc();
    const createdAt = firebase.firestore.Timestamp.now();
    setChatContext(
      mergeDirectChats(chatContextRef.current, otherUserId, (x) => {
        return {
          ...x,
          orderedMessages: [
            ...x.orderedMessages,
            {
              documentId: doc.id,
              author: userInfo.info,
              status: 'sending',
              text,
              sentToId: otherUserId,
              sentFromId: userInfo.ownerId,
              createdAt,
              chat,
            },
          ],
        };
      })
    );
    return {doc, createdAt};
  }

  async function sendMessage(
    text: string,
    doc: DocumentReference<PrivateMessageRecord>,
    createdAt: Timestamp,
    otherUserId: string
  ) {
    if (!userInfoId.current) return;
    const messagePromise = doc.set({
      text,
      createdAt,
      author: userInfo.info,
      sentTo: otherUserId,
      participants: [userInfo.ownerId, otherUserId],
    });
    const newUnreadMessages = {} as any;
    newUnreadMessages[userInfo.ownerId] = firebase.firestore.FieldValue.increment(1);
    const unreadPromise = usersMessagingCollection
      .doc(userInfoId.current)
      .set({lastPrivateUnreadMessages: newUnreadMessages}, {merge: true});
    await Promise.all([messagePromise, unreadPromise]);
    setChatContext(
      mergeDirectChats(chatContextRef.current, otherUserId, (x) => {
        return {
          ...x,
          orderedMessages: replaceElement(
            x.orderedMessages,
            (item) => item.documentId === doc.id,
            (item) => ({
              ...item,
              status: 'sent',
            })
          ),
        };
      })
    );
  }

  function setMessageError(doc: DocumentReference<PrivateMessageRecord>) {
    setChatContext(
      mergeDirectChats(chatContextRef.current, otherUserId, (x) => {
        return {
          ...x,
          orderedMessages: replaceElement(
            x.orderedMessages,
            (item) => item.documentId === doc.id,
            (item) => ({
              ...item,
              status: 'error',
            })
          ),
        };
      })
    );
  }

  const onSend = async (text: string) => {
    if (!isLoadedUser(chat.otherUser)) {
      return;
    }
    if (chat.otherUser.bannedUsers && chat.otherUser.bannedUsers[userInfo.ownerId]) {
      return;
    }

    const {doc, createdAt} = createSendingMessage(text);

    try {
      await sendMessage(text, doc, createdAt, otherUserId);
    } catch (e: any) {
      console.log(e);
      setMessageError(doc);
    }
  };

  const messages = useMemo(
    () =>
      chat.orderedMessages.map((x) => ({
        ...x,
        author: x.sentFromId === userInfo.ownerId ? userInfo.info : chat.otherUser,
      })),
    [chat.orderedMessages, chat.otherUser, userInfo]
  );

  return <FirebaseChatBodyComponentImpl messages={messages} onSend={onSend} chat={chat} />;
}
