import React, {ReactNode} from 'react';
import {FlexCenterColumn, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {StyledCircleClip2} from '../Utils/CircledText';
import {PrimaryRed} from '../Firebase/firebase-styles';
import {BreakingFlexRow} from './breaking-flex-row';

export function TimelineRow({
  step,
  text,
  image,
  hideEnd,
  hideStart,
}: {
  step: string;
  text: ReactNode;
  image: ReactNode;
  hideStart?: boolean;
  hideEnd?: boolean;
}) {
  return (
    <FlexRow style={{alignSelf: 'stretch', overflow: 'hidden'}}>
      <FlexCenterColumn>
        <FlexFiller style={{borderLeft: hideStart ? undefined : '3px dashed red', minHeight: '50px', width: '0'}} />
        <StyledCircleClip2
          size='90px'
          background={PrimaryRed}
          style={{fontSize: '50px', fontFamily: 'Russo One', color: 'white'}}
        >
          <>{step}</>
        </StyledCircleClip2>
        <FlexFiller style={{borderLeft: hideEnd ? undefined : '3px dashed red', minHeight: '50px', width: '0'}} />
      </FlexCenterColumn>
      <BreakingFlexRow style={({isBroken}) => ({flex: '1 1 0', padding: isBroken ? '40px 0' : '100px 0'})}>
        <FlexColumn
          style={{fontSize: '50px', flex: '2 2 auto', minWidth: '200px', justifyContent: 'center', margin: '30px'}}
        >
          {text}
        </FlexColumn>
        <FlexColumn style={{fontSize: '50px', flex: '1 1 auto', minWidth: '400px'}}>{image}</FlexColumn>
      </BreakingFlexRow>
    </FlexRow>
  );
}
