import React, {createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState} from 'react';
import {FlexCenterColumn, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {AdaptiveContext, AdaptiveContextType} from '../Shell/adaptive';
import {makeSubscribable} from '../Utils/subscriptions';
import {Slider} from '../Utils/Slider';
import {DraggableThumb} from '../Utils/DraggableThumb';

type Size = {width: number; height: number};

type TestContextType = Size & {requestSize: (s: Size) => void};
const TestContext = createContext<TestContextType>({width: 300, height: 600, requestSize: () => {}});

export function TestStage({children}: {children: ReactNode}) {
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(600);
  const ctx = useMemo<TestContextType>(
    () => ({
      width,
      height,
      requestSize: (s) => {
        setWidth(s.width);
        setHeight(s.height);
      },
    }),
    [width, height]
  );
  return (
    <TestContext.Provider value={ctx}>
      <FlexColumn style={{alignSelf: 'stretch'}}>
        <FlexRow>
          <div>width: {width} px</div>
          <Slider value={width} onChanged={(e) => setWidth(e)} min={100} max={2000} />
          <div>height: {height} px</div>
          <Slider value={height} onChanged={(e) => setHeight(e)} min={100} max={2000} />
        </FlexRow>
        <FlexFiller>{children}</FlexFiller>
      </FlexColumn>
    </TestContext.Provider>
  );
}

export function TestBed({children}: {children: ReactNode}) {
  const ctx = useMemo<AdaptiveContextType>(
    () => ({
      width: 1000000,
      height: 1000000,
      onChanged: makeSubscribable(false),
      onResetScroll: makeSubscribable(false),
    }),
    []
  );

  const {width, height, requestSize} = useContext(TestContext);

  useLayoutEffect(() => {
    ctx.width = width;
    ctx.height = height;
    ctx.onChanged.fire();
  }, [width, height, ctx]);

  return (
    <div
      style={{
        border: '1px solid #eee',
        margin: '20px',
        position: 'relative',
        width: `${width + 30}px`,
        height: `${height + 30}px`,
      }}
    >
      <div
        style={{
          position: 'relative',
          border: '1px solid black',
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <AdaptiveContext.Provider value={ctx}>
          <FlexCenterColumn style={{position: 'relative', width: '100%', height: '100%', overflow: 'auto'}}>
            {children}
          </FlexCenterColumn>
        </AdaptiveContext.Provider>
      </div>
      <DraggableThumb
        onDrag={(dx, dy) => {
          requestSize({width: width + dx, height: height + dy});
        }}
        render={({onMouseDown}) => (
          <div
            onMouseDown={onMouseDown}
            style={{
              background: 'blue',
              cursor: 'nwse-resize',
              width: '30px',
              height: '30px',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          />
        )}
      />
    </div>
  );
}
