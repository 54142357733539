export function Spinner({size}: {size?: number}) {
  size = size ?? 64;
  return (
    <div className='lds-ring' style={{width: `${size}px`, height: `${size}px`}}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export function SmallSpinner() {
  return <Spinner size={24} />;
}
