import React, {ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import firebase from '@firebase/app';
import {FirebaseContext, useFirebaseAuth} from './firebase';
import {FlexCenterColumn} from '../Utils/LayoutStyles';
import {Environment, environmentContext, useEnvironmentLoader} from '../Shell/Environment';
import {AdaptiveContext} from '../Shell/adaptive';
import {FirebaseUserInfoContext, LoginStateType} from './RequireLoginComponent';
import {makeUserReference, UserMessagingRecord} from '../../model';
import {useUsersMessagingCollection} from './collections';
import {useIsMounted} from '../Utils/useIsMounted';
import {useForceUpdate} from '../Utils/useForceUpdate';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {AppRouting} from './AppRouting';
import {useMountTimer} from '../Utils/useMountTimer';
import styled from 'styled-components';
import {cssTransition, ToastContainer} from 'react-toastify';
import {Spinner} from '../Utils/SmallSpinner';
import {useResizeDetector} from 'react-resize-detector';

export function AppComponent() {
  return (
    <UiHelpersWrapper>
      <AppEnvironmentLoaderWrapper>
        <AppRouting />
      </AppEnvironmentLoaderWrapper>
    </UiHelpersWrapper>
  );
}

function AppEnvironmentLoaderWrapper({children}: {children: ReactNode}) {
  const env = useEnvironmentLoader();

  if (env === null) {
    return <Spinner />;
  }

  return <AppFirebaseWrapper env={env}>{children}</AppFirebaseWrapper>;
}

const Zoom = cssTransition({
  enter: 'zoom-enter',
  exit: 'zoom-exit',
});

function UiHelpersWrapper({children}: {children: ReactNode}) {
  return (
    <>
      {children}
      <ToastContainer autoClose={3000} transition={Zoom} />
    </>
  );
}

export function AppFirebaseWrapper({env, children}: {env: Environment; children: ReactNode}) {
  const adaptiveContext = useContext(AdaptiveContext);
  const firebaseApp = useMemo(() => {
    let app;

    if (!firebase.apps.length) {
      app = firebase.initializeApp({
        apiKey: 'AIzaSyBMFYtV7OTpsiE-mQTmRendPPc5jtB2d5c',
        authDomain: 'breakout-313618.firebaseapp.com',
        projectId: 'breakout-313618',
        storageBucket: 'breakout-313618.appspot.com',
        messagingSenderId: '974466564367',
        appId: '1:974466564367:web:e745487bb1adf77c457e7a',
        measurementId: 'G-VSTF24F060',
      });
      if (env.useEmulator) {
        app.firestore!().useEmulator('localhost', 8080);
        app.auth!().useEmulator('http://localhost:9099', {disableWarnings: true});
        app.storage!().useEmulator('localhost', 9199);
        app.analytics().setAnalyticsCollectionEnabled(false);
      }
    } else {
      app = firebase.app();
    }

    const firestore = app.firestore!();
    const auth = app.auth!();
    const storage = app.storage!();
    const analytics = app.analytics();

    return {firestore, auth, storage, analytics};
  }, [env]);

  const rootDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const s = adaptiveContext.onResetScroll.subscribable(() => {
      if (rootDivRef.current) {
        rootDivRef.current.scrollTo(0, 0);
      }
    });
    return () => s.unsubscribe();
  }, [adaptiveContext.onResetScroll]);

  const {width, height, ref} = useResizeDetector();

  useLayoutEffect(() => {
    if (width !== undefined && height !== undefined) {
      adaptiveContext.width = width;
      adaptiveContext.height = height;
      adaptiveContext.onChanged.fire();
    }
  }, [width, height, adaptiveContext]);

  if (firebaseApp === null) {
    return <Spinner />;
  }

  return (
    <environmentContext.Provider value={env}>
      <FirebaseContext.Provider value={firebaseApp}>
        <UserInfoContext>
          <StyledRootColumn ref={ref} style={{width: '100%', height: '100%', overflow: 'auto'}}>
            {children}
          </StyledRootColumn>
        </UserInfoContext>
      </FirebaseContext.Provider>
    </environmentContext.Provider>
  );
}

const StyledRootColumn = styled(FlexCenterColumn)`
  @media print {
    overflow: visible !important;
  }
`;

function UserInfoContext({children}: {children: ReactNode}) {
  const userInfoCollection = useUsersMessagingCollection();
  const timerMs = useMountTimer();

  const stateRef = useRef<LoginStateType>({state: 'loading'});

  const isMounted = useIsMounted();

  const auth = useFirebaseAuth();
  const currentUser = auth.currentUser;

  const userQuery = useMemo(
    () => (currentUser ? userInfoCollection.where('ownerId', '==', currentUser.uid) : null),
    [currentUser, userInfoCollection]
  );

  const forceUpdate = useForceUpdate();

  const setState = useCallback(
    (newState: LoginStateType) => {
      stateRef.current = newState;
      forceUpdate();
    },
    [forceUpdate]
  );

  const setUserInfo = useCallback(
    (newInfo: UserMessagingRecord & {documentId: string}) => {
      if (stateRef.current.state !== 'logged-in') return;
      setState({
        state: 'logged-in',
        loggedInReason: stateRef.current.loggedInReason,
        firebaseUser: stateRef.current.firebaseUser,
        userInfo: newInfo,
      });
    },
    [setState]
  );

  const contextValue = useMemo(
    () => ({
      loginState: stateRef.current,
      setUserInfo,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUserInfo, stateRef.current]
  );

  useEffectDebug(() => {
    return userQuery?.onSnapshot((s) => {
      if (stateRef.current.state !== 'logged-in') return;
      const doc = s.docs[0];
      if (!doc) {
        setState({state: 'logged-out'});
        return;
      }
      const newUserInfo = doc.data();
      setState({...stateRef.current, userInfo: {...newUserInfo, documentId: doc.id}});
    });
  }, [setState, userQuery]);

  useEffectDebug(() => {
    const s = auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          setState({state: 'getting-user-info', firebaseUser: user});
          try {
            const query = userInfoCollection.where('ownerId', '==', user.uid);
            const userInfoList = await query.get();
            if (!isMounted.current) return;
            const userInfo = userInfoList.docs[0];
            if (userInfo) {
              const data = userInfo.data();
              setState({
                state: 'logged-in',
                loggedInReason: timerMs > 2000 ? 'manual-login' : 'auto-login',
                firebaseUser: user,
                userInfo: {...data, documentId: userInfo.id},
              });
            } else {
              const newInfo: UserMessagingRecord = {
                ownerId: user.uid,
                lastReadMessages: {},
                lastPrivateUnreadMessages: {},
                info: makeUserReference(user),
              };
              const a = await userInfoCollection.add(newInfo);
              if (!isMounted.current) return;
              setState({
                state: 'logged-in',
                loggedInReason: 'manual-login',
                firebaseUser: user,
                userInfo: {...newInfo, documentId: a.id},
              });
            }
          } catch (e: any) {
            console.log('could not get user info');
            console.log(e);
          }
        } else {
          setState({state: 'logged-out'});
        }
      },
      (error) => {
        setState({state: 'logged-out'});
        console.log(error);
      }
    );
    return () => {
      s();
    };
  }, [auth, isMounted, setState, userInfoCollection]);

  return <FirebaseUserInfoContext.Provider value={contextValue}>{children}</FirebaseUserInfoContext.Provider>;
}
