import QRCodeStyling, {Options} from 'qr-code-styling';
import {useEffect, useMemo, useRef} from 'react';

import logo from './help/footer-logo.svg';

export function StyledQrCodeComponent({
  data,
  size,
  background,
  hideLogo,
}: {
  data: string;
  size?: number;
  background?: string;
  hideLogo?: boolean;
}) {
  size = size ?? 300;
  const options = useMemo<Options>(
    () => ({
      data,
      image: hideLogo ? undefined : logo,
      dotsOptions: {type: 'extra-rounded'},
      cornersSquareOptions: {type: 'extra-rounded'},
      cornersDotOptions: {type: 'dot'},
      qrOptions: {errorCorrectionLevel: 'H'},
      backgroundOptions: {color: background ?? 'white'},
      width: size,
      height: size,
    }),
    [data, size, background, hideLogo]
  );
  return <QrCodeComponent options={options} />;
}

export function QrCodeComponent({options}: {options: Options}) {
  const ref = useRef<HTMLDivElement>(null);
  const qrCode = useMemo<QRCodeStyling>(() => new QRCodeStyling(options), [options]);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  return <div ref={ref} />;
}
