import '@firebase/auth';
import {FirebaseAuth, User} from '@firebase/auth-types';
import '@firebase/firestore';
import {CollectionReference, FirebaseFirestore} from '@firebase/firestore-types';
import '@firebase/storage';
import {FirebaseStorage} from '@firebase/storage-types';
import '@firebase/analytics';
import {FirebaseAnalytics} from '@firebase/analytics-types';

// import '@firebase/database';
// import '@firebase/messaging';
// import '@firebase/installation';
// import '@firebase/database';
// import '@firebase/storage';
// import '@firebase/functions';
// import '@firebase/performance';
// import '@firebase/remote-config';
// import '@firebase/app-check';
import {createContext, useContext, useMemo} from 'react';

export const FirebaseContext = createContext<{
  firestore: FirebaseFirestore;
  auth: FirebaseAuth;
  storage: FirebaseStorage;
  analytics: FirebaseAnalytics;
} | null>(null);

export function useFirestore() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.firestore;
}

export function useFirebaseAuth() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.auth;
}

export function useFirestoreCollection<T>(name: string) {
  const firestore = useFirestore();
  return useMemo(() => firestore.collection(name) as CollectionReference<T>, [firestore, name]);
}

export function useFirebaseStorage() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.storage;
}

export function useFirebaseAnalytics() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.analytics;
}

// todo remove
export function useFirebaseUser(): User {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  if (!context.auth.currentUser) throw new Error('Firebase user empty');
  return context.auth.currentUser;
}
