import {ResolvedDestination} from '../Shell/RouteDefinitions';
import {getDisplayName, getUserId, useChatContext} from './chat-context';
import {PrivateChatEntryComponent} from './PrivateChatEntryComponent';
import React from 'react';

export function PrivateChatsListComponent({
  onRoomSelect,
  selectedRoom,
  searchText,
}: {
  selectedRoom: ResolvedDestination | null;
  onRoomSelect: (id: ResolvedDestination) => void;
  searchText: string;
}) {
  const {chatContextRef} = useChatContext();

  return (
    <>
      {chatContextRef.current.orderedDirectChats
        .filter((x) =>
          searchText === ''
            ? true
            : getDisplayName(x.otherUser).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        )
        .map((x) => {
          const otherId = getUserId(x.otherUser);
          return (
            <PrivateChatEntryComponent
              key={otherId}
              chat={x}
              onClick={() => onRoomSelect({type: 'direct', userId: otherId})}
              isSelected={selectedRoom?.type === 'direct' && selectedRoom.userId === otherId}
            />
          );
        })}
    </>
  );
}
