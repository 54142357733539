import {FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {StyledCircleClip2} from '../Utils/CircledText';
import React from 'react';

export function BreakoutFeature({
  image,
  text,
  isVertical,
  background,
}: {
  text: string;
  image: string;
  isVertical: boolean;
  background?: string;
}) {
  return (
    <FlexColumn style={{width: '260px', marginTop: isVertical ? '60px' : 0}}>
      <StyledCircleClip2
        style={{boxShadow: '0px 15px 25px #454D5A40'}}
        size='260px'
        background={background || 'linear-gradient(90deg, rgba(251,101,64,1) 0%, rgba(252,56,92,1) 100%)'}
      >
        <img src={image} alt='' />
      </StyledCircleClip2>
      <FlexRow style={{marginTop: '25px', alignSelf: 'stretch'}}>
        <FlexFiller style={{textAlign: 'center'}}>{text}</FlexFiller>
      </FlexRow>
    </FlexColumn>
  );
}
