import {
  ChatContextType,
  ChatUserRef,
  getDisplayName,
  getUserId,
  isDirectChat,
  isLoadingUserObjectReference,
  LoadedChat,
  LoadedDirectChat,
} from './chat-context';
import React, {ReactNode, useRef, useState} from 'react';
import {FlexCenterColumn, FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {
  BackgroundGray,
  ChatMaxWidth,
  PrimaryRed,
  StyledMenuButton,
  StyledMenuLinkButton,
  StyledPopupMenu,
} from './firebase-styles';
import {Button, Dialog, Popover} from '@blueprintjs/core';
import {SmallSpinner} from '../Utils/SmallSpinner';
import {format, isSameDay} from 'date-fns';
import {Timestamp} from '@firebase/firestore-types';
import {useIsSmall} from '../Shell/adaptive';
import {UserImage} from './ParticipantsComponent';
import styled from 'styled-components';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {useDirectMessagesCollection, useMessagesCollectionNull} from './collections';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {StyledCircleClip} from '../Utils/CircledText';
import {Icon} from '@iconify/react';
import {UserObjectReference} from '../../model';
import {MainRoute} from '../Shell/RouteDefinitions';
import {AsyncButtonBase} from '../Utils/AsyncButton';
import {useHistory} from 'react-router';

import trashIcon from '@iconify-icons/feather/trash-2';
import sendIcon from '@iconify-icons/ion/send';

export function replaceElement<T>(items: T[], selector: (item: T) => boolean, t: (item: T) => T) {
  return items.map((x) => {
    if (selector(x)) {
      return t(x);
    }
    return x;
  });
}

export function mergeChatContext(
  chatContext: ChatContextType,
  chatId: string,
  t: (messages: LoadedChat) => LoadedChat
) {
  return {
    ...chatContext,
    orderedChats: replaceElement(chatContext.orderedChats, (x) => x.documentId === chatId, t),
  };
}

export function mergeDirectChats(
  chatContext: ChatContextType,
  userId: string,
  t: (messages: LoadedDirectChat) => LoadedDirectChat
) {
  return {
    ...chatContext,
    orderedDirectChats: replaceElement(chatContext.orderedDirectChats, (x) => getUserId(x.otherUser) === userId, t),
  };
}

export function FirebaseChatBodyComponentImpl({
  messages,
  onSend,
  chat,
}: {
  messages: MessageData[];
  onSend: (text: string) => void;
  chat: LoadedChat | LoadedDirectChat;
}) {
  const [text, setText] = useState('');
  const isSmall = useIsSmall();
  const {userInfo} = useFirebaseUserInfo();

  const isAdmin = !!(!isDirectChat(chat) && chat.moderators.find((x) => x.id === userInfo.ownerId));

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffectDebug(() => {
    // otherwise does not scroll all the way down on load (on FF)
    const t = setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
    }, 100);
    return () => {
      clearTimeout(t);
    };
  }, [messages]);

  const menuRenderer = useMenuRenderer(chat);

  return (
    <>
      <FlexRow
        style={{
          flex: '1 1 0',
          alignSelf: 'stretch',
          background: BackgroundGray,
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <FlexColumn style={{flex: '1 1 0', maxWidth: ChatMaxWidth}}>
          <FlexColumn style={{flex: '1 1 0', position: 'relative'}}>
            {messages.map((x) => {
              return (
                <MessageComponent key={x.documentId} message={x} menuRenderer={() => menuRenderer.render(x, isAdmin)} />
              );
            })}
            <div ref={messagesEndRef} style={{height: '10px'}} />
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
      <FlexRow
        style={{
          alignSelf: 'stretch',
          background: BackgroundGray,
          justifyContent: 'center',
          height: isSmall ? '60px' : '110px',
          borderTop: '1px solid #b6bbc4',
          borderColor: '#d8d8db',
        }}
      >
        <FlexColumn style={{flex: '1 1 0', maxWidth: ChatMaxWidth, justifyContent: 'center', padding: '0 10px'}}>
          <FlexRow style={{border: '1px solid #ccc', borderRadius: '5px', alignItems: 'stretch', height: '60px'}}>
            <input
              placeholder='Write a message'
              style={{border: 0, background: 'transparent', flex: '1 1 0', padding: '10px'}}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && text !== '') {
                  onSend(text);
                  setText('');
                }
              }}
              data-test='test-enter-messageInput'
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <Button
              style={{
                boxShadow: 'initial',
                alignSelf: 'stretch',
                background: 'transparent',
                color: PrimaryRed,
                borderLeft: '1px solid #ccc',
              }}
              data-test='test-send-messageBtn'
              onClick={() => {
                if (text !== '') {
                  onSend(text);
                  setText('');
                }
              }}
            >
              <StyledCircleClip size={40} background={PrimaryRed} color='white'>
                <Icon icon={sendIcon} width={20} height={20} />
              </StyledCircleClip>
            </Button>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </>
  );
}

const StyledMessageMenuItem = styled(StyledMenuLinkButton)<{isSmall: boolean}>`
  align-self: center;
  padding: ${(props) => (props.isSmall ? '10px 20px' : undefined)};
`;

function useMenuRenderer(chat: LoadedChat | LoadedDirectChat): {
  render: (message: MessageData, isAdmin: boolean) => React.ReactNode[];
} {
  const directMessagesCollection = useDirectMessagesCollection();
  const messagesCollection = useMessagesCollectionNull(isDirectChat(chat) ? undefined : chat.documentId);
  const {userInfo} = useFirebaseUserInfo();
  const history = useHistory();
  const isSmall = useIsSmall();

  return {
    render: (message, isAdmin) => {
      const result = [];
      const senderId = getUserId(message.author);
      if (senderId === userInfo.ownerId || isAdmin) {
        result.push(
          <AsyncButtonBase
            key='delete'
            render={({onClick, isLoading}) => (
              <StyledMessageMenuItem onClick={onClick} isSmall={isSmall}>
                <Icon icon={trashIcon} />
                <div>Delete</div>
                {isLoading && <SmallSpinner />}
              </StyledMessageMenuItem>
            )}
            onClickAsync={async () => {
              if (message.chat.isDirect) {
                await directMessagesCollection.doc(message.documentId).delete();
              } else {
                await messagesCollection?.doc(message.documentId).delete();
              }
            }}
          />
        );
      }
      if (senderId !== userInfo.ownerId) {
        result.push(
          <StyledMessageMenuItem
            key='dm'
            isSmall={isSmall}
            onClick={() => history.push(MainRoute.makePath({destination: {type: 'direct', userId: senderId}}))}
          >
            <Icon icon={sendIcon} />
            <div>Send direct message</div>
          </StyledMessageMenuItem>
        );
      }
      return result;
    },
  };
}

type MessageData = {
  documentId: string;
  createdAt: Timestamp;
  status: 'sending' | 'sent' | 'error';
  text: string;
  author: ChatUserRef<unknown>;
  chat: LoadedChat | LoadedDirectChat;
};

function MessageComponent({message, menuRenderer}: {message: MessageData; menuRenderer: () => ReactNode[]}) {
  const isSmall = useIsSmall();
  const {userInfo} = useFirebaseUserInfo();
  const [showMenu, setShowMenu] = useState(false);

  const currentUserRef: UserObjectReference = userInfo.info;
  const isOwn = message.status === 'sending' || getUserId(message.author) === userInfo.ownerId;
  const author = message.status === 'sending' ? currentUserRef : message.author;

  const cornerStyle = isOwn
    ? {
        borderBottomRightRadius: 0,
      }
    : {
        borderBottomLeftRadius: 0,
      };

  const menu = menuRenderer();

  return (
    <StyledShowOnHoverParent
      style={{
        margin: '10px',
        alignSelf: isOwn ? 'flex-end' : 'flex-start',
        marginLeft: isOwn ? '60px' : '10px',
        marginRight: !isOwn ? '60px' : '10px',
        position: 'relative',
        maxWidth: '90%',
      }}
      onClick={() => setShowMenu(true)}
    >
      <div style={{width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {!isOwn && <UserImage user={message.author} imageStyle='black' />}
      </div>
      <FlexColumn
        style={{
          background: isOwn ? '#444D59' : '#E6E6E6',
          padding: '10px 15px',
          flex: '1 1 0',
          borderRadius: '13px',
          ...cornerStyle,
        }}
      >
        <FlexCenterRow style={{marginBottom: '5px', flexDirection: isSmall ? 'column' : 'row'}}>
          {!isOwn && (
            <div
              style={{
                flex: '100 100 auto',
                color: '#FC385C',
                alignSelf: 'stretch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isLoadingUserObjectReference(author) ? 'loading' : getDisplayName(author) ?? 'unknown'}
            </div>
          )}
          <FlexFiller />
          {message.status === 'sending' ? <SmallSpinner /> : message.status === 'error' && <Icon icon='error' />}
          <FlexColumn
            style={{
              color: isOwn ? '#FFF' : '#FC385C',
              fontSize: '10px',
              marginLeft: '20px',
              fontWeight: 500,
              opacity: 0.6,
              flex: '1 1 auto',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {isSameDay(new Date(), message.createdAt.toDate())
                ? format(message.createdAt.toDate(), 'hh:mm')
                : format(message.createdAt.toDate(), 'EEEE LLL d, hh:mm')}
            </div>
          </FlexColumn>
        </FlexCenterRow>
        <div
          style={{
            color: isOwn ? 'white' : undefined,
            fontSize: '12px',
            fontWeight: isOwn ? 500 : undefined,
            wordBreak: 'break-word',
          }}
        >
          {message.text}
        </div>
        {menu.length > 0 &&
          (isSmall ? (
            <>
              <Dialog
                isOpen={showMenu}
                onClose={() => setShowMenu(false)}
                backdropProps={{
                  onTouchStart: () => {
                    setShowMenu(false);
                  },
                }}
              >
                <FlexCenterColumn>{menu}</FlexCenterColumn>
              </Dialog>
            </>
          ) : (
            <StyledShowOnHover style={{position: 'absolute', top: '4px', bottom: 0, right: 0}}>
              <Popover
                placement='bottom'
                isOpen={showMenu}
                onClose={() => setShowMenu(false)}
                content={<StyledPopupMenu>{menu}</StyledPopupMenu>}
              >
                <StyledMenuButton
                  whiteColor={isOwn}
                  onClick={() => {
                    setShowMenu(true);
                  }}
                />
              </Popover>
            </StyledShowOnHover>
          ))}
      </FlexColumn>
    </StyledShowOnHoverParent>
  );
}

const StyledShowOnHoverParent = styled(FlexCenterRow)``;

const StyledShowOnHover = styled.div`
  display: none;

  ${StyledShowOnHoverParent}:hover & {
    display: block;
  }
`;
