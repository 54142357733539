import {createContext, useContext, useState} from 'react';
import {useEffectDebug} from '../Utils/useEffectDebug';

export type Environment = {
  readonly useEmulator: boolean;
};

export const environmentContext = createContext<Environment | null>(null);

export function useEnvironment(): Environment {
  const environment = useContext(environmentContext);
  if (environment === null) {
    throw new Error('environment is not loaded.');
  }
  return environment;
}

export function useEnvironmentLoader(): Environment | null {
  const [env, setEnv] = useState<Environment | null>(null);
  useEffectDebug(() => {
    setEnv((window as any).environment);
  }, []);
  return env;
}
