import styled, {css} from 'styled-components';
import React, {CSSProperties, ReactNode} from 'react';

export const contentMaxWidth = '1200px';

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const FlexFiller = styled.div`
  flex: 1 1 0;
`;

export const FlexFillerAuto = styled.div`
  flex: 1 1 auto;
`;

export const FlexFillerScaled = styled.div<{multiplier: number}>`
  flex: ${(props) => props.multiplier} ${(props) => props.multiplier} 0;
`;

export const FlexCenterer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function CenterMaxWidth({
  children,
  maxWidth,
  style,
  leftRightPadding,
}: {
  children: ReactNode;
  maxWidth: string;
  leftRightPadding?: string;
  style?: CSSProperties;
}) {
  return (
    <FlexRow style={{flex: '1 1 auto', ...style}}>
      <FlexFiller />
      <FlexColumn
        style={{
          flex: `0 1 ${maxWidth}`,
          width: maxWidth,
          maxWidth,
          padding: leftRightPadding && `0 ${leftRightPadding}`,
        }}
      >
        {children}
      </FlexColumn>
      <FlexFiller />
    </FlexRow>
  );
}
