import React, {HTMLAttributes, ReactNode} from 'react';
import {FlexCenterRow, FlexColumn} from '../../Utils/LayoutStyles';
import {PrimaryRed, StyledMainButton} from '../../Firebase/firebase-styles';

import image_1_visitor from './1_visitor.svg';
import image_2_register from './2_register.svg';
import image_3_create_chatroom from './3_create_chatroom.svg';
import image_4_display_qr from './4_display_qr.svg';
import image_5_1_scan_qr from './5_1_scan_qr_icon.svg';
import image_5_2_text_icon from './5_2_text_icon.svg';
import image_5_3_balloon_party from './5_3_balloon_party.svg';
import {useHistory} from 'react-router';
import {MainRoute, UserInfoRoute} from '../../Shell/RouteDefinitions';
import {BranchSvg} from '../../Firebase/help/Leaf1Svg';
import {Decoration} from '../../Utils/decoration';
import {CenterMaxWidth2} from '../center-max-width2';
import {WithStretchedBackground} from '../with-stretched-background';
import {BreakingFlexRow} from '../breaking-flex-row';
import {TimelineRow} from '../timeline-row';
import {WaveMiddle} from '../wave-middle';
import {WhyBreakout} from '../why-breakout';
import {AboutUs} from '../about-us';
import {BreakoutFeature} from '../breakout-feature';
import {WaveBottom} from '../wave-bottom';
import {TopRow} from '../top-row';
import {StyledRouterLink} from '../../Utils/NavigationLinks';

export function HelpVenue() {
  return (
    <FlexColumn style={{alignSelf: 'stretch'}}>
      <WithStretchedBackground background={<WaveTop />} style={{paddingBottom: '160px'}}>
        <CenterMaxWidth2>
          <TitleRow />
        </CenterMaxWidth2>
      </WithStretchedBackground>
      <CenterMaxWidth2>
        <HowItWorksSection />
      </CenterMaxWidth2>
      <FlexColumn style={{background: 'white', alignSelf: 'stretch'}}>
        <WithStretchedBackground background={<WaveMiddle />} style={{paddingBottom: '160px'}}>
          <CenterMaxWidth2>
            <OwnerLinkRow />
          </CenterMaxWidth2>
        </WithStretchedBackground>
        <CenterMaxWidth2>
          <WhatIsBreakout />
        </CenterMaxWidth2>
      </FlexColumn>
      <FlexColumn>
        <CenterMaxWidth2>
          <WhyBreakout />
        </CenterMaxWidth2>
        <CenterMaxWidth2 style={{background: 'white'}}>
          <AboutUs />
        </CenterMaxWidth2>
        <WaveBottom background='white' />
      </FlexColumn>
    </FlexColumn>
  );
}

function TitleRow() {
  const history = useHistory();
  return (
    <>
      <TopRow />
      <BreakingFlexRow style={{marginTop: '141px'}}>
        <FlexColumn style={{flex: '2 2 auto', marginRight: '30px'}}>
          <div style={{fontSize: '80px', fontWeight: 400, fontFamily: 'Russo One', lineHeight: '1em'}}>
            "I <span style={{color: PrimaryRed}}>Love</span> Being <br />
            Bored by Myself"
          </div>
          <div style={{fontSize: '24px', marginTop: '40px', textAlign: 'right'}}>- No Traveller Ever</div>
          <div style={{fontSize: '18px', marginTop: '40px'}}>
            Breakout helps your guests have a fantastic experience
          </div>
          <StyledMainButton
            style={{marginTop: '40px', alignSelf: 'center', padding: '20px 40px'}}
            onClick={() => {
              history.push(MainRoute.makePath({destination: {type: 'default-location'}}));
            }}
          >
            Create a chatroom
          </StyledMainButton>
        </FlexColumn>
        <FlexColumn style={{flex: '1 1 auto', minWidth: '400px'}}>
          <img src={image_1_visitor} alt='' />
        </FlexColumn>
      </BreakingFlexRow>
    </>
  );
}

function HowItWorksSection() {
  return (
    <>
      <div style={{fontSize: '80px', alignSelf: 'center', fontWeight: 400, fontFamily: 'Russo One', marginTop: '20px'}}>
        How Breakout Works
      </div>
      <TimelineRow
        hideStart={true}
        step='1'
        text={"Register Your Account"}
        image={<img src={image_2_register} alt='' />}
      />
      <TimelineRow
        step='2'
        text={
          <Decoration decoration={<BranchSvg />} offsetVertical='170px' offsetHorizontal='50px'>
            Create a Chatroom for Your Guests
          </Decoration>
        }
        image={<img src={image_3_create_chatroom} alt='' />}
      />
      <TimelineRow
        hideEnd={true}
        step='3'
        text={"Display Your Chatroom's QR Code"}
        image={<img src={image_4_display_qr} alt='' />}
      />
    </>
  );
}

function OwnerLinkRow() {
  return (
    <>
      <FlexCenterRow
        style={{
          fontSize: '80px',
          fontWeight: 400,
          fontFamily: 'Russo One',
          lineHeight: '1em',
          color: 'white',
          justifyContent: 'center',
          marginTop: '80px',
        }}
      >
        Breakout
      </FlexCenterRow>
      <FlexCenterRow
        style={{
          fontSize: '35px',
          lineHeight: '1em',
          color: 'white',
          justifyContent: 'center',
          marginTop: '80px',
        }}
      >
        Your social traveling companion!
      </FlexCenterRow>
      <StyledRouterLink
        style={{
          alignSelf: 'center',
          marginTop: '56px',
          width: '200px',
          height: '50px',
          borderRadius: '5px',
          background: '#454D5A',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        to={UserInfoRoute.makePath()}
      >
        How customers see it
      </StyledRouterLink>
    </>
  );
}

function WhatIsSection({children}: {children: ReactNode}) {
  return <div style={{padding: '30px 0', borderBottom: '1px solid #f0f0f0'}}>{children}</div>;
}

// const MobileBreakWidth = '960px';

// function Wrap<TParams>(getParams: () => TParams, getStyle: (params: TParams) => CSSProperties) {
//   return function BreakingFlexRow({
//                                     children,
//                                     style,
//                                     ...props
//                                   }: {children: (p: TParams) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
//     const params = getParams(); //useMediaQuery({maxWidth: '960px'});
//     return (
//       <div
//         style={{
//           ...getStyle(params),
//           ...style,
//         }}
//         {...props}
//       >
//         {children(params)}
//       </div>
//     );
//   };
// }
//
// const BreakingFlexRow = Wrap<{isBroken: boolean}>(
//   () => ({isBroken: useMediaQuery({maxWidth: '960px'})}),
//   (params) => ({
//     display: 'flex',
//     alignItems: 'center',
//     flexDirection: params.isBroken ? 'column' : 'row',
//   })
// );
//
// function FeaturesRow({
//                        children,
//                      }: {children: ({isBroken}: {isBroken: boolean}) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
//   return <BreakingFlexRow style={{justifyContent: 'space-around', marginTop: '150px'}}>{children}</BreakingFlexRow>;
// }

function FeaturesRow({children}: {children: (isBroken: boolean) => ReactNode} & HTMLAttributes<HTMLDivElement>) {
  return (
    <BreakingFlexRow
      style={(isBroken) => ({
        justifyContent: 'space-around',
        marginTop: isBroken ? 0 : '150px',
        marginBottom: isBroken ? '60px' : '200px',
      })}
    >
      {children}
    </BreakingFlexRow>
  );
}

function WhatIsBreakout() {
  return (
    <>
      <div style={{fontSize: '50px', fontFamily: 'Russo One', alignSelf: 'center'}}>What Is Breakout</div>
      <WhatIsSection><div style={{fontSize: '18px'}}>Breakout is a location-based social platform.</div></WhatIsSection>
      <WhatIsSection><div style={{fontSize: '18px'}}>It connects guests from the same venue or group and offers them activities to do together.</div>
      </WhatIsSection>
      <WhatIsSection>
      <div style={{fontSize: '18px'}}>Promote your own events, control the customer journey, and help them
        have a great experience!</div>
      </WhatIsSection>
      <FeaturesRow>
        {(isBroken) => (
          <>
            <BreakoutFeature
              text='The QR code is the only door to the chatroom.'
              image={image_5_1_scan_qr}
              isVertical={isBroken}
              background={'white'}
            />
            <BreakoutFeature
              text='Talk to your guests directly.'
              image={image_5_2_text_icon}
              isVertical={isBroken}
              background={'white'}
            />
            <BreakoutFeature
              text='Organize events in your venue.'
              image={image_5_3_balloon_party}
              isVertical={isBroken}
              background={'white'}
            />
          </>
        )}
      </FeaturesRow>
    </>
  );
}

function WaveTop() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1920 975'
      style={{filter: 'drop-shadow(0px 15px 10px #454D5A40)'}}
      height='100%'
      width='100%'
      preserveAspectRatio='none'
    >
      <defs>
        <linearGradient id='a' x1='1' y1='0.5' x2='0' y2='0.5' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fb6540' />
          <stop offset='1' stopColor='#fc385c' />
        </linearGradient>
      </defs>
      <g>
        <path fill='#f2f2f2' d='M0,0H1920V770S1438.871,971.3,958.871,971.3,0,770,0,770Z' />
      </g>
    </svg>
  );
}
