import {ChatActivity, LoadedChat, LoadedChatActivity, useChatContext} from './chat-context';
import {ImageUploadLayout, NameDescriptionSaveLayout} from './ParticipantsComponent';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {
  BackgroundGray,
  InputHeight,
  LightGray,
  PrimaryBlack,
  PrimaryRed,
  StyledBlackButton,
  StyledInputGroup,
  StyledMainAsyncButton,
  StyledMainButton,
} from './firebase-styles';
import {CenterMaxWidth, FlexCenterColumn, FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {Dialog} from '@blueprintjs/core';
import {TitledDialogLayout, TwoColumnResponsiveLayout} from './TitledDialogLayout';
import {useAsyncCallback} from '../Utils/useAsyncCallback';
import {useFirebaseStorage} from './firebase';
import {useChatRoomsCollection} from './collections';
import {makeInsertValue} from '../../model';
import {randomToken} from './random-token';
import {mergeChatContext, replaceElement} from './ChatComponent';
import firebase from 'firebase';
import {ActivityImageComponent} from './ActivityImageComponent';
import Creatable from 'react-select/creatable';
import {ChatImageComponent} from './ChatRoomsComponent';
import styled from 'styled-components';

export function EditChatComponent({chat}: {chat: LoadedChat}) {
  return (
    <CenterMaxWidth maxWidth='960px' style={{overflow: 'auto', alignSelf: 'stretch', padding: '20px'}}>
      <FlexColumn style={{alignSelf: 'stretch', flex: '1 1 auto'}}>
        <EditChatBasicInfoComponent chat={chat} />
        <div style={{height: '1px', background: PrimaryBlack, margin: '20px 0', alignSelf: 'stretch'}} />
        <EditChatActivitiesComponent chat={chat} />
      </FlexColumn>
    </CenterMaxWidth>
  );
}

function EditChatBasicInfoComponent({chat}: {chat: LoadedChat}) {
  const [name, setName] = useState(chat.name);
  const [description, setDescription] = useState(chat.description ?? '');

  const chatRoomsCollection = useChatRoomsCollection();
  const storage = useFirebaseStorage();

  useEffectDebug(() => {
    setName(chat.name);
  }, [chat.name]);

  useEffectDebug(() => {
    setDescription(chat.description ?? '');
  }, [chat.description]);

  const onSetFile = useAsyncCallback<{file: File}>(
    async ({wrap, file}) => {
      const r = await storage.ref(`chat-images/${chat.documentId}`).put(file);
      const newUrl = await wrap(r.ref.getDownloadURL());
      await chatRoomsCollection.doc(chat.documentId).set({photoUrl: newUrl}, {merge: true});
    },
    [storage, chatRoomsCollection, chat.documentId]
  );

  const onRemoveFile = useAsyncCallback(async () => {
    await Promise.all([
      storage.ref(`chat-images/${chat.documentId}`).delete(),
      chatRoomsCollection.doc(chat.documentId).set({photoUrl: null}, {merge: true}),
    ]);
  }, [storage, chatRoomsCollection, chat.documentId]);

  return (
    <FlexRow style={{alignSelf: 'stretch'}}>
      <FlexCenterColumn style={{flex: '1 1 auto'}}>
        <div style={{color: PrimaryRed, fontSize: '21px', margin: '10px'}}>Chat image</div>
        <FlexCenterRow style={{flex: '1 1 auto'}}>
          <ImageUploadLayout
            renderImage={() => <ChatImageComponent chat={chat} size={160} />}
            onSetFile={onSetFile}
            showRemove={!!chat.photoUrl}
            onRemove={onRemoveFile}
          />
        </FlexCenterRow>
      </FlexCenterColumn>
      <div style={{width: '1px', alignSelf: 'stretch', background: PrimaryBlack, opacity: '0.1', margin: '0 20px'}} />
      <FlexColumn style={{flex: '1 1 auto'}}>
        <div style={{color: PrimaryRed, fontSize: '21px', margin: '10px'}}>Basic information</div>
        <NameDescriptionSaveLayout
          name={name}
          description={description}
          onNameChanged={setName}
          onDescriptionChanged={setDescription}
          onSaveClick={async () => {
            await chatRoomsCollection.doc(chat.documentId).set({name, description}, {merge: true});
          }}
        />
      </FlexColumn>
    </FlexRow>
  );
}

function EditChatActivitiesComponent({chat}: {chat: LoadedChat}) {
  const [editActivity, setEditActivity] = useState<LoadedChatActivity | 'new-activity' | null>(null);

  const chatRoomsCollection = useChatRoomsCollection();
  const storage = useFirebaseStorage();

  return (
    <>
      <FlexCenterRow>
        <div style={{color: PrimaryRed, fontSize: '21px', margin: '10px 0'}}>Activities</div>
        <FlexFiller />
        <StyledMainButton
          style={{minHeight: '50px', minWidth: '150px', margin: '10px 0'}}
          onClick={() => {
            setEditActivity('new-activity');
          }}
          data-test='test-addActivity-chatBtn'
        >
          Add activity
        </StyledMainButton>
      </FlexCenterRow>
      <FlexColumn>
        {chat.activities.length === 0 && (
          <>
            <div>There are no activities in this chat</div>
          </>
        )}
        {chat.activities.map((a) => (
          <EditableActivityCard
            key={a.id}
            activity={a}
            onEdit={() => setEditActivity(a)}
            onDelete={async () => {
              await Promise.all([
                storage.ref(`images/activities/${a.id}`).delete(),
                chatRoomsCollection.doc(chat.documentId).set(
                  {
                    activities: makeInsertValue(a.id, firebase.firestore.FieldValue.delete()),
                  },
                  {merge: true}
                ),
              ]);
            }}
          />
        ))}
      </FlexColumn>
      <Dialog
        style={{background: BackgroundGray, width: 'unset'}}
        isOpen={editActivity !== null}
        onClose={() => setEditActivity(null)}
      >
        {editActivity === 'new-activity' && (
          <TitledDialogLayout
            onClose={() => setEditActivity(null)}
            title={'Create new activity'}
            content={<NewActivityDialogContent chatId={chat.documentId} onClose={() => setEditActivity(null)} />}
          />
        )}

        {editActivity !== 'new-activity' && editActivity !== null && (
          <TitledDialogLayout
            onClose={() => setEditActivity(null)}
            title={'Edit activity'}
            content={<EditActivityDialogContent activity={editActivity} chatId={chat.documentId} />}
          />
        )}
      </Dialog>
    </>
  );
}

function EditActivityDialogContent({activity, chatId}: {activity: LoadedChatActivity; chatId: string}) {
  const storage = useFirebaseStorage();

  const roomsCollection = useChatRoomsCollection();
  const [name, setName] = useState(activity.name);
  const [url, setUrl] = useState(activity.url);
  const [description, setDescription] = useState(activity.description ?? '');
  const [categories, setCategory] = useState(activity.categories);
  const [photoUrl, setPhotoUrl] = useState<string | null>(activity.photoUrl);

  useEffect(() => {
    setName(activity.name);
  }, [activity.name]);
  useEffect(() => {
    setUrl(activity.url);
  }, [activity.url]);
  useEffect(() => {
    setPhotoUrl(activity.photoUrl);
  }, [activity.photoUrl]);
  useEffect(() => {
    setDescription(activity.description ?? '');
  }, [activity.description]);

  const {chatContext, setChatContext} = useChatContext();

  return (
    <EditActivityContent
      saveText='Save'
      onSaveClick={async () => {
        const newActivity = {name, url, categories, description};
        const insertValue = makeInsertValue<Omit<ChatActivity, 'photoUrl'>>(activity.id, newActivity);
        await roomsCollection.doc(chatId).set({activities: insertValue}, {merge: true});

        setChatContext(
          mergeChatContext(chatContext, chatId, (chat) => {
            const newActivities = replaceElement(
              chat.activities,
              (x) => x.id === activity.id,
              (t) => ({...newActivity, photoUrl, categories, description, id: activity.id})
            );
            return {...chat, activities: newActivities};
          })
        );
      }}
      name={name}
      url={url}
      photoUrl={photoUrl}
      categories={categories ?? []}
      description={description}
      categoriesChanged={(e) => setCategory(e)}
      nameChanged={(e) => setName(e)}
      urlChanged={(e) => setUrl(e)}
      descriptionChanged={(e) => setDescription(e)}
      fileSelected={async (e) => {
        if (e) {
          const photoData = await fetch(e).then((r) => r.blob());
          const r = await storage.ref(`images/activities/${activity.id}`).put(photoData);
          const newUrl = await r.ref.getDownloadURL();
          await roomsCollection.doc(chatId).set(
            {
              activities: makeInsertValue(activity.id, {photoUrl: newUrl}),
            },
            {merge: true}
          );
          setPhotoUrl(newUrl);
          setChatContext(
            mergeChatContext(chatContext, chatId, (chat) => {
              const newActivities = replaceElement(
                chat.activities,
                (x) => x.id === activity.id,
                (t) => ({name, url, categories, description, photoUrl: newUrl, id: activity.id})
              );
              return {...chat, activities: newActivities};
            })
          );
        } else {
          await Promise.all([
            storage.ref(`images/activities/${activity.id}`).delete(),
            roomsCollection.doc(chatId).set(
              {
                activities: makeInsertValue(activity.id, {photoUrl: null}),
              },
              {merge: true}
            ),
          ]);
          setPhotoUrl(null);
          setChatContext(
            mergeChatContext(chatContext, chatId, (chat) => {
              const newActivities = replaceElement(
                chat.activities,
                (x) => x.id === activity.id,
                (t) => ({name, url, categories, description, photoUrl: null, id: activity.id})
              );
              return {...chat, activities: newActivities};
            })
          );
        }
      }}
    />
  );
}

function NewActivityDialogContent({chatId, onClose}: {chatId: string; onClose: () => void}) {
  const storage = useFirebaseStorage();

  const roomsCollection = useChatRoomsCollection();
  const [name, setName] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [dataPhotoUrl, setDataPhotoUrl] = useState<string | null>(null);

  const {chatContext, setChatContext} = useChatContext();

  return (
    <EditActivityContent
      saveText='Create'
      onSaveClick={async () => {
        const activityId = randomToken();
        let storagePhotoUrl = null;
        if (dataPhotoUrl) {
          const photoData = await fetch(dataPhotoUrl).then((r) => r.blob());
          const r = await storage.ref(`images/activities/${activityId}`).put(photoData);
          storagePhotoUrl = await r.ref.getDownloadURL();
        }

        const newActivity = {name, url, categories, photoUrl: storagePhotoUrl, description};
        const insertValue = makeInsertValue<ChatActivity>(activityId, newActivity);
        await roomsCollection.doc(chatId).set({activities: insertValue}, {merge: true});

        setChatContext(
          mergeChatContext(chatContext, chatId, (chat) => {
            const newActivities = [...chat.activities, {...newActivity, id: activityId}];
            return {...chat, activities: newActivities};
          })
        );
        onClose();
      }}
      name={name}
      description={description}
      url={url}
      photoUrl={dataPhotoUrl}
      categories={categories}
      nameChanged={(e) => setName(e)}
      urlChanged={(e) => setUrl(e)}
      fileSelected={(e) => setDataPhotoUrl(e)}
      categoriesChanged={(e) => setCategories(e)}
      descriptionChanged={(e) => setDescription(e)}
    />
  );
}

const allCategories: string[] = [
  'In-house',
  'Restaurant',
  'Bars',
  'Cafes',
  'Nightclubs',
  'Music venues',
  'Cinemas and theaters',
  'Casinos',
  'Swimming pools, thermal baths, saunas and spas',
  'Historical monuments',
  'Museums',
  'Fun',
  'Sport venues',
  'Winter and water sports',
  'Parks and nature',
  'Paid and free tours',
  'Cultural events',
  'LGBTQ',
];

const allCategoryObjects = allCategories.map((x) => ({value: x, label: x}));

function EditActivityContent({
  onSaveClick,
  saveText,

  name,
  photoUrl,
  url,
  categories,
  description,

  nameChanged,
  fileSelected,
  urlChanged,
  categoriesChanged,
  descriptionChanged,
}: {
  onSaveClick: () => Promise<void>;
  saveText: ReactNode;

  name: string;
  photoUrl: string | null;
  url: string;
  categories: string[];
  description: string;

  nameChanged: (s: string) => void;
  fileSelected: (s: string | null) => Promise<void> | void;
  urlChanged: (s: string) => void;
  categoriesChanged: (s: string[]) => void;
  descriptionChanged: (s: string) => void;
}) {
  const isMenuOpen = useRef(false);

  const onSetFile = useAsyncCallback<{file: File}>(async ({wrap, file}) => {
    fileSelected(URL.createObjectURL(file));
  }, []);

  const onRemoveFile = useAsyncCallback(async () => {
    fileSelected(null);
  }, []);

  return (
    <TwoColumnResponsiveLayout
      leftContent={
        <ImageUploadLayout
          renderImage={() => (
            <div style={{maxWidth: '400px', maxHeight: '400px'}}>
              <ActivityImageComponent activity={{name, photoUrl}} />
            </div>
          )}
          onSetFile={onSetFile}
          showRemove={!!photoUrl}
          onRemove={onRemoveFile}
        />
      }
      rightContent={
        <FlexColumn>
          <StyledInputGroup>
            <div>Categories:</div>
            {/* Select does not support strings as values because of a bug */}
            <Creatable
              value={categories.map((x) => allCategoryObjects.find((c) => c.value === x) ?? {option: x, value: x})}
              onChange={(x) => categoriesChanged(x.map((c) => c.value))}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  if (isMenuOpen.current) e.stopPropagation();
                }
              }}
              onMenuOpen={() => (isMenuOpen.current = true)}
              onMenuClose={() => (isMenuOpen.current = false)}
              placeholder='Select one or multiple'
              options={allCategoryObjects}
              isMulti={true}
              closeMenuOnSelect={false}
              styles={{
                container: (provided) => ({
                  ...provided,
                  display: 'flex',
                  maxWidth: '300px',
                }),
                control: (provided) => ({
                  ...provided,
                  flex: '1 1 0',
                  minHeight: InputHeight,
                  border: 0,
                  background: LightGray,
                }),
                multiValue: (provided) => ({
                  ...provided,
                  flex: '0 1 auto',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  flex: '0 1 auto',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  opacity: '30%',
                  marginLeft: '8px',
                }),
              }}
            />
          </StyledInputGroup>
          <StyledInputGroup>
            <div>Name:</div>
            <input value={name} onChange={(e) => nameChanged(e.target.value)} data-test='test-activity-nameInput' />
          </StyledInputGroup>
          <StyledInputGroup>
            <div>Description:</div>
            <textarea value={description} onChange={(e) => descriptionChanged(e.target.value)} />
          </StyledInputGroup>
          <StyledInputGroup>
            <div>Url:</div>
            <input value={url} onChange={(e) => urlChanged(e.target.value)} data-test='test-activity-urlInput' />
          </StyledInputGroup>
          <StyledMainAsyncButton data-test='test-activity-saveBtn' onClickAsync={onSaveClick}>
            {saveText}
          </StyledMainAsyncButton>
        </FlexColumn>
      }
    />
  );
}

const StyledInputGroup2 = styled(StyledInputGroup)`
  margin-top: 15px;
`;

function EditableActivityCard({
  activity,
  onEdit,
  onDelete,
}: {
  activity: ChatActivity;
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <div
      title={activity.name}
      style={{
        margin: '10px 0',
        padding: '15px',
        borderRadius: '10px',
        boxShadow: '0px 3px 6px #00000029',
        background: 'white',
      }}
    >
      <FlexRow>
        <FlexCenterColumn style={{flex: '1 1 0', alignSelf: 'center'}}>
          <ActivityImageComponent activity={activity} />
        </FlexCenterColumn>
        <FlexColumn style={{flex: '1 1 0', marginLeft: '20px'}}>
          <div style={{color: PrimaryRed, fontSize: '21px'}}>{activity.name}</div>
          <StyledInputGroup2>
            <div>Url</div>
            <input disabled={true} value={activity.url} />
          </StyledInputGroup2>
          <StyledInputGroup2>
            <div>Categories</div>
            <input disabled={true} value={(activity.categories ?? []).join(', ')} />
          </StyledInputGroup2>
          <StyledInputGroup2>
            <div>Description</div>
            <textarea disabled={true} value={activity.description} />
          </StyledInputGroup2>
          <FlexFiller />
          <FlexRow style={{marginTop: '20px'}}>
            <StyledBlackButton
              style={{flex: '1 1 0', borderRadius: '5px', marginRight: '20px'}}
              onClick={onDelete}
              data-test='test-deleteActivity-btn'
            >
              Delete
            </StyledBlackButton>
            <StyledMainButton
              style={{flex: '1 1 0', borderRadius: '5px'}}
              onClick={onEdit}
              data-test='test-editActivity-btn'
            >
              Edit
            </StyledMainButton>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </div>
  );
}
