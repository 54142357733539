import {LoadedChat, useChatContext} from './chat-context';
import {useChatRoomsCollection, useMessagesCollection, useUsersMessagingCollection} from './collections';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import firebase from '@firebase/app';
import React from 'react';
import {FirebaseChatBodyComponentImpl, mergeChatContext, replaceElement} from './ChatComponent';
import {DocumentReference, Timestamp} from '@firebase/firestore-types';
import {ChatMessageRecord} from '../../model';
import {useEffectDebug} from '../Utils/useEffectDebug';

export function ChatBodyComponent({chat}: {chat: LoadedChat}) {
  const {setChatContext, chatContextRef} = useChatContext();
  const messagesCollection = useMessagesCollection(chat.documentId);
  const {userInfo} = useFirebaseUserInfo();
  const roomsCollection = useChatRoomsCollection();
  const usersMessagingCollection = useUsersMessagingCollection();

  useEffectDebug(() => {
    const newReadMessages = {} as any;
    const lastMessage = chat.lastMessageIndex;
    if (lastMessage !== undefined) {
      newReadMessages[chat.documentId] = lastMessage;
      usersMessagingCollection.doc(userInfo.documentId).set({lastReadMessages: newReadMessages}, {merge: true});
    }
  }, [chat]);

  function createSendingMessage(text: string) {
    const doc = messagesCollection.doc();
    const createdAt = firebase.firestore.Timestamp.now();
    setChatContext(
      mergeChatContext(chatContextRef.current, chat.documentId, (x) => {
        return {
          ...x,
          orderedMessages: [
            ...x.orderedMessages,
            {
              documentId: doc.id,
              author: userInfo.info,
              status: 'sending',
              text,
              chatId: chat.documentId,
              createdAt,
              chat,
            },
          ],
        };
      })
    );
    return {doc, createdAt};
  }

  async function sendMessage(text: string, doc: DocumentReference<ChatMessageRecord>, createdAt: Timestamp) {
    const messagePromise = doc.set({
      text,
      createdAt,
      author: userInfo.info,
    });

    const chatDocument = roomsCollection.doc(chat.documentId);
    const chatPromise = chatDocument.set(
      {lastMessageIndex: firebase.firestore.FieldValue.increment(1) as any},
      {merge: true}
    );

    await Promise.all([messagePromise, chatPromise]);

    setChatContext(
      mergeChatContext(chatContextRef.current, chat.documentId, (x) => {
        return {
          ...x,
          orderedMessages: replaceElement(
            x.orderedMessages,
            (item) => item.documentId === doc.id,
            (item) => ({
              ...item,
              status: 'sent',
            })
          ),
        };
      })
    );
  }

  function setMessageError(doc: DocumentReference<ChatMessageRecord>) {
    setChatContext(
      mergeChatContext(chatContextRef.current, chat.documentId, (x) => {
        return {
          ...x,
          orderedMessages: replaceElement(
            x.orderedMessages,
            (item) => item.documentId === doc.id,
            (item) => ({
              ...item,
              status: 'error',
            })
          ),
        };
      })
    );
  }

  const onSend = async (text: string) => {
    const {doc, createdAt} = createSendingMessage(text);

    try {
      await sendMessage(text, doc, createdAt);
    } catch (e: any) {
      console.log(e);
      setMessageError(doc);
    }
  };

  return <FirebaseChatBodyComponentImpl messages={chat.orderedMessages} onSend={onSend} chat={chat} />;
}
