import {FlexFiller} from '../Utils/LayoutStyles';
import {ContactRoute, MainRoute} from '../Shell/RouteDefinitions';
import {SvgBreakoutLogoWithText} from '../Firebase/SvgBreakoutLogoWithText';
import {PrimaryBlack, PrimaryRed} from '../Firebase/firebase-styles';
import React from 'react';
import {StyledRouterLink} from '../Utils/NavigationLinks';
import {BreakingFlexRow} from './breaking-flex-row';

export function TopRow() {
  return (
    <BreakingFlexRow
      style={{marginTop: '35px'}}
      children={({isBroken}) => {
        return (
          <>
            <StyledRouterLink style={{height: '52px'}} to={MainRoute.makePath('home')}>
              <SvgBreakoutLogoWithText
                logoColor2='#fb6540'
                logoColor1='#fc385c'
                textColor='black'
                textColor2={PrimaryRed}
              />
            </StyledRouterLink>
            <FlexFiller />
            <StyledRouterLink
              to={ContactRoute.makePath()}
              style={{
                color: PrimaryBlack,
                margin: isBroken ? '20px 0' : '0 40px',
              }}
            >
              Contact us
            </StyledRouterLink>
            <StyledRouterLink
              to={MainRoute.makePath('home')}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '220px',
                height: '50px',
                borderRadius: '5px',
                background: '#7CCF9A',
                color: 'white',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              Sign in
            </StyledRouterLink>
          </>
        );
      }}
    />
  );
}
