import {Redirect, Route, Switch, useHistory} from 'react-router';
import {
  BetaRoute,
  ContactRoute,
  DeleteAccountRoute,
  DemoRoute,
  MainRoute,
  PreprintedQrCodeRoute,
  QrCodeRoute,
  QrPrintRoute,
  UserInfoRoute,
  VenueInfoRoute,
} from '../Shell/RouteDefinitions';
import {DashboardComponent} from './DashboardComponent';
import {ContactPage} from './help/Contact';
import {JoinChatComponent} from './JoinChatComponent';
import {HashRouter} from 'react-router-dom';
import React from 'react';
import {DeleteAccountPage} from './DeleteAccountPage';
import {Beta} from './help/Beta';
import {QrPrintComponent} from './QrPrintComponent';
import {JoinChatComponentDemo} from '../demo/JoinChatComponentDemo';
import {QrGenerationComponent} from './QrGenerationComponent';
import {useFirebaseAnalytics} from './firebase';
import {useEffectDebug} from '../Utils/useEffectDebug';
import {HelpVisitor} from '../info/help-visitor/help-visitor';
import {HelpVenue} from '../info/help-venue/help-venue';

export function AppRouting() {
  return (
    <HashRouter>
      <Switch>
        <Routes />
      </Switch>
    </HashRouter>
  );
}

function Routes() {
  const history = useHistory();
  const analytics = useFirebaseAnalytics();

  useEffectDebug(() => {
    analytics.logEvent('screen_view', {
      firebase_screen: history.location.pathname,
      firebase_screen_class: 'AppRouting',
    });
    return history.listen((location, action) => {
      analytics.logEvent('screen_view', {firebase_screen: location.pathname, firebase_screen_class: 'AppRouting'});
    });
  }, [analytics]);

  return (
    <>
      <Route {...DemoRoute.route(() => <JoinChatComponentDemo />)} />
      <Route {...MainRoute.route((p) => <DashboardComponent params={p} />)} />
      <Route {...PreprintedQrCodeRoute.route(() => <QrGenerationComponent />)} />
      <Route {...UserInfoRoute.route(() => <HelpVisitor />)} />
      <Route {...VenueInfoRoute.route(() => <HelpVenue />)} />
      <Route {...BetaRoute.route(() => <Beta />)} />
      <Route {...ContactRoute.route(() => <ContactPage />)} />
      <Route {...DeleteAccountRoute.route(() => <DeleteAccountPage />)} />
      <Route {...QrCodeRoute.route((p) => <JoinChatComponent token={p.locationToken} days={p.days} />)} />
      <Route {...QrPrintRoute.route((p) => <QrPrintComponent roomId={p.roomId} />)} />
      <Route exact={true} path='/'>
        <Redirect to={MainRoute.makePath({destination: {type: 'default-location'}})} />
      </Route>
    </>
  );
}
