import React, {useMemo, useState} from 'react';
import {makeNestedMap, makeUserMap} from '../../model';
import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from '../Utils/LayoutStyles';
import {Dialog} from '@blueprintjs/core';
import {useChatRoomsCollection} from './collections';
import styled from 'styled-components';
import {useChatContext} from './chat-context';
import firebase from '@firebase/app';
import {HeaderHeight, PrimaryBlack, PrimaryRed, StyledBlackButton, StyledMainButton} from './firebase-styles';
import {randomToken} from './random-token';
import {ContactRoute, MainRoute, ResolvedDestination, UserInfoRoute} from '../Shell/RouteDefinitions';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {RoomEntryComponent} from './RoomEntryComponent';
import {PrivateChatsListComponent} from './PrivateChatsListComponent';
import {useHistory} from 'react-router';
import {StyledCircleClip} from '../Utils/CircledText';
import {SvgBreakoutLogoWithText} from './SvgBreakoutLogoWithText';
import {Icon} from '@iconify/react';
import searchIcon from '@iconify-icons/ion/search';
import {TitledDialogLayout} from './TitledDialogLayout';
import {useFirebaseStorage} from './firebase';
import {useAsyncCallback} from '../Utils/useAsyncCallback';
import {ImageUploadLayout, NameDescriptionSaveLayout} from './ParticipantsComponent';
import megaphoneIcon from '@iconify-icons/ion/megaphone-outline';
import warningIcon from '@iconify-icons/ion/warning-outline';
import {Beta} from './help/Beta';

const StyledRoomsList = styled(FlexColumn)`
  background: #444d59;
  border: 1px solid #444d59;
`;

export function ChatRoomsComponent({
  onRoomSelect,
  selectedRoom,
}: {
  selectedRoom: ResolvedDestination | null;
  onRoomSelect: (id: ResolvedDestination) => void;
}) {
  const {chatContextRef} = useChatContext();
  const [searchText, setSearchText] = useState('');
  const [showBeta, setShowBeta] = useState(false);
  const history = useHistory();

  return (
    <StyledRoomsList style={{flex: '1 1 0'}}>
      <a
        style={{
          height: HeaderHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid #5B636E',
          color: 'white',
        }}
        href='/app'
      >
        <div
          style={{
            width: '155px',
          }}
        >
          <SvgBreakoutLogoWithText color='white' />
        </div>
      </a>
      <FlexCenterRow style={{position: 'relative'}}>
        <StyledSearchBox placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Icon icon={searchIcon} style={{position: 'absolute', left: '40px'}} width={22} height={22} color='#C1C1CC' />
      </FlexCenterRow>
      <FlexColumn style={{overflow: 'auto', flex: '1 0 100px'}}>
        {chatContextRef.current.orderedChats
          .filter((x) =>
            searchText === '' ? true : x.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          )
          .map((x) => {
            return (
              <RoomEntryComponent
                key={x.documentId}
                chat={x}
                onClick={() => onRoomSelect({type: 'chat', chatId: x.documentId})}
                isSelected={selectedRoom?.type === 'chat' && selectedRoom.chatId === x.documentId}
              />
            );
          })}
        <PrivateChatsListComponent selectedRoom={selectedRoom} onRoomSelect={onRoomSelect} searchText={searchText} />
        <FlexFiller />
      </FlexColumn>
      <CreateChatButtonComponent />
      <StyledBlackButton
        style={{margin: '0 20px'}}
        onClick={() => {
          history.push(UserInfoRoute.makePath());
        }}
      >
        <InfoIcon />
        <div style={{marginLeft: '10px'}}>Help</div>
      </StyledBlackButton>
      <StyledBlackButton
        style={{margin: '16px 20px'}}
        onClick={() => {
          history.push(ContactRoute.makePath());
        }}
      >
        <Icon icon={megaphoneIcon} width={25} height={25} />
        <div style={{marginLeft: '10px'}}>Contact</div>
      </StyledBlackButton>
      <StyledBlackButton
        style={{margin: '16px 20px'}}
        onClick={() => {
          setShowBeta(true);
        }}
      >
        <Icon icon={warningIcon} width={25} height={25} />
        <div style={{marginLeft: '10px'}}>Beta</div>
      </StyledBlackButton>
      <Dialog isOpen={showBeta} onClose={() => setShowBeta(false)}>
        <Beta />
      </Dialog>
      <div
        style={{
          opacity: 0.5,
          fontSize: '12px',
          letterSpacing: '0.125em',
          margin: '24px 0',
          color: 'white',
          alignSelf: 'center',
        }}
      >
        © Breakout Messaging 2021
      </div>
    </StyledRoomsList>
  );
}

const StyledSearchBox = styled.input`
  border-radius: 10px;
  background: #4d5765;
  margin: 20px;
  border: 0;
  height: 40px;
  flex: 1 1 auto;
  padding-left: 60px;
  color: white;

  ::placeholder {
    color: #c1c1cc;
  }
`;

function CreateChatButtonComponent() {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <FlexRow
        style={{
          alignSelf: 'stretch',
          justifyContent: 'stretch',
          borderTop: '1px solid #515964',
          height: '110px',
        }}
      >
        <StyledMainButton
          style={{
            flex: '1 1 auto',
            margin: '30px 20px',
            letterSpacing: '0.125em',
            fontWeight: 600,
          }}
          data-test='test-create-chatBtn'
          onClick={() => setShowDialog(true)}
        >
          Create Chat
        </StyledMainButton>
      </FlexRow>
      <Dialog isOpen={showDialog} onClose={() => setShowDialog(false)} style={{width: 'unset'}}>
        <TitledDialogLayout
          onClose={() => setShowDialog(false)}
          title={'Create chat'}
          content={<CreateChatDialogContent onClose={() => setShowDialog(false)} />}
        />
      </Dialog>
    </>
  );
}

export function CreateChatDialogContent({onClose}: {onClose: () => void}) {
  const storage = useFirebaseStorage();
  const chatRoomsCollection = useChatRoomsCollection();
  const {userInfo} = useFirebaseUserInfo();
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const imageToken = useMemo(() => randomToken(), []);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);

  const columnsPadding = '30px';

  const onSetFile = useAsyncCallback<{file: File}>(
    async ({wrap, file}) => {
      const r = await storage.ref(`temp-images/${imageToken}`).put(file);
      const newUrl = await wrap(r.ref.getDownloadURL());
      setPhotoUrl(newUrl);
    },
    [storage]
  );

  const onRemoveFile = useAsyncCallback(async () => {
    await storage.ref(`chat-create-images/${imageToken}`).delete();
  }, [storage, imageToken]);

  const onSaveChanges = useAsyncCallback(async () => {
    const r = await chatRoomsCollection.add({
      owner: userInfo.info,
      users: makeUserMap(userInfo.info, null),
      name,
      description,
      createdAt: firebase.firestore.Timestamp.now(),
      token: randomToken(),
      moderators: makeNestedMap((x) => x.id, userInfo.info),
      bannedUsers: {},
      lastMessageIndex: 0,
      photoUrl,
    });

    onClose();
    history.push(MainRoute.makePath({destination: {type: 'chat', chatId: r.id}}));
  }, [chatRoomsCollection, name, description]);

  const body = (
    <>
      <ImageUploadLayout
        renderImage={() => <ChatImageComponent chat={{name, photoUrl}} size={160} />}
        onSetFile={onSetFile}
        showRemove={!!photoUrl}
        onRemove={onRemoveFile}
      />
      <div style={{width: '1px', alignSelf: 'stretch', background: PrimaryBlack, opacity: '0.1'}} />
      <NameDescriptionSaveLayout
        style={{padding: `0 ${columnsPadding}`}}
        name={name}
        description={description}
        onNameChanged={(t) => setName(t)}
        onDescriptionChanged={(t) => setDescription(t)}
        onSaveClick={onSaveChanges}
        saveText='Create chat'
      />
    </>
  );

  return <FlexRow>{body}</FlexRow>;
}

export function ChatImageComponent({
  chat,
  size,
  isSelected,
  unclipped,
}: {
  chat: {name: string; photoUrl: string | undefined | null};
  size?: number;
  isSelected?: boolean;
  unclipped?: boolean;
}) {
  const body = <ChatImageComponent2 chat={chat} color={isSelected ? PrimaryRed : 'white'} />;
  if (unclipped) return body;
  return (
    <StyledCircleClip size={size ?? 57} background={isSelected ? 'white' : PrimaryRed}>
      {body}
    </StyledCircleClip>
  );
}

export function ChatImageComponent2({
  chat,
  color,
}: {
  chat: {name: string; photoUrl: string | undefined | null};
  color?: string;
}) {
  if (!!chat.photoUrl) {
    return (
      <img
        alt={chat.name}
        src={chat.photoUrl}
        referrerPolicy='no-referrer'
        style={{objectFit: 'cover', width: '100%', height: '100%'}}
      />
    );
  }

  return <SvgChatIcon size='100%' color={color ?? 'white'} />;
}

function InfoIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17.969' height='17.969' viewBox='0 0 17.969 17.969'>
      <g transform='translate(-3.375 -3.375)'>
        <path
          fill='white'
          d='M12.359,5.189a7.167,7.167,0,1,1-5.071,2.1,7.14,7.14,0,0,1,5.071-2.1m0-1.814a8.984,8.984,0,1,0,8.984,8.984,8.983,8.983,0,0,0-8.984-8.984Z'
        />
        <path
          fill='white'
          d='M18.338,19.672H16.523v-5.4h1.814Zm0-7.17H16.523V10.688h1.814Z'
          transform='translate(-5.071 -2.82)'
        />
      </g>
    </svg>
  );
}

export function SvgChatIcon({color, size}: {color?: string; size: number | string}) {
  color = color ?? '#fc385c';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox={`0 0 57 57`}>
      <g transform='translate(-41.59 -99.774)'>
        <circle
          fill='none'
          stroke={color}
          strokeWidth='2px'
          strokeMiterlimit={10}
          cx='3.417'
          cy='3.417'
          r='3.417'
          transform='translate(75.127 113)'
        />
        <path
          fill='none'
          stroke={color}
          strokeWidth='2px'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M75.187,246.535l-9.628-9.61a3.4,3.4,0,0,0-4.659-.138L48,248.257m18.691,8.5,13.1-13.1a3.4,3.4,0,0,1,4.578-.212l7.811,6.514'
          transform='translate(0 -113.204)'
        />
      </g>
    </svg>
  );
}
