import {ReactNode, useCallback, useRef} from 'react';

export function FileUploader({
  onChange,
  render,
}: {
  onChange: (f: File) => void;
  render: (p: {clickHandler: () => void}) => ReactNode;
}) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => hiddenFileInput.current?.click(), []);

  return (
    <>
      {render({clickHandler: onClick})}
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={(e) => {
          onChange(e.target.files![0]);
        }}
        style={{display: 'none'}}
      />
    </>
  );
}
