import {BreakingFlexRow} from './breaking-flex-row';
import {FlexFiller, FlexFillerAuto} from '../Utils/LayoutStyles';
import {PrimaryRed} from '../Firebase/firebase-styles';
import image_8 from './help-visitor/8_meetup.svg';
import React from 'react';

export function AboutUs() {
  return (
    <BreakingFlexRow style={({isBroken}) => ({margin: isBroken ? '40px 0' : '200px 0'})}>
      {({isBroken}) => (
        <>
          <FlexFillerAuto style={{minWidth: '200px'}}>
            <div style={{color: PrimaryRed, fontSize: '34px', marginBottom: '40px'}}>About Us</div>
            <div style={{fontSize: '18px'}}>Breakout Messaging is a location-based chatroom creation platform.
            <br />
            <br />
            Our mission is to empower hotel and venue owners to help their guests connect and find local activities.
            <br />
            <br />
            Breakout gives them the tools to break the ice online and schedule real-life meetups.
            <br />
            <br />
            All in the respect of privacy and anonymity.</div>
          </FlexFillerAuto>
          <FlexFiller style={{flexBasis: isBroken ? 0 : '100px', flexGrow: 0}} />
          <FlexFillerAuto style={{minWidth: '200px'}}>
            <img src={image_8} alt='' />
          </FlexFillerAuto>
        </>
      )}
    </BreakingFlexRow>
  );
}
