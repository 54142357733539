import React, {CSSProperties, ReactNode, useMemo, useState} from 'react';
import {isLoadedUser, LoadingUserObjectReference, UnknownUserObjectReference, useChatContext} from './chat-context';
import {useFirebaseAuth, useFirebaseStorage} from './firebase';
import {FlexCenterColumn, FlexCenterRow, FlexColumn, FlexFiller} from '../Utils/LayoutStyles';
import {Dialog, Popover} from '@blueprintjs/core';
import {StyledCircleClip} from '../Utils/CircledText';
import {NestedMap, UserObjectReference} from '../../model';
import {
  BackgroundGray,
  HeaderHeight,
  PrimaryRed,
  StyledIconButton,
  StyledMainAsyncButton,
  StyledMainButton,
  StyledMenuButton,
  StyledMenuLinkButton,
  StyledPopupMenu,
} from './firebase-styles';
import {useHistory} from 'react-router';
import {MainRoute} from '../Shell/RouteDefinitions';
import {useFirebaseUserInfo} from './RequireLoginComponent';
import {logout} from './logout-helper';
import {useChatRoomsCollection, useCollectionHelpers, useUsersMessagingCollection} from './collections';
import {AsyncButtonBase} from '../Utils/AsyncButton';
import firebase from '@firebase/app';
import {useEffectDebug} from '../Utils/useEffectDebug';
import styled from 'styled-components';
import {SvgSend} from './SvgSend';
import {FileUploader} from './FileUploader';
import {SmallSpinner} from '../Utils/SmallSpinner';
import {Icon} from '@iconify/react';
import trash2 from '@iconify-icons/feather/trash-2';
import cloudUpload from '@iconify-icons/mdi/cloud-upload';
import logoutIcon from '@iconify-icons/mdi/exit-to-app';
import pencilIcon from '@iconify-icons/ion/pencil';
import {useAsyncCallback} from '../Utils/useAsyncCallback';
import {TitledDialogLayout, TwoColumnResponsiveLayout} from './TitledDialogLayout';
import {VersionComponent} from './VersionComponent';

export function ParticipantsComponent({chatId}: {chatId: string | null}) {
  const {chatContext} = useChatContext();
  const {userInfo} = useFirebaseUserInfo();

  const chat = chatContext.orderedChats.find((x) => x.documentId === chatId);
  const isAdmin = !!chat?.moderators.find((x) => x.id === userInfo.ownerId);

  return (
    <FlexColumn style={{borderLeft: `1px solid ${BackgroundGray}`, flex: '1 1 0'}}>
      <LoggedInUserPanel />
      <FlexColumn style={{flex: '1 1 0', overflow: 'auto'}}>
        {chat &&
          chatId &&
          chat.participants.map((p) => <ParticipantComponent key={p.id} p={p} isAdmin={isAdmin} chatId={chatId} />)}
      </FlexColumn>
    </FlexColumn>
  );
}

function LoggedInUserPanel() {
  const [showMenu, setShowMenu] = useState(false);
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const auth = useFirebaseAuth();
  const chatCollection = useChatRoomsCollection();
  const usersMessagingCollection = useUsersMessagingCollection();

  const {userInfo} = useFirebaseUserInfo();

  return (
    <>
      <Dialog
        style={{background: BackgroundGray, width: 'unset'}}
        isOpen={showEditInfo}
        onClose={() => setShowEditInfo(false)}
      >
        <EditUserDialogContent onClose={() => setShowEditInfo(false)} />
      </Dialog>
      <Dialog
        style={{background: BackgroundGray, width: 'unset', padding: '30px'}}
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
      >
        <DeleteAccountContent />
      </Dialog>
      <FlexCenterRow
        style={{
          borderBottom: `1px solid ${BackgroundGray}`,
          height: HeaderHeight,
          paddingLeft: '30px',
          marginRight: '10px',
        }}
      >
        <UserWithUrl
          user={{
            id: userInfo.ownerId,
            photoUrl: userInfo.info.photoUrl,
            displayName: userInfo.info.displayName ?? 'unknown',
          }}
        />
        <Popover
          isOpen={showMenu}
          placement='bottom'
          onClose={() => setShowMenu(false)}
          content={
            <StyledPopupMenu>
              <StyledMenuLinkButton
                onClick={() => {
                  setShowEditInfo(true);
                  setShowMenu(false);
                }}
              >
                <Icon icon={pencilIcon} width={22} height={22} />
                <div>Edit profile</div>
              </StyledMenuLinkButton>
              <StyledMenuLinkButton
                className='test-logout-btn'
                onClick={async () => {
                  await logout(auth, userInfo, chatCollection, usersMessagingCollection);
                  window.location.href = '/';
                }}
              >
                <Icon icon={logoutIcon} width={22} height={22} />
                <div>Logout</div>
              </StyledMenuLinkButton>
              <StyledMenuLinkButton
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
              >
                <Icon icon={trash2} width={22} height={22} />
                <div>Delete account...</div>
              </StyledMenuLinkButton>
              <VersionComponent
                style={{alignSelf: 'center', fontSize: '10px', fontWeight: 400, margin: '25px 0 0 15px'}}
              />
            </StyledPopupMenu>
          }
        >
          <StyledMenuButton onClick={() => setShowMenu(!showMenu)} testName='test-menu-btn' />
        </Popover>
      </FlexCenterRow>
    </>
  );
}

export function DeleteAccountContent() {
  const userMessaging = useUsersMessagingCollection();
  const {firebaseUser, userInfo} = useFirebaseUserInfo();
  const roomsCollection = useChatRoomsCollection();
  const userRoomsQuery = useMemo(
    () => roomsCollection.where(`users.${userInfo.ownerId}`, '!=', null),
    [userInfo.ownerId, roomsCollection]
  );

  const {leaveChat} = useCollectionHelpers();

  return (
    <>
      <div>
        Are you sure you want to delete your account? You can always create a new one and access your chats if you have
        their QR codes
      </div>
      <AsyncButtonBase
        render={({onClick, isLoading, isSucceeded}) => (
          <StyledMainButton
            style={{marginTop: '50px', width: '260px', height: '50px'}}
            disabled={isLoading || isSucceeded}
            onClick={onClick}
            data-test='test-deleteAccount-btn'
          >
            Delete account
          </StyledMainButton>
        )}
        onClickAsync={async () => {
          const rooms = await userRoomsQuery.get();
          for (const doc of rooms.docs) {
            await leaveChat(doc.id);
          }
          await userMessaging.doc(userInfo.documentId).delete();
          await firebaseUser.delete();
        }}
      />
    </>
  );
}

const StyledInputLabel = styled.div`
  font-size: 12px;
  color: #454d5a;
  opacity: 0.5;
  margin-bottom: 4px;
  align-self: flex-start;
`;

const StyledTextInput = styled.input`
  border: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #2b2c2b;
  font-size: 14px;
  min-height: 50px;

  &:disabled {
    background-color: #e6e6e6;
  }
`;

const StyledTextareaInput = styled.textarea`
  border: 0;
  border-radius: 8px;
  display: flex;
  color: #2b2c2b;
  font-size: 14px;
  min-height: 50px;
  padding: 16px;
`;

function EditUserDialogContent({onClose}: {onClose: () => void}) {
  return <TitledDialogLayout onClose={onClose} title={'User information'} content={<EditUserInfo />} />;
}

export function EditUserInfo({isVertical}: {isVertical?: boolean}) {
  const {userInfo, firebaseUser} = useFirebaseUserInfo();
  const storage = useFirebaseStorage();
  const usersMessagingCollection = useUsersMessagingCollection();

  const [name, setName] = useState(userInfo.info.displayName);
  const [description, setDescription] = useState(userInfo.info.description ?? '');

  useEffectDebug(() => {
    setName(userInfo.info.displayName);
  }, [userInfo.info.displayName]);

  useEffectDebug(() => {
    setDescription(userInfo.info.description ?? '');
  }, [userInfo.info.description]);

  const columnsPadding = '30px';

  const onSetFile = useAsyncCallback<{file: File}>(
    async ({wrap, file}) => {
      const r = await storage.ref(`images/${userInfo.ownerId}`).put(file);
      const newUrl = await wrap(r.ref.getDownloadURL());
      await wrap(
        usersMessagingCollection.doc(userInfo.documentId).set(
          {
            info: {
              photoUrl: newUrl,
            } as any,
          },
          {merge: true}
        )
      );
    },
    [storage, usersMessagingCollection, userInfo]
  );

  const onRemoveFile = useAsyncCallback(async () => {
    await Promise.all([
      storage.ref(`images/${userInfo.ownerId}`).delete(),
      usersMessagingCollection.doc(userInfo.documentId).set(
        {
          info: {
            photoUrl: null,
          } as any,
        },
        {merge: true}
      ),
    ]);
  }, [storage, usersMessagingCollection, userInfo]);

  const onSaveChanges = useAsyncCallback(async () => {
    await usersMessagingCollection.doc(userInfo.documentId).set(
      {
        info: {
          displayName: name,
          description,
        } as any,
      },
      {merge: true}
    );
  }, [usersMessagingCollection, name, description, userInfo]);

  return (
    <TwoColumnResponsiveLayout
      leftContent={
        <ImageUploadLayout
          renderImage={() => <UserImage user={userInfo.info} imageStyle='black' size={160} />}
          onSetFile={onSetFile}
          showRemove={!!userInfo.info.photoUrl}
          onRemove={onRemoveFile}
        />
      }
      rightContent={
        <NameDescriptionSaveLayout
          style={{padding: `0 ${columnsPadding}`}}
          email={firebaseUser.email ?? 'Email not provided'}
          name={name}
          description={description}
          onNameChanged={(t) => setName(t)}
          onDescriptionChanged={(t) => setDescription(t)}
          onSaveClick={onSaveChanges}
        />
      }
    />
  );
}

export function ImageUploadLayout({
  renderImage,
  onSetFile,
  showRemove,
  onRemove,
}: {
  renderImage: () => ReactNode;
  onSetFile: (p: {file: File}) => Promise<void>;
  showRemove: boolean;
  onRemove: () => Promise<void>;
}) {
  const [isUploading, setIsUploading] = useState(false);

  return (
    <FlexCenterColumn style={{justifyContent: 'center', padding: `0 60px`}}>
      {renderImage()}

      <FileUploader
        onChange={async (file) => {
          if (file) {
            setIsUploading(true);
            await onSetFile({file});
            setIsUploading(false);
          }
        }}
        render={({clickHandler}) => (
          <FlexCenterRow style={{marginTop: '20px', cursor: 'pointer'}} onClick={clickHandler}>
            <Icon icon={cloudUpload} width={24} height={24} color={PrimaryRed} />
            <div style={{marginLeft: '10px'}}>{showRemove ? 'Replace' : 'Upload'}</div>
            <div style={{width: '24px', height: '24px'}}>{isUploading && <SmallSpinner />}</div>
          </FlexCenterRow>
        )}
      />
      {showRemove && (
        <AsyncButtonBase
          render={({onClick, isLoading}) => {
            return (
              <FlexCenterRow style={{marginTop: '20px', cursor: 'pointer'}} onClick={onClick}>
                <Icon icon={trash2} width={24} height={24} color={PrimaryRed} />
                <div style={{marginLeft: '10px'}}>Remove</div>
                <div style={{width: '24px', height: '24px'}}>{isLoading && <SmallSpinner />}</div>
              </FlexCenterRow>
            );
          }}
          onClickAsync={onRemove}
        />
      )}
    </FlexCenterColumn>
  );
}

export function NameDescriptionSaveLayout({
  style,
  email,
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  onSaveClick,
  saveText,
}: {
  style?: CSSProperties;
  email?: string | null;
  name: string;
  description: string;
  onNameChanged: (t: string) => void;
  onDescriptionChanged: (t: string) => void;
  onSaveClick: () => Promise<void>;
  saveText?: string;
}) {
  return (
    <FlexColumn style={{...style, alignItems: 'stretch'}}>
      {email && (
        <>
          <StyledInputLabel>Email:</StyledInputLabel>
          <StyledTextInput value={email} disabled={true} style={{marginBottom: '15px'}} />
        </>
      )}
      <StyledInputLabel>Name:</StyledInputLabel>
      <StyledTextInput value={name} onChange={(e) => onNameChanged(e.target.value)} data-test='test-create-nameInput' />
      <StyledInputLabel style={{marginTop: '15px'}}>Description:</StyledInputLabel>
      <StyledTextareaInput
        value={description}
        onChange={(e) => onDescriptionChanged(e.target.value)}
        style={{height: '90px'}}
        data-test='test-create-descriptionInput'
      />

      <StyledMainAsyncButton onClickAsync={onSaveClick} data-test='test-saveBtn'>
        {saveText ?? 'Save'}
      </StyledMainAsyncButton>
    </FlexColumn>
  );
}

export function ParticipantComponent({p, isAdmin, chatId}: {p: UserObjectReference; isAdmin: boolean; chatId: string}) {
  const history = useHistory();
  const {userInfo} = useFirebaseUserInfo();
  const [showOptions, setShowOptions] = useState(false);
  const chatCollection = useChatRoomsCollection();

  return (
    <FlexCenterRow style={{borderBottom: '1px solid #F2F2F2', padding: '10px 10px 10px 30px'}}>
      <UserWithUrl user={p} />
      {userInfo.info.id !== p.id && (
        <>
          <StyledIconButton
            onClick={() => {
              history.push(MainRoute.makePath({destination: {type: 'direct', userId: p.id}}));
            }}
          >
            <SvgSend />
          </StyledIconButton>
          {isAdmin && (
            <Popover
              placement='bottom'
              isOpen={showOptions}
              onClose={() => setShowOptions(false)}
              content={
                <StyledPopupMenu>
                  <StyledMenuLinkButton
                    onClick={async () => {
                      const participantsDelete: any = {};
                      participantsDelete[p.id] = firebase.firestore.FieldValue.delete();
                      await chatCollection.doc(chatId).set({users: participantsDelete}, {merge: true});
                    }}
                  >
                    Remove from chat
                  </StyledMenuLinkButton>
                  <StyledMenuLinkButton
                    onClick={async () => {
                      const participantsDelete: any = {};
                      participantsDelete[p.id] = firebase.firestore.FieldValue.delete();
                      const bannedUsers: NestedMap<UserObjectReference> = {};
                      bannedUsers[p.id] = p;
                      await chatCollection.doc(chatId).set({users: participantsDelete, bannedUsers}, {merge: true});
                    }}
                  >
                    Remove and ban
                  </StyledMenuLinkButton>
                </StyledPopupMenu>
              }
            >
              <StyledMenuButton onClick={() => setShowOptions(!showOptions)} />
            </Popover>
          )}
        </>
      )}
    </FlexCenterRow>
  );
}

export function UserImage({
  user,
  size,
  imageStyle,
}: {
  user: UserObjectReference | LoadingUserObjectReference | UnknownUserObjectReference;
  size?: number;
  imageStyle: 'black' | 'red' | 'inverted-red';
}) {
  return (
    <StyledCircleClip size={size ?? 40}>
      {isLoadedUser(user) && user.photoUrl ? (
        <img
          alt={user.displayName}
          src={user.photoUrl}
          referrerPolicy='no-referrer'
          style={{height: '100%', width: 'auto'}}
          width={`${size ?? 40}px`}
          height={`${size ?? 40}px`}
        />
      ) : (
        <UserIcon size={size} imageStyle={imageStyle} />
      )}
    </StyledCircleClip>
  );
}

function UserIcon({size, imageStyle}: {size?: number; imageStyle: 'black' | 'red' | 'inverted-red'}) {
  size = size ?? 40;

  let color = '#fff';
  let backgroundColor = '#fc385c';

  if (imageStyle === 'inverted-red') {
    color = '#fc385c';
    backgroundColor = '#fff';
  }

  if (imageStyle === 'black') {
    backgroundColor = '#444D59';
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 40 40'>
      <circle fill={color} cx='20' cy='20' r='20' />
      <path
        fill={backgroundColor}
        d='M20,.563a20,20,0,1,0,20,20A20,20,0,0,0,20,.563ZM20,8.3a7.1,7.1,0,1,1-7.1,7.1A7.1,7.1,0,0,1,20,8.3Zm0,27.742a15.454,15.454,0,0,1-11.815-5.5,8.991,8.991,0,0,1,7.944-4.823,1.973,1.973,0,0,1,.573.089,10.678,10.678,0,0,0,3.3.556,10.637,10.637,0,0,0,3.3-.556,1.973,1.973,0,0,1,.573-.089,8.991,8.991,0,0,1,7.944,4.823A15.454,15.454,0,0,1,20,36.046Z'
        transform='translate(0 -0.563)'
      />
    </svg>
  );
}

export function UserWithUrl({user, size, fontSize}: {user: UserObjectReference; size?: number; fontSize?: string}) {
  return (
    <FlexCenterRow style={{flex: '1 1 0'}}>
      <UserImage user={user} imageStyle='black' size={size} />
      <div style={{flex: '0 0 20px'}} />
      <StyledUserName style={{fontSize: fontSize ?? '16px'}}>{user.displayName}</StyledUserName>
    </FlexCenterRow>
  );
}

const StyledUserName = styled(FlexFiller)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
