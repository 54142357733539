import firebase from '@firebase/app';
import {ChatRecord, UserMessagingRecord} from '../../model';
import {FirebaseAuth} from '@firebase/auth-types';
import {CollectionReference} from '@firebase/firestore-types';

export async function logout(
  auth: FirebaseAuth,
  userInfo: UserMessagingRecord & {documentId: string},
  chatCollection: CollectionReference<ChatRecord>,
  userInfoCollection: CollectionReference<UserMessagingRecord>
) {
  if (auth.currentUser?.isAnonymous) {
    const participantsDelete: any = {};
    participantsDelete[userInfo.info.id] = firebase.firestore.FieldValue.delete();
    if (userInfo.lastChatId) {
      await chatCollection.doc(userInfo.lastChatId).set({users: participantsDelete}, {merge: true});
    }
    await userInfoCollection.doc(userInfo.documentId).delete();
    await auth.currentUser.delete();
  } else {
    auth.signOut();
  }
}
